const aerialPlotApi = process.env.aerialPlotConnections.aerialPlotApi.baseUrl;

export function getGrowingSeasonDetails(accessToken) {
	return fetch(`${aerialPlotApi}api/v1/GrowingSeasons/GetGrowingSeasonsDetails`, {
		method: "GET",
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function createGrowingSeason(accessToken, season) {
    const seasonFormData = new FormData();

    for(const name in season)
    {
        let val = season[name] === "" ? null : season[name];
        if (val){
            seasonFormData.set(name, val);
        }
    }

    return fetch(`${aerialPlotApi}api/v1/GrowingSeasons`, {
		method: "POST",
		headers: {
			Authorization: `Bearer ${accessToken}`
		},
        body: seasonFormData
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function updateGrowingSeason(accessToken, season) {
    const seasonFormData = new FormData();

    for(const name in season)
    {
        let val = season[name] === "" ? null : season[name];

        if (val){
            seasonFormData.set(name, val);
        }
    }

    return fetch(`${aerialPlotApi}api/v1/GrowingSeasons`, {
		method: "PUT",
		headers: {
			Authorization: `Bearer ${accessToken}`
		},
        body: seasonFormData
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}