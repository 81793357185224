import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import moment from "moment";

import { Segment, Form, Grid, Button, Divider } from "semantic-ui-react";
import { toast } from "react-toastify";

import "../styles.css";

import { useAuth0 } from "../../../../auth/auth0";
import { useUserAuth } from "../../../../hooks/useUserAuth";

import * as clientActions from "../../../../redux/actions/clientActions";

const CmClient = ({ clientData, onClientSaved }) => {
	const { getTokenSilently } = useAuth0();
	const dispatch = useDispatch();
	const userAuth = useUserAuth();

	//-- Initial Data
	const [clientId, setClientId] = useState(null);
	const [initialEnabled, setInitialEnabled] = useState(false);
	const [initialClientName, setInitialClientName] = useState(null);
	const [clientSince, setClientSince] = useState(null);
	const [createdBy, setCreatedBy] = useState(null);

	//-- Updated Data
	const [clientName, setClientName] = useState(null);
	const [enabled, setEnabled] = useState(false);

	//-- UI Control
	const [mode, setMode] = useState("update");
	const [loading, setLoading] = useState(true);
	const [saving, setSaving] = useState(false);

	useEffect(() => {
		if (clientData) {
			setMode("update");
			getData();
		}
	}, [clientData]);

	async function getData() {
		setLoading(true);
		setInitialValues();
		setLoading(false);
	}

	function setInitialValues() {
		setClientId(clientData.clientInfo.clientId);

		setInitialEnabled(clientData.clientInfo.enabled);
		setEnabled(clientData.clientInfo.enabled);

		setInitialClientName(clientData.clientInfo.clientName);
		setClientName(clientData.clientInfo.clientName);

		setClientSince(moment(clientData.clientInfo.createdDateTimeUtc).local().format("MM/DD/YYYY"));
		setCreatedBy(`${clientData.clientInfo.createdByFirstName} ${clientData.clientInfo.createdByLastName}`);
	}

	const resetChanges = () => {
		setEnabled(initialEnabled);
		setClientName(initialClientName);
	};

	const saveChanges = async () => {
		const clientObj = getClientObject();

		setSaving(true);

		const accessToken = await getTokenSilently();

		if (mode === "update") {
			dispatch(clientActions.updateClient(accessToken, clientObj, userAuth.currentClientId))
				.then((res) => {
					if (res.statusCode === 200) {
						updateInitialValues();
						onClientSaved(clientId);
						toast.success("Client saved successfully");
					} else if (res.statusCode === 400) {
						if (res.messages && res.messages.length > 0) {
							res.messages.forEach((m) => {
								if (m.type === "Informational") {
									onClientSaved(clientId);
									toast.info(m.text);
								} else if (m.type === "FailedValidation") {
									toast.error(m.text);
								}
							});
						}
					}

					setSaving(false);
				})
				.catch((err) => {
					toast.error("Unable to save client info. Please try again.");
					console.log(err);
					setSaving(false);
				});
		} else if (mode === "create") {
			dispatch(clientActions.createClient(accessToken, clientObj, userAuth.currentClientId))
				.then((res) => {
					if (res.statusCode === 200) {
						updateInitialValues();
						onClientSaved(res.data);
						toast.success("Client saved successfully");
					} else if (res.statusCode === 400) {
						if (res.messages && res.messages.length > 0) {
							res.messages.forEach((m) => {
								if (m.type === "Informational") {
									onClientSaved(res.data);
									toast.info(m.text);
								} else if (m.type === "FailedValidation") {
									toast.error(m.text);
								}
							});
						}
					}

					setSaving(false);
				})
				.catch((err) => {
					toast.error("Unable to save client info. Please try again.");
					console.log(err);
					setSaving(false);
				});
		}
	};

	const getClientObject = () => {
		let clientObj = {};
		if (mode === "update") {
			clientObj = {
				clientId,
				clientName,
				enabled
			};
		} else if (mode === "create") {
			clientObj = {
				clientName,
				enabled
			};
		}

		return clientObj;
	};

	function updateInitialValues() {
		setInitialEnabled(enabled);
		setInitialClientName(clientName);
	}

	const prepareNewClientForm = () => {
		setMode("create");

		setClientId(null);
		setInitialEnabled(true);
		setEnabled(true);
		setInitialClientName(null);
		setClientName(null);
		setClientSince(null);
		setCreatedBy(null);
	};

	return (
		<Segment basic>
			<Form>
				<Grid id="client-management-grid" verticalAlign="middle" columns="equal">
					<Grid.Row>
						<Grid.Column width="2" textAlign="right">
							<Form.Field>
								<label htmlFor="form-input-client-name">Client Name*</label>
							</Form.Field>
						</Grid.Column>
						<Grid.Column width="5">
							<Form.Input
								id="form-input-client-name"
								value={clientName ?? ""}
								required
								error={!loading && !clientName}
								onChange={(e, { value }) => {
									setClientName(value);
								}}
								loading={loading}
							/>
						</Grid.Column>
						<Grid.Column width="2" textAlign="right">
							<Form.Field>
								<label htmlFor="form-checkbox-enabled">Enabled*</label>
							</Form.Field>
						</Grid.Column>
						<Grid.Column width="5">
							<Form.Checkbox
								toggle
								checked={enabled}
								onChange={(e, { checked }) => {
									setEnabled(checked);
								}}
							></Form.Checkbox>
						</Grid.Column>
						<Grid.Column textAlign="right">
							<Form.Field>
								<Button
									id="form-button-new-client"
									color="green"
									content="New Client"
									onClick={prepareNewClientForm}
									disabled={loading || saving}
								/>
							</Form.Field>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column>
							<Divider />
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column width="2" textAlign="right">
							<Form.Field>
								<label htmlFor="form-input-client-since">Client Since</label>
							</Form.Field>
						</Grid.Column>
						<Grid.Column width="5">
							<Form.Input id="form-input-client-since" value={clientSince ?? ""} loading={loading} readOnly />
						</Grid.Column>
						<Grid.Column width="2" textAlign="right">
							<Form.Field>
								<label htmlFor="form-input-created-by">Created By</label>
							</Form.Field>
						</Grid.Column>
						<Grid.Column width="5">
							<Form.Input id="form-input-created-by" value={createdBy ?? ""} loading={loading} readOnly />
						</Grid.Column>
					</Grid.Row>
					<Grid.Row textAlign="right">
						<Grid.Column>
							<Form.Field>
								<Button id="form-button-reset" content="Reset" onClick={resetChanges} disabled={loading || saving} />
								<Button
									id="form-button-save"
									primary
									content="Save"
									onClick={saveChanges}
									loading={saving}
									disabled={loading || saving}
								/>
							</Form.Field>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</Form>
		</Segment>
	);
};

CmClient.propTypes = {
	clientData: PropTypes.object,
	onClientSaved: PropTypes.func.isRequired
};

export default CmClient;
