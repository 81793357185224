export function getProtocolInfoOptions(clientId, searchAllClients, accessToken) {
	return fetch(
		`${process.env.aerialPlotConnections.aerialPlotApi.baseUrl}api/v1/Protocols/ProtocolInfoOptions?clientId=${clientId}&searchAllClients=${searchAllClients}`,
		{
			method: "GET",
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}
	)
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function getRecentlyUpdatedProtocols(clientId, accessToken) {
	return fetch(
		`${process.env.aerialPlotConnections.aerialPlotApi.baseUrl}api/v1/Protocols/GetRecentlyUpdatedProtocols?clientId=${clientId}`,
		{
			method: "GET",
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}
	)
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function getProtocolFavorites(clientId, accessToken) {
	return fetch(
		`${process.env.aerialPlotConnections.aerialPlotApi.baseUrl}api/v1/Protocols/GetProtocolFavorites?clientId=${clientId}`,
		{
			method: "GET",
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}
	)
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function getProtocolFavoriteGuids(clientId, accessToken) {
	return fetch(
		`${process.env.aerialPlotConnections.aerialPlotApi.baseUrl}api/v1/Protocols/GetProtocolFavoriteGuids?clientId=${clientId}`,
		{
			method: "GET",
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}
	)
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function createProtocolFavorite(clientId, accessToken, protocolId) {
	return fetch(
		`${process.env.aerialPlotConnections.aerialPlotApi.baseUrl}api/v1/Protocols/CreateProtocolFavorite?clientId=${clientId}&protocolId=${protocolId}`,
		{
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify(protocolId)
		}
	)
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function deleteProtocolFavorite(clientId, accessToken, protocolId) {
	return fetch(
		`${process.env.aerialPlotConnections.aerialPlotApi.baseUrl}api/v1/Protocols/DeleteProtocolFavorite?clientId=${clientId}&protocolId=${protocolId}`,
		{
			method: "DELETE",
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}
	)
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function getProtocolQuestions(clientId, accessToken) {
	return fetch(
		`${process.env.aerialPlotConnections.aerialPlotApi.baseUrl}api/v1/Protocols/ProtocolQuestions?clientId=${clientId}`,
		{
			method: "GET",
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}
	)
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function getProtocols(clientId, searchAllClients, accessToken) {
	return fetch(
		`${process.env.aerialPlotConnections.aerialPlotApi.baseUrl}api/v1/Protocols?clientId=${clientId}&searchAllClients=${searchAllClients}`,
		{
			method: "GET",
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}
	)
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function getAssociatedTrials(protocolId, clientId, accessToken) {
	return fetch(
		`${process.env.aerialPlotConnections.aerialPlotApi.baseUrl}api/v1/Protocols/AssociatedTrials?protocolId=${protocolId}&clientId=${clientId}`,
		{
			method: "GET",
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}
	)
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function createAssociatedTrials(protocolId, trialIds, clientId, accessToken) {
	return fetch(
		`${process.env.aerialPlotConnections.aerialPlotApi.baseUrl}api/v1/Protocols/AssociatedTrials?protocolId=${protocolId}&clientId=${clientId}`,
		{
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify(trialIds)
		}
	)
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function getProtocolData(protocolId, clientId, accessToken) {
	return fetch(
		`${process.env.aerialPlotConnections.aerialPlotApi.baseUrl}api/v1/Protocols/GetProtocolData?protocolId=${protocolId}&clientId=${clientId}`,
		{
			method: "GET",
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}
	)
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function deleteProtocol(protocolId, clientId, accessToken) {
	return fetch(
		`${process.env.aerialPlotConnections.aerialPlotApi.baseUrl}api/v1/Protocols/DeleteProtocol?protocolId=${protocolId}&clientId=${clientId}`,
		{
			method: "DELETE",
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}
	)
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function createProtocol(protocolData, clientId, accessToken) {
	const protocolDataFormData = new FormData();

	for (const name in protocolData) {
		let val = protocolData[name] === "" ? null : protocolData[name];
		if (typeof val !== "object") {
			protocolDataFormData.set(name, val);
		}
	}

	let regulatoryIndex = 0;
	for (let reg of protocolData.regulatory) {
		protocolDataFormData.append(`Regulatories[${regulatoryIndex}].Id`, reg.id);
		protocolDataFormData.append(`Regulatories[${regulatoryIndex}].Checked`, reg.checked);

		regulatoryIndex++;
	}

	let protocolVariablesIndex = 0;
	for (let pv of protocolData.variablesTested) {
		protocolDataFormData.append(`ProtocolVariables[${protocolVariablesIndex}].Id`, pv.id);
		protocolDataFormData.append(`ProtocolVariables[${protocolVariablesIndex}].Checked`, pv.checked);

		protocolVariablesIndex++;
	}

	return fetch(
		`${process.env.aerialPlotConnections.aerialPlotApi.baseUrl}api/v1/Protocols/CreateProtocol?clientId=${clientId}`,
		{
			method: "POST",
			headers: {
				Authorization: `Bearer ${accessToken}`
			},
			body: protocolDataFormData
		}
	)
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function updateProtocol(protocolData, clientId, accessToken) {
	const protocolDataFormData = new FormData();

	for (const name in protocolData) {
		let val = protocolData[name] === "" ? null : protocolData[name];
		if (typeof val !== "object") {
			protocolDataFormData.set(name, val);
		}
	}

	let regulatoryIndex = 0;
	for (let reg of protocolData.regulatory) {
		protocolDataFormData.append(`Regulatories[${regulatoryIndex}].Id`, reg.id);
		protocolDataFormData.append(`Regulatories[${regulatoryIndex}].Checked`, reg.checked);

		regulatoryIndex++;
	}

	let protocolVariablesIndex = 0;
	for (let pv of protocolData.variablesTested) {
		protocolDataFormData.append(`ProtocolVariables[${protocolVariablesIndex}].Id`, pv.id);
		protocolDataFormData.append(`ProtocolVariables[${protocolVariablesIndex}].Checked`, pv.checked);

		protocolVariablesIndex++;
	}

	return fetch(
		`${process.env.aerialPlotConnections.aerialPlotApi.baseUrl}api/v1/Protocols/UpdateProtocol?clientId=${clientId}`,
		{
			method: "PUT",
			headers: {
				Authorization: `Bearer ${accessToken}`
			},
			body: protocolDataFormData
		}
	)
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function getSummaryInfo(protocolId, clientId, accessToken) {
	return fetch(
		`${process.env.aerialPlotConnections.aerialPlotApi.baseUrl}api/v1/Protocols/SummaryInfo?protocolId=${protocolId}&clientId=${clientId}`,
		{
			method: "GET",
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}
	)
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function getProtocolTreatmentData(protocolId, clientId, accessToken) {
	return fetch(
		`${process.env.aerialPlotConnections.aerialPlotApi.baseUrl}api/v1/Protocols/GetProtocolTreatmentData?protocolId=${protocolId}&clientId=${clientId}`,
		{
			method: "GET",
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}
	)
		.then((res) => res.json())
		.then((data) => data);
}

export function createProtocolTreatmentData(protocolTreatmentData, clientId, accessToken) {
	const protocolTreatmentDataFormData = new FormData();

	let protocolTreatmentIndex = 0;
	for (let protocolTreatment of protocolTreatmentData.protocolTreatments) {
		protocolTreatmentDataFormData.append(
			`ProtocolTreatments[${protocolTreatmentIndex}].Id`,
			protocolTreatment.id ?? ""
		);
		protocolTreatmentDataFormData.append(
			`ProtocolTreatments[${protocolTreatmentIndex}].TrialTreatmentId`,
			protocolTreatment.trialTreatmentId
		);
		protocolTreatmentDataFormData.append(`ProtocolTreatments[${protocolTreatmentIndex}].Name`, protocolTreatment.name);
		protocolTreatmentDataFormData.append(
			`ProtocolTreatments[${protocolTreatmentIndex}].ConfidentialName`,
			protocolTreatment.confidentialName ?? ""
		);
		protocolTreatmentDataFormData.append(
			`ProtocolTreatments[${protocolTreatmentIndex}].Description`,
			protocolTreatment.description ?? ""
		);
		protocolTreatmentDataFormData.append(
			`ProtocolTreatments[${protocolTreatmentIndex}].Seed`,
			protocolTreatment.seed ?? ""
		);
		protocolTreatmentDataFormData.append(
			`ProtocolTreatments[${protocolTreatmentIndex}].TreatmentTypeId`,
			protocolTreatment.treatmentTypeId
		);
		protocolTreatmentDataFormData.append(
			`ProtocolTreatments[${protocolTreatmentIndex}].ProtocolId`,
			protocolTreatment.protocolId
		);

		protocolTreatmentIndex++;
	}

	let protocolTreatmentApplicationIndex = 0;
	for (let pta of protocolTreatmentData.protocolTreatmentApplications) {
		protocolTreatmentDataFormData.append(
			`ProtocolTreatmentApplications[${protocolTreatmentApplicationIndex}].Id`,
			pta.id ?? ""
		);
		protocolTreatmentDataFormData.append(
			`ProtocolTreatmentApplications[${protocolTreatmentApplicationIndex}].ApplicationId`,
			pta.applicationId
		);
		protocolTreatmentDataFormData.append(
			`ProtocolTreatmentApplications[${protocolTreatmentApplicationIndex}].Checked`,
			pta.checked
		);
		protocolTreatmentDataFormData.append(
			`ProtocolTreatmentApplications[${protocolTreatmentApplicationIndex}].TrialTreatmentId`,
			pta.trialTreatmentId
		);

		protocolTreatmentApplicationIndex++;
	}

	return fetch(
		`${process.env.aerialPlotConnections.aerialPlotApi.baseUrl}api/v1/Protocols/CreateProtocolTreatmentData?clientId=${clientId}`,
		{
			method: "POST",
			headers: {
				Authorization: `Bearer ${accessToken}`
			},
			body: protocolTreatmentDataFormData
		}
	)
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function updateProtocolTreatmentData(protocolTreatmentData, clientId, accessToken) {
	const protocolTreatmentDataFormData = new FormData();

	let protocolTreatmentIndex = 0;
	for (let protocolTreatment of protocolTreatmentData.protocolTreatments) {
		protocolTreatmentDataFormData.append(
			`ProtocolTreatments[${protocolTreatmentIndex}].Id`,
			protocolTreatment.id ?? ""
		);
		protocolTreatmentDataFormData.append(
			`ProtocolTreatments[${protocolTreatmentIndex}].TrialTreatmentId`,
			protocolTreatment.trialTreatmentId
		);
		protocolTreatmentDataFormData.append(`ProtocolTreatments[${protocolTreatmentIndex}].Name`, protocolTreatment.name);
		protocolTreatmentDataFormData.append(
			`ProtocolTreatments[${protocolTreatmentIndex}].ConfidentialName`,
			protocolTreatment.confidentialName ?? ""
		);
		protocolTreatmentDataFormData.append(
			`ProtocolTreatments[${protocolTreatmentIndex}].Description`,
			protocolTreatment.description ?? ""
		);
		protocolTreatmentDataFormData.append(
			`ProtocolTreatments[${protocolTreatmentIndex}].Seed`,
			protocolTreatment.seed ?? ""
		);
		protocolTreatmentDataFormData.append(
			`ProtocolTreatments[${protocolTreatmentIndex}].TreatmentTypeId`,
			protocolTreatment.treatmentTypeId
		);
		protocolTreatmentDataFormData.append(
			`ProtocolTreatments[${protocolTreatmentIndex}].ProtocolId`,
			protocolTreatment.protocolId
		);

		protocolTreatmentIndex++;
	}

	let protocolTreatmentApplicationIndex = 0;
	for (let pta of protocolTreatmentData.protocolTreatmentApplications) {
		protocolTreatmentDataFormData.append(
			`ProtocolTreatmentApplications[${protocolTreatmentApplicationIndex}].Id`,
			pta.id ?? ""
		);
		protocolTreatmentDataFormData.append(
			`ProtocolTreatmentApplications[${protocolTreatmentApplicationIndex}].ApplicationId`,
			pta.applicationId
		);
		protocolTreatmentDataFormData.append(
			`ProtocolTreatmentApplications[${protocolTreatmentApplicationIndex}].Checked`,
			pta.checked
		);
		protocolTreatmentDataFormData.append(
			`ProtocolTreatmentApplications[${protocolTreatmentApplicationIndex}].TrialTreatmentId`,
			pta.trialTreatmentId
		);

		protocolTreatmentApplicationIndex++;
	}

	return fetch(
		`${process.env.aerialPlotConnections.aerialPlotApi.baseUrl}api/v1/Protocols/UpdateProtocolTreatmentData?clientId=${clientId}`,
		{
			method: "PUT",
			headers: {
				Authorization: `Bearer ${accessToken}`
			},
			body: protocolTreatmentDataFormData
		}
	)
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function getProtocolTreatmentOptions(protocolId, clientId, accessToken) {
	return fetch(
		`${process.env.aerialPlotConnections.aerialPlotApi.baseUrl}api/v1/Protocols/GetProtocolTreatmentOptions?protocolId=${protocolId}&clientId=${clientId}`,
		{
			method: "GET",
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}
	)
		.then((res) => res.json())
		.then((data) => data);
}

export function setSummaryInfo(protocolId, overwriteTreatmentNames, clientId, accessToken) {
	return fetch(
		`${process.env.aerialPlotConnections.aerialPlotApi.baseUrl}api/v1/Protocols/SummaryInfo?protocolId=${protocolId}&overwriteTreatmentNames=${overwriteTreatmentNames}&clientId=${clientId}`,
		{
			method: "POST",
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}
	)
		.then((res) => res.json())
		.then((data) => data);
}

export function saveAssessments(assessments, protocolId, clientId, accessToken) {
	const assessmentFormData = new FormData();
	let datasetIndex = 0;
	let index = 0;
	for (const name in assessments) {
		let val = assessments[name] === "" ? null : assessments[name];
		if (typeof val !== "object") {
			assessmentFormData.set(name, val);
		}
	}
	for (const newGroundDataVm of assessments.groundDataSets) {
		assessmentFormData.append(`GroundDataSets[${datasetIndex}].groundDataset.Id`, newGroundDataVm.datasetId ?? "");
		assessmentFormData.append(`GroundDataSets[${datasetIndex}].groundDataset.ProtocolId`, protocolId ?? "");
		assessmentFormData.append(
			`GroundDataSets[${datasetIndex}].groundDataset.DatasetNumericalId`,
			newGroundDataVm.datasetNumericalId ?? ""
		);
		assessmentFormData.append(
			`GroundDataSets[${datasetIndex}].groundDataset.GroundDataTimingMethodId`,
			newGroundDataVm.groundDataset?.selectedTimingMethodId ?? ""
		);
		assessmentFormData.append(
			`GroundDataSets[${datasetIndex}].groundDataset.GrowthStageId`,
			newGroundDataVm.groundDataset?.growthStageId && newGroundDataVm.groundDataset?.growthStageId !== null
				? newGroundDataVm.groundDataset?.growthStageId
				: ""
		);
		assessmentFormData.append(
			`GroundDataSets[${datasetIndex}].groundDataset.GroundDataGrowthPhaseId`,
			newGroundDataVm.groundDataset?.growthPhaseId ?? ""
		);

		if (newGroundDataVm.groundDataset?.selectedTimingMethodType === "Date") {
			assessmentFormData.append(
				`GroundDataSets[${datasetIndex}].groundDataset.GrowthPhaseDate`,
				newGroundDataVm.groundDataset?.plannedTiming && newGroundDataVm.groundDataset?.plannedTiming !== "Invalid date"
					? newGroundDataVm.groundDataset?.plannedTiming
					: ""
			);
		} else if (newGroundDataVm.groundDataset?.selectedTimingMethodType === "StartEnd") {
			assessmentFormData.append(
				`GroundDataSets[${datasetIndex}].groundDataset.GrowthPhaseStartStageId`,
				newGroundDataVm.groundDataset?.plannedTiming?.growthStageStart ?? ""
			);
			assessmentFormData.append(
				`GroundDataSets[${datasetIndex}].groundDataset.GrowthPhaseEndStageId`,
				newGroundDataVm.groundDataset?.plannedTiming?.growthStageEnd ?? ""
			);
		} else if (newGroundDataVm.groundDataset?.selectedTimingMethodType === "int") {
			assessmentFormData.append(
				`GroundDataSets[${datasetIndex}].groundDataset.GrowthPhaseInteger`,
				newGroundDataVm.groundDataset?.plannedTiming ?? ""
			);
		} else if (newGroundDataVm.groundDataset?.selectedTimingMethodType === "Text") {
			assessmentFormData.append(
				`GroundDataSets[${datasetIndex}].groundDataset.GrowthPhaseText`,
				newGroundDataVm.groundDataset?.plannedTiming ?? ""
			);
		} else if (newGroundDataVm.groundDataset?.selectedTimingMethodType === "Start") {
			assessmentFormData.append(
				`GroundDataSets[${datasetIndex}].groundDataset.GrowthPhaseStartStageId`,
				newGroundDataVm.groundDataset?.plannedTiming?.growthStageStart ?? ""
			);
		}

		index = 0;
		for (const data of newGroundDataVm.assessments) {
			assessmentFormData.append(
				`GroundDataSets[${datasetIndex}].GroundDatasetAssessmentData[${index}].Name`,
				data.assessment.name
			);
			assessmentFormData.append(
				`GroundDataSets[${datasetIndex}].GroundDatasetAssessmentData[${index}].GroundDataAssessmentId`,
				data.assessment.id
			);
			assessmentFormData.append(
				`GroundDataSets[${datasetIndex}].GroundDatasetAssessmentData[${index}].AverageFor`,
				data?.assessment?.averageFor ?? ""
			);
			assessmentFormData.append(
				`GroundDataSets[${datasetIndex}].GroundDatasetAssessmentData[${index}].GroundDataUnitId`,
				data.unit.id
			);
			assessmentFormData.append(
				`GroundDataSets[${datasetIndex}].GroundDatasetAssessmentData[${index}].GroundDataSampleId`,
				data.samplesPerPlot?.id ?? ""
			);
			assessmentFormData.append(
				`GroundDataSets[${datasetIndex}].GroundDatasetAssessmentData[${index}].SampleDefinitionNumber`,
				data.sampleDefinitionNumber?.name ?? "1"
			);
			assessmentFormData.append(
				`GroundDataSets[${datasetIndex}].GroundDatasetAssessmentData[${index}].SamplesPerPlot`,
				data.samplesPerPlot?.name ?? "1"
			);
			assessmentFormData.append(
				`GroundDataSets[${datasetIndex}].GroundDatasetAssessmentData[${index}].GroundDataDeviceId`,
				data.device?.id ?? ""
			);
			assessmentFormData.append(
				`GroundDataSets[${datasetIndex}].GroundDatasetAssessmentData[${index}].GroundDataMethodId`,
				data.method.id
			);
			assessmentFormData.append(
				`GroundDataSets[${datasetIndex}].GroundDatasetAssessmentData[${index}].GroundDataSourceId`,
				data.source.id
			);
			assessmentFormData.append(
				`GroundDataSets[${datasetIndex}].GroundDatasetAssessmentData[${index}].GroundDataSourceName`,
				data.source.name
			);
			assessmentFormData.append(
				`GroundDataSets[${datasetIndex}].GroundDatasetAssessmentData[${index}].GroundDataSampleDefinitionTypeId`,
				data.groundDataSampleDefinitionPart?.groundDataSampleDefinitionTypeId ?? ""
			);
			assessmentFormData.append(
				`GroundDataSets[${datasetIndex}].GroundDatasetAssessmentData[${index}].GroundDataSampleDefinitionPartId`,
				data.groundDataSampleDefinitionPart?.id ?? ""
			);
			assessmentFormData.append(
				`GroundDataSets[${datasetIndex}].GroundDatasetAssessmentData[${index}].GroundDatasetTemplateId`,
				data.templateId ?? ""
			);
			assessmentFormData.append(
				`GroundDataSets[${datasetIndex}].GroundDatasetAssessmentData[${index}].Width`,
				data.width ?? ""
			);
			assessmentFormData.append(
				`GroundDataSets[${datasetIndex}].GroundDatasetAssessmentData[${index}].Length`,
				data.length ?? ""
			);
			assessmentFormData.append(
				`GroundDataSets[${datasetIndex}].GroundDatasetAssessmentData[${index}].Radius`,
				data.radius ?? ""
			);
			assessmentFormData.append(
				`GroundDataSets[${datasetIndex}].GroundDatasetAssessmentData[${index}].Order`,
				index + 1
			);
			assessmentFormData.append(
				`GroundDataSets[${datasetIndex}].GroundDatasetAssessmentData[${index}].Notes`,
				data.notes ?? ""
			);
			index++;
		}

		datasetIndex++;
	}

	return fetch(
		`${process.env.aerialPlotConnections.aerialPlotApi.baseUrl}api/v1/Protocols/Datasets?protocolId=${protocolId}&clientId=${clientId}`,
		{
			method: "POST",
			headers: {
				Authorization: `Bearer ${accessToken}`
			},
			body: assessmentFormData
		}
	)
		.then((res) => res.json())
		.then((data) => data);
}

export function getProtocolOverviewInfo(protocolId, clientId, accessToken) {
	return fetch(
		`${process.env.aerialPlotConnections.aerialPlotApi.baseUrl}api/v1/Protocols/GetProtocolOverviewInfo?protocolId=${protocolId}&clientId=${clientId}`,
		{
			method: "GET",
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}
	)
		.then((res) => res.json())
		.then((data) => data);
}

export function getProtocolDashboardOptions(protocolId, clientId, accessToken) {
	return fetch(
		`${process.env.aerialPlotConnections.aerialPlotApi.baseUrl}api/v1/Protocols/ProtocolDashboardOptions?protocolId=${protocolId}&clientId=${clientId}`,
		{
			method: "GET",
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}
	)
		.then((res) => res.json())
		.then((data) => data);
}

export function getPlotAnalysisStatusForProtocol(protocolId, clientId, checkFlights, checkCurveModels, accessToken) {
	return fetch(
		`${process.env.aerialPlotConnections.aerialPlotApi.baseUrl}api/v1/Protocols/GetPlotAnalysisStatusForProtocol
		?protocolId=${protocolId}
		&clientId=${clientId}
		&checkFlights=${checkFlights}
		&checkCurveModels=${checkCurveModels}`,
		{
			method: "GET",
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}
	)
		.then((res) => res.json())
		.then((data) => data);
}

export function getAucMeanComparisonLettersForProtocol(
	uncheckedTrials,
	uncheckedTreatments,
	protocolId,
	clientId,
	analysisId,
	analysisTypeId,
	assessmentId,
	groundDataGrowthPhaseId,
	curveModelAnalysisId,
	quantifiedRegionTypeId,
	meanComparisonId,
	alphaId,
	dataNormalizationId,
	useGdus,
	removeDamagedOrExcluded,
	isUniformity,
	accessToken
) {
	let uncheckedTrialsAndTreatmentsFormData = new FormData();

	for (let i = 0; i < uncheckedTrials.length; i++) {
		uncheckedTrialsAndTreatmentsFormData.append(`UncheckedTrials[${i}]`, uncheckedTrials[i]);
	}

	for (let i = 0; i < uncheckedTreatments.length; i++) {
		uncheckedTrialsAndTreatmentsFormData.append(`UncheckedTreatments[${i}]`, uncheckedTreatments[i]);
	}

	return fetch(
		`${
			process.env.aerialPlotConnections.aerialStatisticsApi.baseUrl
		}api/v1/MeanComparison/GetAucMeanComparisonLettersForProtocol
		?protocolId=${protocolId}&clientId=${clientId}
		${analysisId ? `&analysisId=${analysisId}` : ""}
		${analysisTypeId ? `&analysisTypeId=${analysisTypeId}` : ""}
		${assessmentId ? `&assessmentId=${assessmentId}` : ""}
		${groundDataGrowthPhaseId ? `&groundDataGrowthPhaseId=${groundDataGrowthPhaseId}` : ""}
		${curveModelAnalysisId ? `&curveModelAnalysisId=${curveModelAnalysisId}` : ""}
		${quantifiedRegionTypeId ? `&quantifiedRegionTypeId=${quantifiedRegionTypeId}` : ""}
		&meanComparisonId=${meanComparisonId}&alphaId=${alphaId}
		&dataNormalizationId=${dataNormalizationId}&useGdus=${useGdus}
		&removeDamagedOrExcluded=${removeDamagedOrExcluded}&isUniformity=${isUniformity}`,
		{
			method: "POST",
			headers: {
				Authorization: `Bearer ${accessToken}`
			},
			body: uncheckedTrialsAndTreatmentsFormData
		}
	)
		.then((res) => res.json())
		.then((data) => data);
}
