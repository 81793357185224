const aerialPlotApi = process.env.aerialPlotConnections.aerialPlotApi.baseUrl;

export function getUavs(accessToken, clientId) {
	return fetch(`${aerialPlotApi}api/v1/Uav?clientId=${clientId}`, {
		method: "GET",
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}
export function getUavDetailsData(accessToken, clientId) {
	return fetch(`${aerialPlotApi}api/v1/Uav/GetUavDetailsData?clientId=${clientId}`, {
		method: "GET",
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function createUav(accessToken, uav) {
	const uavFormData = new FormData();

	for (const name in uav) {
		let val = uav[name] === "" ? null : uav[name];


			if (val) {
				uavFormData.set(name, val);
			}
		
	}

	return fetch(`${aerialPlotApi}api/v1/UAV`, {
		method: "POST",
		headers: {
			Authorization: `Bearer ${accessToken}`
		},
		body: uavFormData
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function updateUav(accessToken, uav) {
	const uavFormData = new FormData();

	for (const name in uav) {
		let val = uav[name] === "" ? null : uav[name];


			if (val) {
				uavFormData.set(name, val);
			}
		
	}

	return fetch(`${aerialPlotApi}api/v1/UAV`, {
		method: "PUT",
		headers: {
			Authorization: `Bearer ${accessToken}`
		},
		body: uavFormData
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function deleteUav(accessToken, uavId) {
	return fetch(`${aerialPlotApi}api/v1/UAV?uavId=${uavId}`, {
		method: "DELETE",
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}
