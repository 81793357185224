import React, { useEffect, useState, useCallback } from "react";
import { useDispatch } from "react-redux";

import { Button, Segment, Grid, Form } from "semantic-ui-react";
import { DateTimePicker } from "react-widgets";
import { toast } from "react-toastify";
import _ from "lodash";

import ProtocolSearch from "./protocolSearch";
import TrialSearch from "./trialSearch";

import { useAuth0 } from "../../../auth/auth0";
import { useUserAuth } from "../../../hooks/useUserAuth";

import * as trialActions from "../../../redux/actions/trialActions";
import * as protocolActions from "../../../redux/actions/protocolActions";
import filterResults from "./resultFilter";
import PropTypes from "prop-types";
import { ApplicationArea } from "../../Lumber/ApplicationAreas";

const Dashboard = ({ mode = "all", updateMapboxTrials }) => {
	const dispatch = useDispatch();
	const { getTokenSilently } = useAuth0();
	const userAuth = useUserAuth();

	// #region UseStates

	// #region UI Control
	const [unlinkedProtocolDataLoading, setUnlinkedProtocolDataLoading] = useState(true);
	const [linkingProtocolsLoading, setLinkingProtcolsLoading] = useState(true);
	const [unlinkedTrialDataLoading, setUnlinkedTrialDataLoading] = useState(true);
	const [linkingTrialsLoading, setLinkingTrialsLoading] = useState(true);
	const [protocolFilterLoading, setProtocolFilterLoading] = useState(true);
	const [trialFilterLoading, setTrialFilterLoading] = useState(true);
	const [resetFields, setResetFields] = useState(true);
	const [selectedTimeRange, setSelectedTimeRange] = useState("allDates");
	// #endregion

	// #region Data
	const [unlinkedProtocolData, setUnlinkedProtocolData] = useState(null);
	const [linkedProtocolData, setLinkedProtocolData] = useState(null);
	const [filteredProtocolData, setFilteredProtocolData] = useState([]);
	const [unlinkedTrialData, setUnlinkedTrialData] = useState(null);
	const [linkedTrialData, setLinkedTrialData] = useState(null);
	const [filteredTrialData, setFilteredTrialData] = useState([]);
	const [trialStatuses, setTrialStatuses] = useState([]);
	const [timeRangeStart, setTimeRangeStart] = useState(null);
	const [timeRangeEnd, setTimeRangeEnd] = useState(null);
	const [timeRangeDirty, setTimeRangeDirty] = useState(false);
	// #endregion

	// #region Search Options

	// #region General
	// const [productCategory, setProductCategory] = useState(null);
	const [crop, setCrop] = useState(null);
	const [growingSeason, setGrowingSeason] = useState(null);
	const [variableTested, setVariableTested] = useState(null);
	const [timeRanges, setTimeRanges] = useState([]);
	const [growingSeasonOptions, setGrowingSeasonOptions] = useState([]);
	// #endregion

	// #region Protocol Specific
	const [protocolName, setProtocolName] = useState(null);
	const [protocolOwner, setProtocolOwner] = useState(null);
	// #endregion

	// #region Trial Specific
	const [trialName, setTrialName] = useState(null);
	const [trialOwner, setTrialOwner] = useState(null);
	const [cooperator, setCooperator] = useState(null);
	const [city, setCity] = useState(null);
	const [state, setState] = useState(null);
	const [company, setCompany] = useState(null);
	const [defStatus, setDefStatus] = useState(null);
	// #endregion

	// #endregion

	// #endregion

	// #region Time Range Setup
	const today = new Date();
	const lastWeek = new Date();
	const lastDayOfThisMonth = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getDate();
	const lastDayOfLastMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 0).getDate();
	const yearOfLastMonth = new Date().getFullYear() - (new Date().getMonth() === 1 ? 1 : 0);
	const timeRangePresetLookup = [
		{
			id: "noDate",
			value: {
				plantDateFrom: null,
				plantDateTo: null
			}
		},
		{
			id: "thisWeek",
			value: {
				plantDateFrom: new Date(today.setDate(new Date().getDate() - new Date().getDay())),
				plantDateTo: new Date(today.setDate(new Date().getDate() - new Date().getDay() + 6))
			}
		},
		{
			id: "thisMonth",
			value: {
				plantDateFrom: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
				plantDateTo: new Date(new Date().getFullYear(), new Date().getMonth(), lastDayOfThisMonth)
			}
		},
		{
			id: "thisYear",
			value: {
				plantDateFrom: new Date(new Date().getFullYear(), 0, 1),
				plantDateTo: new Date(new Date().getFullYear(), 11, 31)
			}
		},
		{
			id: "lastWeek",
			value: {
				plantDateFrom: new Date(lastWeek.setDate(new Date().getDate() - new Date().getDay() - 7)),
				plantDateTo: new Date(lastWeek.setDate(new Date().getDate() - new Date().getDay() - 1))
			}
		},
		{
			id: "lastMonth",
			value: {
				plantDateFrom: new Date(yearOfLastMonth, new Date().getMonth() - 1, 1),
				plantDateTo: new Date(yearOfLastMonth, new Date().getMonth() - 1, lastDayOfLastMonth)
			}
		},
		{
			id: "lastYear",
			value: {
				plantDateFrom: new Date(new Date().getFullYear() - 1, 0, 1),
				plantDateTo: new Date(new Date().getFullYear() - 1, 11, 31)
			}
		},
		{
			id: "allDates",
			value: {
				plantDateFrom: new Date(2001, 0, 1),
				plantDateTo: new Date(2099, 0, 1)
			}
		}
	];
	// #endregion

	// #region Filter Update Callback Functions

	//-- Protocol Specific Updates
	const updateProtocolName = useCallback(
		(val) => {
			setProtocolName(val);
		},
		[protocolName]
	);

	const updateProtocolOwner = useCallback(
		(val) => {
			setProtocolOwner(val);
		},
		[protocolOwner]
	);

	//-- Trial Specific Updates
	const updateTrialName = useCallback(
		(val) => {
			setTrialName(val);
		},
		[trialName]
	);

	const updateTrialOwner = useCallback(
		(val) => {
			setTrialOwner(val);
		},
		[trialOwner]
	);

	const updateCooperator = useCallback(
		(val) => {
			setCooperator(val);
		},
		[cooperator]
	);

	const updateCity = useCallback(
		(val) => {
			setCity(val);
		},
		[city]
	);

	const updateState = useCallback(
		(val) => {
			setState(val);
		},
		[state]
	);

	const updateCompany = useCallback(
		(val) => {
			setCompany(val);
		},
		[company]
	);

	const updateDEFStatus = useCallback(
		(val) => {
			setDefStatus(val);
		},
		[defStatus]
	);

	// #endregion

	///////////////////
	// CORE BEHAVIOR //
	///////////////////

	// 1. When the page first loads, populate the time range presets.
	useEffect(() => {
		setTimeRangePresetOptions();
	}, []);

	// 2. Once the time range presets have been configured, attempt to load the API data.
	useEffect(() => {
		if (timeRanges?.length > 0) {
			loadPageModeAppropriateData();
		}
	}, [timeRanges]);

	// 3. When the queries have finished running, link the trials to the protocols.
	useEffect(() => {
		if (!unlinkedTrialDataLoading && !unlinkedProtocolDataLoading) {
			if (unlinkedTrialData && unlinkedProtocolData) {
				linkTrialsAndProtocols();

				let currentGrowingSeason = getCurrentGrowingSeason();

				let gsOptions = _.sortBy(
					_.uniqBy(
						_.map(unlinkedTrialData, (t) => {
							return { text: t.growingSeasonName, value: t.growingSeasonName };
						}).concat(
							_.map(unlinkedProtocolData, (p) => {
								return { text: p.growingSeasonName, value: p.growingSeasonName };
							})
						),
						"value"
					),
					"value"
				).reverse();

				let currentGrowingSeasonSelection =
					_.find(gsOptions, (gs) => gs.value.includes(currentGrowingSeason))?.value ?? gsOptions[0]?.value;

				if (growingSeason === "blank") {
					currentGrowingSeasonSelection = null;
				}

				setGrowingSeasonOptions(gsOptions);
				setGrowingSeason(currentGrowingSeasonSelection);
			}
		}
	}, [unlinkedTrialData, unlinkedProtocolData]);

	// 4. Once the trials have been linked to the protocols, apply our filters.
	useEffect(() => {
		if (!linkingTrialsLoading && !linkingProtocolsLoading) {
			if (linkedTrialData && linkedProtocolData) {
				applyFilters();
			}
		}
	}, [linkedTrialData, linkedProtocolData]);

	// 5. Once the trial data has been filtered, update the map to show the trial pins.
	useEffect(() => {
		if (filteredTrialData.length > 0) {
			let trialsWithCoordinates = _.filter(filteredTrialData, (ftd) => {
				return (
					ftd.urLat != 0 &&
					ftd.urLat !== null &&
					ftd.urLat !== undefined &&
					ftd.urLong != 0 &&
					ftd.urLong !== null &&
					ftd.urLong !== undefined &&
					ftd.llLat != 0 &&
					ftd.llLat !== null &&
					ftd.llLat !== undefined &&
					ftd.llLong != 0 &&
					ftd.llLong !== null &&
					ftd.llLong !== undefined
				);
			});

			var mappedTrialPinData = _.map(trialsWithCoordinates, (t) => {
				return {
					name: t.trialName,
					trialId: t.trialId,
					llLat: t.llLat,
					llLong: t.llLong,
					urLat: t.urLat,
					urLong: t.urLong
				};
			});

			updateMapboxTrials(mappedTrialPinData);
		}
	}, [filteredTrialData]);

	/////////////////////////
	// CORE IMPLEMENTATION //
	/////////////////////////

	// Set the available time range preset options in the dropdown.
	const setTimeRangePresetOptions = () => {
		let options = [
			{
				key: "noDate",
				value: "noDate",
				text: "No Plant Date"
			},
			{
				key: "thisWeek",
				value: "thisWeek",
				text: "This Week"
			},
			{
				key: "thisMonth",
				value: "thisMonth",
				text: "This Month"
			},
			{
				key: "thisYear",
				value: "thisYear",
				text: "This Year"
			},
			{
				key: "lastWeek",
				value: "lastWeek",
				text: "Last Week"
			},
			{
				key: "lastMonth",
				value: "lastMonth",
				text: "Last Month"
			},
			{
				key: "lastYear",
				value: "lastYear",
				text: "Last Year"
			},
			{
				key: "allDates",
				value: "allDates",
				text: "All Dates"
			}
		];

		setTimeRanges(options);
	};

	// Load the appropriate data based on the current mode.
	const loadPageModeAppropriateData = () => {
		if (mode === "all") {
			getTrialAndProtocolDataForAllMode();
		} else if (mode === "favorites") {
			getTrialAndProtocolDataForFavoritesMode();
		} else if (mode === "updated") {
			getTrialAndProtocolDataForUpdatedMode();
		} else if (mode === "published") {
			getTrialAndProtocolDataForPublishedMode();
		}
	};

	// Load data for the 'All' page mode.
	const getTrialAndProtocolDataForAllMode = async () => {
		const accessToken = await getTokenSilently();

		let customTimeRangeObject = {
			plantDateFrom: "Mon Jan 01 2001",
			plantDateTo: "Thu Jan 01 2099"
		};

		if (timeRangeStart !== null) {
			customTimeRangeObject.plantDateFrom = new Date(timeRangeStart).toDateString();
		}
		if (timeRangeEnd !== null) {
			customTimeRangeObject.plantDateTo = new Date(timeRangeEnd).toDateString();
		}

		if (timeRangeStart === null || timeRangeEnd === null) {
			// TRIAL DATA
			setUnlinkedTrialDataLoading(true);
			dispatch(trialActions.searchForTrials(customTimeRangeObject, userAuth.currentClientId, false, accessToken))
				.then((res) => {
					setUnlinkedTrialDataLoading(false);
					setUnlinkedTrialData(
						_.filter(res.trialInfos, (ti) => {
							return ti.plantDate === null;
						})
					);
					setTrialStatuses(res.trialStatuses);
				})
				.catch((err) => {
					toast.error("Error searching for trials. Please try again.");
					console.error(err);
				});
		} else {
			let specifiedTimeRangeObject = getSpecifiedTimeRange();

			// TRIAL DATA
			setUnlinkedTrialDataLoading(true);
			dispatch(trialActions.searchForTrials(specifiedTimeRangeObject, userAuth.currentClientId, false, accessToken))
				.then((res) => {
					setUnlinkedTrialDataLoading(false);

					let trialInfo =
						selectedTimeRange !== "allDates" ? _.filter(res.trialInfos, (ti) => ti.plantDate) : res.trialInfos;

					setUnlinkedTrialData(trialInfo);
					setTrialStatuses(res.trialStatuses);
				})
				.catch((err) => {
					toast.error("Error searching for trials. Please try again.");
					console.error(err);
				});
		}

		setTimeRangeDirty(false);

		// PROTOCOL DATA
		if (!unlinkedProtocolData) {
			setUnlinkedProtocolDataLoading(true);
			dispatch(protocolActions.getProtocols(userAuth.currentClientId, false, accessToken))
				.then((res) => {
					// Make sure the user has a valid license required to look at protocols.
					res.data = _.filter(res.data, (d) => {
						if (
							userAuth.hasApplicationArea(ApplicationArea.HomeOverview, d.clientId) ||
							userAuth.hasApplicationArea(ApplicationArea.HomeRecentlyPublished, d.clientId)
						) {
							return d;
						}
					});

					setUnlinkedProtocolDataLoading(false);
					setUnlinkedProtocolData(res.data);
				})
				.catch((err) => {
					toast.error("Error searching for protocols. Please try again.");
					console.error(err);
				});
		}
	};

	// Load data for the 'Favorites' page mode.
	const getTrialAndProtocolDataForFavoritesMode = async () => {
		const accessToken = await getTokenSilently();

		// TRIAL DATA
		setUnlinkedTrialDataLoading(true);
		dispatch(trialActions.getTrialFavorites(userAuth.currentClientId, accessToken))
			.then((res) => {
				setUnlinkedTrialDataLoading(false);
				setUnlinkedTrialData(res.data.trialInfos);
				setTrialStatuses(res.data.trialStatuses);
			})
			.catch((err) => {
				toast.error("Error searching for trials. Please try again.");
				console.error(err);
			});

		setTimeRangeDirty(false);

		// PROTOCOL DATA
		if (!unlinkedProtocolData) {
			setUnlinkedProtocolDataLoading(true);
			dispatch(protocolActions.getProtocolFavorites(userAuth.currentClientId, accessToken))
				.then((res) => {
					setUnlinkedProtocolDataLoading(false);
					setUnlinkedProtocolData(res.data);
				})
				.catch((err) => {
					toast.error("Error searching for protocols. Please try again.");
					console.error(err);
				});
		}
	};

	// Load data for the 'Updated' page mode.
	const getTrialAndProtocolDataForUpdatedMode = async () => {
		const accessToken = await getTokenSilently();

		// TRIAL DATA
		setUnlinkedTrialDataLoading(true);
		dispatch(trialActions.getRecentlyUpdatedTrials(userAuth.currentClientId, accessToken))
			.then((res) => {
				setUnlinkedTrialDataLoading(false);
				setUnlinkedTrialData(res.data.trialInfos);
				setTrialStatuses(res.data.trialStatuses);
			})
			.catch((err) => {
				toast.error("Error searching for trials. Please try again.");
				console.error(err);
			});

		setTimeRangeDirty(false);

		// PROTOCOL DATA
		if (!unlinkedProtocolData) {
			setUnlinkedProtocolDataLoading(true);
			dispatch(protocolActions.getRecentlyUpdatedProtocols(userAuth.currentClientId, accessToken))
				.then((res) => {
					setUnlinkedProtocolDataLoading(false);
					setUnlinkedProtocolData(res.data);
				})
				.catch((err) => {
					toast.error("Error searching for protocols. Please try again.");
					console.error(err);
				});
		}
	};

	// Load data for the 'Published' page mode.
	const getTrialAndProtocolDataForPublishedMode = async () => {
		const accessToken = await getTokenSilently();

		// TRIAL DATA
		setUnlinkedTrialDataLoading(true);
		dispatch(trialActions.getRecentlyPublishedTrials(userAuth.currentClientId, accessToken))
			.then((res) => {
				setUnlinkedTrialDataLoading(false);
				setUnlinkedTrialData(res.data.trialInfos);
				setTrialStatuses(res.data.trialStatuses);
			})
			.catch((err) => {
				toast.error("Error searching for trials. Please try again.");
				console.error(err);
			});

		setTimeRangeDirty(false);

		// PROTOCOL DATA
		if (!unlinkedProtocolData) {
			setUnlinkedProtocolDataLoading(true);
			dispatch(protocolActions.getProtocols(userAuth.currentClientId, false, accessToken))
				.then((res) => {
					// Make sure the user has a valid license required to look at protocols.
					res.data = _.filter(res.data, (d) => {
						if (
							userAuth.hasApplicationArea(ApplicationArea.HomeOverview, d.clientId) ||
							userAuth.hasApplicationArea(ApplicationArea.HomeRecentlyPublished, d.clientId)
						) {
							return d;
						}
					});
					setUnlinkedProtocolDataLoading(false);
					setUnlinkedProtocolData(res.data);
				})
				.catch((err) => {
					toast.error("Error searching for protocols. Please try again.");
					console.error(err);
				});
		}
	};

	// Based on the date filters and selected time preset option, get the times that the user is trying to query for.
	const getSpecifiedTimeRange = () => {
		let specifiedTimeRange =
			timeRangeStart && timeRangeEnd
				? { plantDateFrom: timeRangeStart.toDateString(), plantDateTo: timeRangeEnd.toDateString() }
				: {
					plantDateFrom: _.find(timeRangePresetLookup, {
						id: selectedTimeRange
					})?.value.plantDateFrom.toDateString(),
					plantDateTo: _.find(timeRangePresetLookup, { id: selectedTimeRange })?.value.plantDateTo.toDateString()
				};
		/*
			Returns an object in the form:
			{
				plantDateFrom: ...
				plantDateTo: ...
			}
		*/
		return specifiedTimeRange;
	};

	// Attempt to associate each of the trials with a protocol name that they are associated with.
	const linkTrialsAndProtocols = () => {
		setLinkingTrialsLoading(true);
		setLinkingProtcolsLoading(true);
		let tempTrialData = _.cloneDeep(unlinkedTrialData);
		let tempProtocolData = _.cloneDeep(unlinkedProtocolData);

		// Assign a set of protocol names each of the matched trials.
		_.map(tempProtocolData, (p) => {
			_.map(p.trialIds, (tId) => {
				// Find the full trial object with the current trial Id.
				let matchingTrial = _.find(tempTrialData, { trialId: tId });

				// If we find a trial, append the protocol name to the trials 'protocolNames' list.
				if (matchingTrial) {
					matchingTrial.protocolNames = matchingTrial.protocolNames
						? [...matchingTrial.protocolNames, p.protocolName]
						: [p.protocolName];
				}
			});
		});

		// Additionally, remove protocols which cannot be linked for certain page modes.
		if (mode === "published") {
			// Get a list of the trialIds that we have.
			var targetTrialIds = _.map(tempProtocolData, (p) => {
				return _.map(p.trialIds, (tId) => {
					return tId;
				});
			});

			var uniqueTrialIds = targetTrialIds.flat().filter((value, index, self) => {
				return self.indexOf(value) === index;
			});

			var linkedProtocols = _.filter(tempProtocolData, (p) => {
				var protocolRelevant = p.trialIds.some((tId) => {
					return uniqueTrialIds.includes(tId);
				});
				return protocolRelevant;
			});

			setLinkedTrialData(tempTrialData);
			setLinkingTrialsLoading(false);

			setLinkedProtocolData(linkedProtocols);
			setLinkingProtcolsLoading(false);
		} else {
			setLinkedTrialData(tempTrialData);
			setLinkingTrialsLoading(false);

			setLinkedProtocolData(tempProtocolData);
			setLinkingProtcolsLoading(false);
		}
	};

	// Apply all of the filter fields on the page to the retrieved data.
	const applyFilters = (reset = false, requestData = null) => {
		let filteredResults = filterResults(
			reset,
			requestData,
			linkedProtocolData,
			linkedTrialData,
			crop,
			growingSeason,
			variableTested,
			protocolName,
			protocolOwner,
			trialName,
			trialOwner,
			cooperator,
			city,
			state,
			company,
			defStatus,
			mode,
			selectedTimeRange,
			trialStatuses
		);

		// If we are on the recently updated or published pages, we need to take the top X records after applying the filters.
		if (mode === "updated" || mode === "published") {
			const numberOfRecentTrialsToDisplay = 10;
			const numberOfRecentProtocolsToDisplay = 10;

			if (filteredResults.filteredProtocolData !== null) {
				filteredResults.filteredProtocolData = filteredResults.filteredProtocolData.slice(
					0,
					numberOfRecentProtocolsToDisplay
				);
			}
			if (filteredResults.filteredTrialData !== null) {
				filteredResults.filteredTrialData = filteredResults.filteredTrialData.slice(0, numberOfRecentTrialsToDisplay);
			}
		}

		filteredResults.filteredProtocolData = _.orderBy(
			filteredResults.filteredProtocolData,
			["growingSeasonName", "protocolName"],
			["desc"]
		);
		filteredResults.filteredTrialData = _.orderBy(
			filteredResults.filteredTrialData,
			["growingSeasonName", "companyName", "trialName"],
			["desc"]
		);

		setFilteredProtocolData(filteredResults.filteredProtocolData);
		setFilteredTrialData(filteredResults.filteredTrialData);

		setTrialFilterLoading(false);
		setProtocolFilterLoading(false);
	};

	const getCurrentGrowingSeason = () => {
		//const date = new Date();

		//let month = date.getMonth() + 1;
		let year = "2024"; //date.getFullYear();

		let season = "Spring";
		//if (month < 5 || month > 12) season = "Fall";

		return `${year} ${season}`;
	};

	//////////////
	// HANDLERS //
	//////////////

	// When the selected time preset changes, change the contents of the "Time Range Start" and "Time Range End" fields.
	useEffect(() => {
		// If the user selects anything other than the "None" option, attempt to set the start and end fields to the correct date.
		if (selectedTimeRange !== "none" && selectedTimeRange) {
			setTimeRangeStart(_.find(timeRangePresetLookup, { id: selectedTimeRange })?.value.plantDateFrom);
			setTimeRangeEnd(_.find(timeRangePresetLookup, { id: selectedTimeRange })?.value.plantDateTo);
			setTimeRangeDirty(true);
		}
	}, [selectedTimeRange]);

	// When the user clicks the reset button, clear all of the filter fields.
	const handleResetFields = () => {
		//-- Triggers reset useEffects in child components
		setResetFields(!resetFields);

		setCrop(null);
		setGrowingSeason("blank");
		setVariableTested(null);

		setSelectedTimeRange("allDates");

		handleFilterSearch(true);
	};

	// When the user clicks the apply button, we need to re-query for data (because the time fields affect which data we've retrieved).
	const handleFilterSearch = (manualRefresh = null) => {
		setProtocolFilterLoading(true);
		setTrialFilterLoading(true);

		if (timeRangeDirty || manualRefresh) {
			loadPageModeAppropriateData();
		} else {
			applyFilters();
		}
	};

	return (
		<>
			<Segment>
				<Form>
					<Grid>
						<Grid.Row style={{ height: "38vh" }}>
							{/* <Grid.Column width="2">
							<Form.Input
								fluid
								label="Product Category"
								placeholder="Product Category"
								type="text"
								value={productCategory ?? ""}
								onChange={(event, { value }) => {
									setProductCategory(value);
								}}
							/>
						</Grid.Column> */}
							{/*Top Left Filters*/}
							<Grid.Column width="16" floated="right">
								<Grid>
									<Grid.Row>
										<Grid.Column width="5" floated="right">
											<Form.Dropdown
												fluid
												label="Crop"
												openOnFocus
												onChange={(event, { value }) => {
													setCrop(value);
												}}
												onSearchChange={(event, { searchQuery }) => {
													setCrop(searchQuery);
												}}
												options={_.uniqBy(
													_.map(unlinkedTrialData, (t) => {
														return { text: t.cropName, value: t.cropName };
													}).concat(
														_.map(unlinkedProtocolData, (p) => {
															return { text: p.cropName, value: p.cropName };
														})
													),
													"value"
												).sort((a, b) => (a.value > b.value ? 1 : -1))}
												placeholder="Crop"
												search
												searchQuery={crop ?? ""}
												selection
												selectOnBlur={false}
												type="text"
												value={crop ?? ""}
												disabled={trialFilterLoading || protocolFilterLoading}
											/>
										</Grid.Column>
										<Grid.Column width="5" floated="right">
											<Form.Dropdown
												fluid
												label="Growing Season"
												openOnFocus
												onChange={(event, { value }) => {
													setGrowingSeason(value);
												}}
												onSearchChange={(event, { searchQuery }) => {
													setGrowingSeason(searchQuery);
												}}
												options={growingSeasonOptions}
												placeholder="Growing Season"
												search
												//searchQuery={growingSeason ?? ""}
												selection
												selectOnBlur={false}
												type="text"
												value={growingSeason ?? ""}
												disabled={trialFilterLoading || protocolFilterLoading}
											/>
										</Grid.Column>
										<Grid.Column width="5" floated="right">
											<Form.Dropdown
												fluid
												label="Variable Tested"
												openOnFocus
												onChange={(event, { value }) => {
													setVariableTested(value);
												}}
												onSearchChange={(event, { searchQuery }) => {
													setVariableTested(searchQuery);
												}}
												options={_.uniqBy(
													_.map(unlinkedProtocolData, (p) => {
														return _.map(p.protocolVariables, (pv) => {
															return { text: pv.name, value: pv.name };
														});
													}).flat(),
													"value"
												).sort((a, b) => (a.value > b.value ? 1 : -1))}
												placeholder="Variable Tested"
												search
												searchQuery={variableTested ?? ""}
												selection
												selectOnBlur={false}
												type="text"
												value={variableTested ?? ""}
												disabled={trialFilterLoading || protocolFilterLoading}
											/>
										</Grid.Column>
									</Grid.Row>
									<Grid.Row>
										<Grid.Column width="5" floated="right">
											<Form.Field
												label={"Plant Range Start"}
												control={DateTimePicker}
												disabled={mode !== "all" || trialFilterLoading || protocolFilterLoading}
												includeTime={false}
												value={timeRangeStart}
												onChange={(date) => {
													setSelectedTimeRange("none");
													setTimeRangeDirty(true);
													if (date) {
														setTimeRangeStart(date);
													} else {
														setTimeRangeStart(null);
													}
												}}
											/>
										</Grid.Column>
										<Grid.Column width="5" floated="right">
											<Form.Field
												label={"Plant Range End"}
												control={DateTimePicker}
												disabled={mode !== "all" || trialFilterLoading || protocolFilterLoading}
												value={timeRangeEnd}
												onChange={(date) => {
													setSelectedTimeRange("none");
													setTimeRangeDirty(true);
													if (date) {
														setTimeRangeEnd(date);
													} else {
														setTimeRangeEnd(null);
													}
												}}
											/>
										</Grid.Column>
										<Grid.Column width="5" floated="right">
											<Form.Select
												fluid
												label="Presets"
												disabled={mode !== "all" || trialFilterLoading || protocolFilterLoading}
												search
												options={timeRanges}
												value={selectedTimeRange}
												onChange={(event, { value }) => {
													setSelectedTimeRange(value);
												}}
												style={{
													backgroundColor: mode !== "all" ? "#EEEEEE" : "#FFFFFF"
												}}
											/>
										</Grid.Column>
									</Grid.Row>
									<Grid.Row>
										<Grid.Column floated="right" width="5" verticalAlign="bottom">
											<Button
												fluid
												negative
												onClick={() => handleResetFields()}
												disabled={trialFilterLoading || protocolFilterLoading}
												loading={trialFilterLoading || protocolFilterLoading}
											>
												Clear Filters
											</Button>
										</Grid.Column>
										<Grid.Column width="5" verticalAlign="bottom">
											<Button
												fluid
												primary
												onClick={() => handleFilterSearch()}
												disabled={trialFilterLoading || protocolFilterLoading}
												loading={trialFilterLoading || protocolFilterLoading}
											>
												Apply
											</Button>
										</Grid.Column>
									</Grid.Row>

									{/*End Top Middle Filters*/}
								</Grid>
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</Form>
			</Segment>

			<ProtocolSearch
				unfilteredProtocolList={unlinkedProtocolData}
				filteredProtocolList={filteredProtocolData}
				setProtocolName={(val) => updateProtocolName(val)}
				setProtocolOwner={(val) => updateProtocolOwner(val)}
				loading={protocolFilterLoading}
				reset={resetFields}
			/>

			<TrialSearch
				unfilteredTrialList={unlinkedTrialData}
				filteredTrialList={filteredTrialData}
				trialStatuses={trialStatuses}
				setTrialName={(val) => updateTrialName(val)}
				setTrialOwner={(val) => updateTrialOwner(val)}
				setCooperator={(val) => updateCooperator(val)}
				setCity={(val) => updateCity(val)}
				setState={(val) => updateState(val)}
				setCompany={(val) => updateCompany(val)}
				setDefStatus={(val => updateDEFStatus(val))}
				loading={trialFilterLoading}
				reset={resetFields}
			/>
		</>
	);
};

Dashboard.propTypes = {
	mode: PropTypes.string.isRequired,
	updateMapboxTrials: PropTypes.func
};

export default Dashboard;
