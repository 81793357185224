import _ from "lodash";

const filterResults = (
	reset,
	requestData,
	protocolData,
	trialData,
	crop,
	growingSeason,
	variableTested,
	protocolName,
	protocolOwner,
	trialName,
	trialOwner,
	cooperator,
	city,
	state,
	company,
	defStatus,
	mode,
	selectedTimeRange,
	trialStatuses
) => {
	//-- Filter all results
	let filteredProtocolData = _.cloneDeep(protocolData);
	let filteredTrialData = requestData ?? _.cloneDeep(trialData);

	//-- Selected Time Range
	if (selectedTimeRange !== "noDate" && selectedTimeRange !== "allDates") {
		filteredTrialData = _.filter(filteredTrialData, (ftd) => {
			return ftd.plantDate;
		});
	}

	//-- Protocol Time Range Filter
	//---- All trials are already filtered by time, so just match up protocols to existing trials
	if (mode === "all" || mode === "published") {
		let trialIdsToKeep = _.map(filteredTrialData, "trialId");
		filteredProtocolData = _.filter(filteredProtocolData, (p) =>
			_.some(trialIdsToKeep, (t) => _.indexOf(p.trialIds, t) !== -1 || p.trialIds.length === 0)
		);
	}

	//---- Crop
	if (!reset && crop) {
		filteredProtocolData = filterOutCriteria(filteredProtocolData, crop, "cropName");
		filteredTrialData = filterOutCriteria(filteredTrialData, crop, "cropName");
	}

	//---- Growing Season
	if (!reset && growingSeason) {
		filteredProtocolData = filterOutCriteria(filteredProtocolData, growingSeason, "growingSeasonName");
		filteredTrialData = filterOutCriteria(filteredTrialData, growingSeason, "growingSeasonName");
	}

	//---- Variable Tested
	if (!reset && variableTested) {
		let variableTestedRegex = new RegExp(_.escapeRegExp(variableTested), "i");
		filteredProtocolData = _.filter(filteredProtocolData, (f) =>
			_.some(_.filter(f.protocolVariables, "checked"), (pv) => variableTestedRegex.test(pv.name))
		);
		let trialIdsToKeep = _.flatten(_.map(filteredProtocolData, "trialIds"));
		filteredTrialData = _.filter(filteredTrialData, (t) => trialIdsToKeep.includes(t.trialId));
	}

	//-- Filter protocol results
	//---- Protocol Name
	if (!reset && protocolName) {
		filteredProtocolData = filterOutCriteria(filteredProtocolData, protocolName, "protocolName");
		if (mode === "all") {
			let trialIdsToKeep = _.flatten(_.map(filteredProtocolData, "trialIds"));
			filteredTrialData = _.filter(filteredTrialData, (t) => trialIdsToKeep.includes(t.trialId));
		}
	}

	//---- Protocol Owner
	if (!reset && protocolOwner) {
		filteredProtocolData = filterOutCriteria(filteredProtocolData, protocolOwner, "owner");
		if (mode === "all") {
			let trialIdsToKeep = _.flatten(_.map(filteredProtocolData, "trialIds"));
			filteredTrialData = _.filter(filteredTrialData, (t) => trialIdsToKeep.includes(t.trialId));
		}
	}

	//-- Filter trial results
	//---- Trial Name
	if (!reset && trialName) {
		filteredTrialData = filterOutCriteria(filteredTrialData, trialName, "trialName");
		if (mode === "all") {
			let trialIdsToFind = _.map(filteredTrialData, "trialId");
			filteredProtocolData = _.filter(filteredProtocolData, (p) =>
				_.some(trialIdsToFind, (t) => _.indexOf(p.trialIds, t) !== -1)
			);
		}
	}

	//---- Trial Owner
	if (!reset && trialOwner) {
		let trialOwnerRegex = new RegExp(_.escapeRegExp(trialOwner), "i");
		filteredTrialData = _.filter(filteredTrialData, (f) =>
			trialOwnerRegex.test(`${f.trialOwnerFirstName} ${f.trialOwnerLastName}`)
		);
		if (mode === "all") {
			let trialIdsToFind = _.map(filteredTrialData, "trialId");
			filteredProtocolData = _.filter(filteredProtocolData, (p) =>
				_.some(trialIdsToFind, (t) => _.indexOf(p.trialIds, t) !== -1)
			);
		}
	}

	//---- Cooperator
	if (!reset && cooperator) {
		filteredTrialData = filterOutCriteria(filteredTrialData, cooperator, "cooperatorName");
		if (mode === "all") {
			let trialIdsToFind = _.map(filteredTrialData, "trialId");
			filteredProtocolData = _.filter(filteredProtocolData, (p) =>
				_.some(trialIdsToFind, (t) => _.indexOf(p.trialIds, t) !== -1)
			);
		}
	}

	//---- City
	if (!reset && city) {
		filteredTrialData = filterOutCriteria(filteredTrialData, city, "city");
		if (mode === "all") {
			let trialIdsToFind = _.map(filteredTrialData, "trialId");
			filteredProtocolData = _.filter(filteredProtocolData, (p) =>
				_.some(trialIdsToFind, (t) => _.indexOf(p.trialIds, t) !== -1)
			);
		}
	}

	//---- State
	if (!reset && state) {
		filteredTrialData = filterOutCriteria(filteredTrialData, state, "stateName");
		if (mode === "all") {
			let trialIdsToFind = _.map(filteredTrialData, "trialId");
			filteredProtocolData = _.filter(filteredProtocolData, (p) =>
				_.some(trialIdsToFind, (t) => _.indexOf(p.trialIds, t) !== -1)
			);
		}
	}

	//---- Company
	if (!reset && company) {
		filteredTrialData = filterOutCriteria(filteredTrialData, company, "companyName");
		if (mode === "all") {
			let trialIdsToFind = _.map(filteredTrialData, "trialId");
			filteredProtocolData = _.filter(filteredProtocolData, (p) =>
				_.some(trialIdsToFind, (t) => _.indexOf(p.trialIds, t) !== -1)
			);
		}
	}

	//---- DEF Status
	if (!reset && defStatus) {
		let filteredTempTrialData = _.filter(trialStatuses, (ts) => {
			if (defStatus === "Exclusions") {
				return (ts.hasExclusions || ts.hasPartialExclusions) && (!ts.hasFlaggings && !ts.hasPartialFlaggings);
			}
			else if (defStatus === "Flags") {
				return (ts.hasFlaggings || ts.hasPartialFlaggings) && (!ts.hasExclusions && !ts.hasPartialExclusions);
			}
			else if (defStatus === "Both") {
				return (ts.hasFlaggings || ts.hasPartialFlaggings) && (ts.hasExclusions || ts.hasPartialExclusions);
			}
			else if (defStatus === "No Annotations") {
				return !ts.hasFlaggings && !ts.hasExclusions && !ts.hasPartialFlaggings && !ts.hasPartialExclusions;
			}
			else {
				return filteredTrialData;
			}
		});
		if (mode === "all") {
			let trialIdsToFind = _.map(filteredTempTrialData, "trialId");
			filteredTrialData = _.filter(filteredTrialData, (ftd) => {
				if (_.includes(trialIdsToFind, ftd.trialId)) {
					return ftd;
				}
			});

			filteredProtocolData = _.filter(filteredProtocolData, (p) =>
				_.some(trialIdsToFind, (t) => _.indexOf(p.trialIds, t) !== -1)
			);
		}
	}

	return {
		filteredTrialData: filteredTrialData,
		filteredProtocolData: filteredProtocolData
	};
};

//-- Creates regex expression and filters specified field on criteria
const filterOutCriteria = (data, criteria, dataField) => {
	let regexExpression = new RegExp(_.escapeRegExp(criteria), "i");
	return _.filter(data, (d) => regexExpression.test(d[`${dataField}`]));
};

export default filterResults;
