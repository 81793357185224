import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import _ from "lodash";

import { Segment, Grid, List, Loader, Form} from "semantic-ui-react";
import { toast } from "react-toastify";

import { useAuth0 } from "../../../../auth/auth0";
import { useUserAuth } from "../../../../hooks/useUserAuth";


import * as uavActions from "../../../../redux/actions/uavActions";

const UavManagement = () => {
	const clientId = useSelector((state) => (state.clients ? state.clients.currentId : null));

	const { getTokenSilently } = useAuth0();
	const userAuth = useUserAuth();
    const dispatch = useDispatch();

	//-- Data Source
	const [uavs, setUavs] = useState(null);
	const [clientName, setClientName] = useState(null);

	//-- Selected Data
	const [selectedUav, setSelectedUav] = useState(null);

	//-- UI Control
	const [mode, setMode] = useState("update");
	const [loading, setLoading] = useState(true);
	const [saving, setSaving] = useState(false);
	const [deleting, setDeleting] = useState(false);

	useEffect(() => {
		if (userAuth.isReady && clientId) {
			setClientName(userAuth.user.userInfo.clientMemberships.find((cm) => cm.clientId === clientId).clientName);
			getData();
		}
		setMode("update");
	}, [userAuth.isReady, clientId]);
	
	const getData = async () => {
		setLoading(true);
		const accessToken = await getTokenSilently();
		dispatch(uavActions.getUavDetailsData(accessToken, clientId))
		.then((res) => {
			if (res.statusCode === 200) {
				setUavs(res.data);
			} else {
				toast.error("Unable to load UAS data. Please try again.");
				setLoading(false);
			}
		})
		.catch((err) => {
			console.log(err);
			toast.error("Unable to load UAV's. Please try again.");
		});
	};

	//-- UAVs
	useEffect(() => {
		if (uavs && uavs.length > 0) {
			if (!selectedUav) {
				uavSelected(uavs[0].id,uavs[0].name);
			} else {
				uavSelected(selectedUav.id, selectedUav.name);
			}
			setMode("update");
		}
	}, [uavs]);
	useEffect(() => {
		if (
			uavs !== null
		) {
			setLoading(false);
		}
	}, [uavs, selectedUav]);

	const uavSelected = (uavId, uavName) => {
		if (uavId || uavName) {
			const selUav = uavs.find((u) => u.id === uavId || u.name === uavName);
			setSelectedUav(selUav);
			setMode("update");
		}
	};

	function prepareNewUavForm() {
		setSelectedUav(null);
		setMode("create");
	}

	const createUav = async () => {
		setSaving(true);

		const accessToken = await getTokenSilently();
		dispatch(uavActions.createUav(accessToken, selectedUav))
			.then((res) => {
				if (res.statusCode === 200) {
					toast.success("UAV created");
					getData();
				} else if (res.statusCode === 400) {
					if (res.messages && res.messages.length > 0) {
						res.messages.forEach((m) => {
							if (m.type === "Informational") {
								toast.success("UAV created");
								toast.info(m.text);	
							} else if (m.type === "FailedValidation") {
								toast.error(m.text);
							}
						});
					}
				}
				setSaving(false);
			})
			.catch(() => {
				toast.error("Unable to create UAV. Please try again.");
				setSaving(false);
			});
	};

	const updateUav = async () => {

		setSaving(true);

		const accessToken = await getTokenSilently();
		dispatch(uavActions.updateUav(accessToken, selectedUav))
			.then((res) => {
				if (res.statusCode === 200) {
					toast.success("UAV updated");
					getData();
				} else if (res.statusCode === 400) {
					if (res.messages && res.messages.length > 0) {
						res.messages.forEach((m) => {
							if (m.type === "Informational") {
								toast.success("UAV updated");
								toast.info(m.text);
							} else if (m.type === "FailedValidation") {
								toast.error(m.text);
							}
						});
					}
				}
				setSaving(false);
			})
			.catch((error) => {
				toast.error("Unable to update UAV. Please try again.");
				console.log(error);
				setSaving(false);
			});
	};

	const deleteUav = async () => {
		setDeleting(true);

		const accessToken = await getTokenSilently();
		dispatch(uavActions.deleteUav(accessToken, selectedUav.id))
			.then((res) => {					
				setSelectedUav(null);
				if (res.statusCode === 200) {
					toast.success("UAV deleted");
					getData();
				} else if (res.statusCode === 400) {
					if (res.messages && res.messages.length > 0) {
						res.messages.forEach((m) => {
							if (m.type === "Informational") {
								toast.success("UAV deleted");
								toast.info(m.text);
							} else if (m.type === "FailedValidation") {
								toast.error(m.text);
							}
						});
					}
				}
				setDeleting(false);
			})
			.catch((error) => {
				toast.error("Unable to delete UAV. Please try again.");
				console.log(error);
				setDeleting(false);
			});
	};

	return (
		<Segment style={{ marginLeft: 50, marginTop: 15, paddingBottom: "unset" }}>
			<h2 style={{ float: "left" }}>Configure UAV</h2>
			<h2 style={{ float: "right", marginTop: "unset", color: "rgba(7, 55, 99, 0.75)" }}>
				<i>{clientName}</i>
			</h2>
			<hr style={{ clear: "both" }} />
			{loading && (
				<Segment basic style={{ marginBottom: 15 }}>
					<Loader active />
				</Segment>
			)}
			{!loading && (
				<Grid id="configure-uas-grid" columns="equal">
					<Grid.Row style={{ paddingBottom: "unset" }}>
						<Grid.Column width={4}>
							<label htmlFor="form-select-uas" className="fieldLabel">
								UAVs
							</label>
									<Form.Button
										id="new-uav"
										color="green"
										content="New UAV"
										style={{ width: "calc(100% + 4px)", marginTop: 5, marginBottom: 10 }}
										onClick={() => prepareNewUavForm()}
									/>
							<List
								id="select-uav"
								selection
								size="big"
								style={{
									minHeight: 150,
									maxHeight: 300,
									marginTop: "unset",
									overflowY: "auto",
									border: "1px solid #DEDEDF",
									backgroundColor: "#FFFFFF"
								}}
							>
								{_.map(uavs, ({ id, name }) => {
									return (
										<List.Item
											key={id}
											active={selectedUav?.id === id}
											onClick={() => {
												uavSelected(id, name);
											}}
										>
											<List.Content>
												<span style={{ fontSize: 14, color: "#000000" }}>{name}</span>
											</List.Content>
										</List.Item>
									);
								})}
							</List>
						</Grid.Column>
						<Grid.Column>
						<Form>
			<Grid id="configure-uav-form-grid" columns="equal">
				<Grid.Row style={{ paddingBottom: "unset" }}>
					<Grid.Column>
						<Form.Group widths={"equal"}>
							<Form.Input
								id="form-input-name"
								width={12}
								label="Name"
								value={selectedUav?.name ?? ""}
								required
								onChange={(event) => setSelectedUav({ ...selectedUav, name: event.target.value})}
							/>
						</Form.Group>
						<Form.Group widths={"equal"}>
							<Form.Input
								id="form-input-company"
								width={12}
								label="Company"
								value={selectedUav?.company ?? ""}
								required
								onChange={(event) => setSelectedUav({ ...selectedUav, company: event.target.value})}
							/>
						</Form.Group>
						<Form.Group style={{ marginBottom: 3 }}>
							<label htmlFor="form-text-area-description" className="fieldLabel" style={{ marginLeft: 7 }}>
								Description
							</label>
						</Form.Group>
						<Form.Group>
							<Form.TextArea
								id="form-text-area-description"
								width={16}
								value={selectedUav?.description ?? ""}
								onChange={(event) => setSelectedUav({ ...selectedUav, description: event.target.value})}
								rows="5"
							/>
						</Form.Group>
						<hr style={{ clear: "both" }} />
						<Form.Group>
								<Form.Checkbox
									id="form-uav-enabled"
									width={3}
									label="UAV Enabled?"
									style={{ marginTop: 33 }}
									checked={selectedUav?.enabled ?? false}
									onChange={(event, data) => {
										setSelectedUav({ ...selectedUav, enabled: data.checked})}
									}
								/>
							</Form.Group>
						<Form.Group style={{ float: "right"}}>
							{mode === "update" && selectedUav?.canDelete && (
								<Form.Button
									floated="right"
									negative
									content="Delete"
									onClick={() => {
										deleteUav();
									}}
									disabled={deleting}
									loading={deleting}
								/>
							)}
							<Form.Button
								floated="right"
								color="green"
								content={mode === "create" ? "Create" : "Update"}
								onClick={() => {
									if (mode === "create") {
										createUav();
									} else {
										updateUav();
									}
								}}
								disabled={saving}
								loading={saving}
							/>
						</Form.Group>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</Form>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			)}
		</Segment>
	);
}

export default UavManagement;