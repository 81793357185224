import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import _ from "lodash";

import { Segment, Grid, List, Form, Checkbox, Loader } from "semantic-ui-react";
import { toast } from "react-toastify";

import { useAuth0 } from "../../../../auth/auth0";

import * as companyActions from "../../../../redux/actions/companyActions";

const CompanyManagement = () => {
	const { getTokenSilently } = useAuth0();
	const dispatch = useDispatch();

	//-- Data Source
	const [companies, setCompanies] = useState([]);
	const [selectedCompany, setSelectedCompany] = useState(null);
	const clientId = useSelector((state) => (state.clients ? state.clients.currentId : null));

	//-- Dropdown Options
	const [companyOptions, setCompanyOptions] = useState([]);
	const [companyTypeOptions, setCompanyTypeOptions] = useState([]);

	//-- UI Control
	const [mode, setMode] = useState("update");
	const [saving, setSaving] = useState(false);
	const [deleting, setDeleting] = useState(false);
	const [generatingCompanyCode, setGeneratingCompanyCode] = useState(false);

	useEffect(() => {
		if (clientId) {
			getCompanyTypes();
		}
	}, [clientId]);

	async function getCompanyTypes() {
		const accessToken = await getTokenSilently();
		dispatch(companyActions.getCompanyTypes(clientId, accessToken))
			.then((res) => {
				const opts = _.map(res.data, ({ id, name }) => {
					const opt = { key: id, value: id, text: name };
					return opt;
				});
				setCompanyTypeOptions(opts);

				getCompanies(true);
			})
			.catch((err) => {
				console.log(err);
				toast.error("Could not find company type options");
			});
	}

	async function getCompanies(selectFirstCompany = true) {
		const accessToken = await getTokenSilently();
		dispatch(companyActions.getCompanyManagementInfo(clientId, accessToken)).then((res) => {
			setCompanies(res.data);
			populateCompanyOptions(res.data, selectFirstCompany);
		});
	}

	function populateCompanyOptions(cOpts, selectFirstCompany) {
		const companyOpts = _.map(cOpts, ({ id, name, companyCode, companyTypeIds }) => {
			const opt = {
				key: id,
				value: id,
				text: name,
				companyCode,
				companyTypeIds
			};
			return opt;
		});

		if (companyOpts.length > 0 && selectFirstCompany) {
			getCompanyDetail(companyOpts[0].value, cOpts);
		}

		setCompanyOptions(companyOpts);
	}

	function getCompanyDetail(companyId, listOfCompanies) {
		const companyOpt = listOfCompanies ?? companies;
		const company = _.find(companyOpt, (p) => {
			return p.id === companyId;
		});

		setSelectedCompany(company);
		setMode("update");
	}

	function prepareNewCompanyForm() {
		setSelectedCompany({ name: "", companyCode: "", enabled: true, clientId: clientId, companyTypeIds: [] });
		setMode("create");
	}

	async function saveCompany() {
		setSaving(true);
		const accessToken = await getTokenSilently();
		if (mode === "create") {
			dispatch(companyActions.createCompany(selectedCompany, accessToken))
				.then((res) => {
					if (res.statusCode === 200) {
						getCompanies(true);
						toast.success("Created!");
					} else if (res.statusCode === 400) {
						if (res.messages && res.messages.length > 0) {
							res.messages.forEach((m) => {
								if (m.type === "Informational") {
									toast.info(m.text);
								} else if (m.type === "FailedValidation") {
									toast.error(m.text);
								}
							});
						}
					}
					setSaving(false);
				})
				.catch((err) => {
					toast.error("Unable to create new company. Please try again.");
					setSaving(false);
					console.log(err);
				});
		} else {
			dispatch(companyActions.editCompany(selectedCompany, accessToken))
				.then((res) => {
					if (res.statusCode === 200) {
						getCompanies(true);
						toast.success("Updated!");
					} else if (res.statusCode === 400) {
						if (res.messages && res.messages.length > 0) {
							res.messages.forEach((m) => {
								if (m.type === "Informational") {
									toast.info(m.text);
								} else if (m.type === "FailedValidation") {
									toast.error(m.text);
								}
							});
						}
					}
					setSaving(false);
				})
				.catch((err) => {
					toast.error("Unable to update company. Please try again.");
					setSaving(false);
					console.log(err);
				});
		}
	}

	async function deleteCompany() {
		setDeleting(true);
		const accessToken = await getTokenSilently();
		dispatch(companyActions.deleteCompany(selectedCompany.id, clientId, accessToken))
			.then(() => {
				getCompanies(true);
				setDeleting(false);
				toast.success("Deleted company successfully.");
			})
			.catch((err) => {
				setDeleting(false);
				console.log(err);
				toast.error("Failed to delete company.");
			});
	}

	async function generateCompanyCode() {
		setGeneratingCompanyCode(true);
		const accessToken = await getTokenSilently();
		if (selectedCompany.name) {
			dispatch(companyActions.generateCompanyCode(selectedCompany.clientId, selectedCompany.name, accessToken))
				.then((res) => {
					setSelectedCompany({ ...selectedCompany, companyCode: res.data });
					setGeneratingCompanyCode(false);
				})
				.catch((err) => {
					console.log(err);
					toast.error("Failed to generate company code.");
					setGeneratingCompanyCode(false);
				});
		} else {
			toast.info("Please enter a company name to generate a company code.");
			setGeneratingCompanyCode(false);
		}
	}

	return (
		<Segment style={{ marginLeft: 50, marginTop: 15 }}>
			<h2 style={{ float: "left" }}>Company Management</h2>
			<hr style={{ clear: "both" }} />
			<Form>
				<Grid id="company-management-grid" columns="equal">
					<Grid.Row>
						<Grid.Column width="4">
							<label htmlFor="form-select-company" className="fieldLabel">
								Companies
							</label>
							<Form.Button
								id="new-company"
								color="green"
								content="New Company"
								style={{ width: "calc(100% + 4px)", marginTop: 5, marginBottom: 10 }}
								onClick={() => {
									prepareNewCompanyForm();
								}}
							/>
							<List
								id="form-select-company"
								selection
								size="big"
								style={{
									minHeight: 150,
									maxHeight: 500,
									marginTop: "unset",
									overflowY: "auto",
									border: "1px solid #DEDEDF",
									backgroundColor: "#FFFFFF"
								}}
							>
								{_.map(companyOptions, ({ key, value, text, companyCode }) => {
									return (
										<List.Item
											key={key}
											active={selectedCompany?.id === value}
											onClick={() => {
												getCompanyDetail(value);
											}}
										>
											<List.Content>
												<span style={{ fontSize: 14, color: "#000000" }}>{text}</span>
												{companyCode && <span style={{ float: "right", fontSize: 12 }}>({companyCode})</span>}
											</List.Content>
										</List.Item>
									);
								})}
							</List>
						</Grid.Column>
						{selectedCompany ? (
							<Grid.Column>
								<Form.Group widths={"equal"}>
									<Form.Input
										id="form-company-name"
										label="Company Name"
										value={selectedCompany?.name ?? ""}
										required
										maxLength="50"
										onChange={(event) => setSelectedCompany({ ...selectedCompany, name: event.target.value })}
									/>
								</Form.Group>
								<Form.Group widths="equal">
									{_.map(companyTypeOptions, (opts, i) => {
										return (
											<>
												{i !== 0 && <br />}
												<Checkbox
													style={{ padding: "7px" }}
													key={i}
													label={opts.text}
													checked={
														selectedCompany?.companyTypeIds?.length > 0
															? selectedCompany?.companyTypeIds.includes(opts.key)
															: false
													}
													onClick={(event, { checked }) => {
														if (!checked) {
															let newCompanyTypeIds = _.filter(
																selectedCompany.companyTypeIds,
																(cti) => cti !== opts.key
															);
															setSelectedCompany({
																...selectedCompany,
																companyTypeIds: newCompanyTypeIds
															});
														} else {
															let newCompanyTypeIds = selectedCompany.companyTypeIds.concat([opts.key]);
															setSelectedCompany({
																...selectedCompany,
																companyTypeIds: newCompanyTypeIds
															});
														}
													}}
												/>
											</>
										);
									})}
								</Form.Group>
								<Form.Group widths="equal">
									<Form.Input
										id="form-company-code"
										width={13}
										label="Company Code"
										value={selectedCompany?.companyCode ?? ""}
										required
										maxLength="7"
										onChange={(event) =>
											setSelectedCompany({ ...selectedCompany, companyCode: event.target.value.toUpperCase() })
										}
									/>
								</Form.Group>
								{mode === "create" ? (
									<Form.Group>
										<Form.Button
											width="3"
											color="blue"
											content="Generate Company Code"
											onClick={generateCompanyCode}
											loading={generatingCompanyCode}
											disabled={generatingCompanyCode}
										/>
									</Form.Group>
								) : null}

								<Form.Group>
									<Form.Checkbox
										id="form-company-enabled"
										width={3}
										label="Enabled?"
										style={{ marginTop: 33 }}
										checked={selectedCompany?.enabled ? true : false}
										onChange={(e, { checked }) => {
											setSelectedCompany({ ...selectedCompany, enabled: checked });
										}}
									/>
								</Form.Group>
								<hr style={{ clear: "both" }} />
								<Form.Group widths={"equal"}>
									<Form.Input
										id="form-company-created-by"
										width={12}
										label="Created By"
										value={
											selectedCompany?.createdByFirstName
												? selectedCompany?.createdByFirstName + " " + selectedCompany?.createdByLastName
												: ""
										}
										readOnly
									/>
								</Form.Group>
								<Form.Group style={{ marginTop: 50, float: "right" }}>
									{selectedCompany?.canDelete ? (
										<Form.Button
											floated="right"
											color="red"
											content="Delete"
											loading={deleting}
											disabled={saving || deleting}
											onClick={() => {
												deleteCompany();
											}}
										/>
									) : null}

									<Form.Button
										floated="right"
										color="green"
										content="Save"
										loading={saving}
										disabled={saving || deleting}
										onClick={() => {
											saveCompany();
										}}
									/>
								</Form.Group>
							</Grid.Column>
						) : (
							<Loader active />
						)}
					</Grid.Row>
				</Grid>
			</Form>
		</Segment>
	);
};

export default CompanyManagement;
