import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import { useHistory } from "react-router-dom";
import { useModuleNavigation } from "../../../../hooks/useModuleNavigation";
import { Loader, Form, Button, Segment, Grid, Radio, Checkbox, Modal, Header } from "semantic-ui-react";
import { toast } from "react-toastify";
import _ from "lodash";

import { useAuth0 } from "../../../../auth/auth0";
import { useUserAuth } from "../../../../hooks/useUserAuth";
import ProtocolFavoritingWidget from "../../Widgets/Favorites/ProtocolFavoritingWidget";

import * as protocolActions from "../../../../redux/actions/protocolActions";
import { useUserSettings } from "../../../../hooks/useUserSettings";

const ProtocolInfo = ({ mode }) => {
	const dispatch = useDispatch();
	const { getTokenSilently } = useAuth0();
	const userAuth = useUserAuth();
	const history = useHistory();
	const moduleNavigation = useModuleNavigation();
	const userSettings = useUserSettings();

	const reduxProtocolData = useSelector((state) => (state.protocolData ? state.protocolData : null));

	//-- Data
	const [protocolInfo, setProtocolInfo] = useState(null);

	//-- Data Sources
	const [protocolOwnerOptions, setProtocolOwnerOptions] = useState([]);
	const [cropOptions, setCropOptions] = useState([]);
	const [growingSeasonOptions, setGrowingSeasonOptions] = useState([]);
	const [geographyOptions, setGeographyOptions] = useState([]);
	const [plotSizeOptions, setPlotSizeOptions] = useState([]);
	const [designOptions, setDesignOptions] = useState([]);
	const [meanComparisonOptions, setMeanComparisonOptions] = useState([]);
	const [alphaOptions, setAlphaOptions] = useState([]);
	const [regulatoryOptions, setRegulatoryOptions] = useState([]);
	const [variablesTestedOptions, setVariablesTestedOptions] = useState([]);

	//-- Validation
	const [formError, setFormError] = useState(null);
	const [errors, setErrors] = useState({});

	//-- UI Control
	const [loading, setLoading] = useState(false);
	const [openDeleteModal, setOpenDeleteModal] = useState(false);
	const [initialLoad, setInitialLoad] = useState(true);

	useEffect(() => {
		if (
			(protocolInfo === null && reduxProtocolData?.id === null && moduleNavigation.protocolId === null) ||
			moduleNavigation.activeSubmodule.name === "new"
		) {
			setInitialProtocolInfo();
		}

		if (protocolOwnerOptions.length === 0) {
			getDropdownOptions();
		}
	}, []);
	//}, [moduleNavigation.currentClientId]);

	useEffect(() => {
		if (reduxProtocolData && reduxProtocolData?.id !== null) {
			setExistingProtocolInfo(reduxProtocolData);
		}
	}, [reduxProtocolData]);

	function setExistingProtocolInfo(data) {
		setProtocolInfo({
			id: data.id,
			protocolId: data.protocolId, //-- user defined id
			protocolName: data.protocolName,
			protocolOwnerId: data.protocolOwnerId,
			cropId: data.cropId,
			growingSeasonId: data.growingSeasonId,
			geographyId: data.geographyId,
			treatments: data.treatments,
			replicates: data.replicates,
			plotSizeId: data.plotSizeId,
			designId: data.designId,
			meanComparisonId: data.meanComparisonId,
			alphaId: data.alphaId,
			yield: data.yield,
			regulatory: data.regulatories,
			variablesTested: data.protocolVariables,
			rotation: data.rotation,
			genetics: data.genetics,
			herbicides: data.herbicides,
			fertilizer: data.fertilizer,
			pesticides: data.pesticides,
			other: data.other,
			objectives: data.objectives
		});
	}

	function setInitialProtocolInfo() {
		setProtocolInfo({
			id: null,
			protocolId: null, //-- user defined id
			protocolName: null,
			protocolOwnerId: null,
			cropId: null,
			growingSeasonId: null,
			geographyId: null,
			treatments: null,
			replicates: null,
			plotSizeId: null,
			designId: null,
			meanComparisonId: null,
			alphaId: null,
			yield: true,
			regulatory: [],
			variablesTested: [],
			rotation: null,
			genetics: null,
			herbicides: null,
			fertilizer: null,
			pesticides: null,
			other: null,
			objectives: null
		});
	}

	async function getDropdownOptions() {
		setLoading(true);
		const accessToken = await getTokenSilently();
		dispatch(protocolActions.getProtocolInfoOptions(userAuth.currentClientId, false, accessToken))
			.then((res) => {
				setInitialProtocolOwnerOptions(res.data.protocolOwners);
				setInitialCropOptions(res.data.crops);
				setInitialGrowingSeasonOptions(res.data.growingSeasons);
				setInitialGeographyOptions(res.data.geographies);
				setInitialPlotSizeOptions(res.data.plotSizes);
				setInitialDesignOptions(res.data.designs);
				setInitialMeanComparisonOptions(res.data.meanComparisons);
				setInitialAlphaOptions(res.data.alphas);
				setInitialRegulatoryOptions(res.data.regulatories);
				setInitialVariablesTested(res.data.protocolVariables);

				setLoading(false);
				setInitialLoad(false);
			})
			.catch((err) => {
				toast.error(err);
				setLoading(false);
				setInitialLoad(false);
			});
	}

	function setInitialProtocolOwnerOptions(options) {
		let optionList = _.map(options, (o) => {
			return { key: o.personId, value: o.personId, text: `${o.firstName} ${o.lastName}` };
		});

		setProtocolOwnerOptions(optionList);
	}

	function setInitialCropOptions(options) {
		let optionList = _.map(options, (o) => {
			return { key: o.cropId, value: o.cropId, text: o.name };
		});

		setCropOptions(optionList);
	}

	function setInitialGrowingSeasonOptions(options) {
		let optionList = _.map(options, (o) => {
			return { key: o.growingSeasonId, value: o.growingSeasonId, text: o.name };
		});

		setGrowingSeasonOptions(optionList);
	}

	function setInitialGeographyOptions(options) {
		let optionList = _.map(options, (o) => {
			return { key: o.id, value: o.id, text: o.name };
		});

		setGeographyOptions(optionList);
	}

	function setInitialPlotSizeOptions(options) {
		let optionList = _.map(options, (o) => {
			return { key: o.id, value: o.id, text: `${o.name} (${o.description})` };
		});

		setPlotSizeOptions(optionList);
	}

	function setInitialDesignOptions(options) {
		let optionList = _.map(options, (o) => {
			return { key: o.id, value: o.id, text: o.name };
		});

		setDesignOptions(optionList);

		if (mode === "new" || moduleNavigation.protocolId === null) {
			//-- Remove when more designs exist
			let defaultDesignId = _.find(optionList, { text: "Randomized Complete Block (RCB)" })?.key;

			setProtocolInfo((protocolInfo) => ({ ...protocolInfo, designId: defaultDesignId }));
		}
	}

	function setInitialMeanComparisonOptions(options) {
		let optionList = _.map(options, (o) => {
			return { key: o.id, value: o.id, text: o.name };
		});

		setMeanComparisonOptions(optionList);

		if (mode === "new" || moduleNavigation.protocolId === null) {
			//-- Remove when more mean comparison options exist
			let defaultMeanComparisonId = _.find(optionList, { text: "Tukey HSD" })?.key;

			setProtocolInfo((protocolInfo) => ({ ...protocolInfo, meanComparisonId: defaultMeanComparisonId }));
		}
	}

	function setInitialAlphaOptions(options) {
		let optionList = _.map(options, (o) => {
			return { key: o.id, value: o.id, text: o.value };
		});

		setAlphaOptions(optionList);

		if (mode === "new" || moduleNavigation.protocolId === null) {
			//-- Remove when tracking user alpha value preferences
			let defaultAlphaId = _.find(optionList, { text: 0.1 })?.key;

			setProtocolInfo((protocolInfo) => ({ ...protocolInfo, alphaId: defaultAlphaId }));
		}
	}

	function setInitialRegulatoryOptions(options) {
		let optionList = _.map(options, (o) => {
			return { key: o.id, value: o.id, text: o.name };
		});

		setRegulatoryOptions(optionList);

		if (mode === "new" || moduleNavigation.protocolId === null) {
			let regulatoryList = _.map(optionList, (ol) => {
				return { id: ol.key, checked: false };
			});

			setProtocolInfo((protocolInfo) => ({ ...protocolInfo, regulatory: regulatoryList }));
		}
	}

	function setInitialVariablesTested(options) {
		let optionList = _.map(options, (o) => {
			return { key: o.id, value: o.id, text: o.name };
		});

		setVariablesTestedOptions(optionList);

		if (mode === "new" || moduleNavigation.protocolId === null) {
			let variablesTestedList = _.map(optionList, (ol) => {
				return { id: ol.key, checked: false };
			});

			setProtocolInfo((protocolInfo) => ({ ...protocolInfo, variablesTested: variablesTestedList }));
		}
	}

	async function handleSave(continueProcess) {
		let valid = validateForm();

		if (valid) {
			setLoading(true);
			const accessToken = await getTokenSilently();
			if (mode === "new" || moduleNavigation.protocolId === null || moduleNavigation.activeSubmodule.name === "new") {
				dispatch(protocolActions.createProtocol(protocolInfo, userAuth.currentClientId, accessToken))
					.then((res) => {
						toast.success("Protocol saved successfully.");

						setLoading(false);
						if (!continueProcess && (!moduleNavigation.protocolId || moduleNavigation.activeSubmodule.name === "new")) {
							history.push(moduleNavigation.createProtocolLink(true, res.data?.id, "info"));
						}

						if (continueProcess) {
							history.push(moduleNavigation.createProtocolLink(true, res.data?.id, "applications"));
						}
					})
					.catch((err) => {
						console.log(err);
						toast.error("Protocol failed to save.");

						setLoading(false);
					});
			} else {
				dispatch(protocolActions.updateProtocol(protocolInfo, userAuth.currentClientId, accessToken))
					.then((res) => {
						toast.success("Protocol saved successfully.");

						setLoading(false);
						if (continueProcess) {
							history.push(moduleNavigation.createProtocolLink(true, res.data?.id, "applications"));
						}
					})
					.catch((err) => {
						console.log(err);
						toast.error("Protocol failed to save.");

						setLoading(false);
					});
			}
		} else {
			toast.error("Please fix errors before saving.");
		}
	}

	async function handleDelete() {
		setLoading(true);

		const accessToken = await getTokenSilently();
		dispatch(protocolActions.deleteProtocol(protocolInfo.id, userAuth.currentClientId, accessToken))
			.then(() => {
				setLoading(false);
				setOpenDeleteModal(false);
				toast.success("Protocol was deleted successfully.");

				history.push(moduleNavigation.createProtocolLink());
			})
			.catch(() => {
				setLoading(false);
				toast.error("There was a problem deleting this protocol. Please try again later.");
			});
	}

	function validateForm() {
		let valid = true;
		let errorList = {};

		if (protocolInfo.protocolId === null || protocolInfo.protocolId === "") {
			valid = false;
			errorList.protocolId = true;
		}

		if (protocolInfo.protocolName === null || protocolInfo.protocolName === "") {
			valid = false;
			errorList.protocolName = true;
		}

		if (protocolInfo.protocolOwnerId === null || protocolInfo.protocolOwnerId === "") {
			valid = false;
			errorList.protocolOwnerId = true;
		}

		if (protocolInfo.cropId === null || protocolInfo.cropId === "") {
			valid = false;
			errorList.cropId = true;
		}

		if (protocolInfo.growingSeasonId === null || protocolInfo.growingSeasonId === "") {
			valid = false;
			errorList.growingSeasonId = true;
		}

		if (protocolInfo.geographyId === null || protocolInfo.geographyId === "") {
			valid = false;
			errorList.geographyId = true;
		}

		if (
			protocolInfo.treatments === null ||
			protocolInfo.treatments === "" ||
			isNaN(parseFloat(protocolInfo.treatments)) ||
			parseFloat(protocolInfo.treatments) > 100
		) {
			valid = false;
			errorList.treatments = true;
		}

		if (
			protocolInfo.replicates === null ||
			protocolInfo.replicates === "" ||
			isNaN(parseFloat(protocolInfo.replicates)) ||
			parseFloat(protocolInfo.replicates) > 100
		) {
			valid = false;
			errorList.replicates = true;
		}

		if (protocolInfo.plotSizeId === null || protocolInfo.plotSizeId === "") {
			valid = false;
			errorList.plotSizeId = true;
		}

		if (protocolInfo.designId === null || protocolInfo.designId === "") {
			valid = false;
			errorList.designId = true;
		}

		if (protocolInfo.meanComparisonId === null || protocolInfo.meanComparisonId === "") {
			valid = false;
			errorList.meanComparisonId = true;
		}

		if (protocolInfo.alphaId === null || protocolInfo.alphaId === "") {
			valid = false;
			errorList.alphaId = true;
		}

		if (protocolInfo.rotation?.length > 200) {
			valid = false;
			errorList.rotation = true;
		}

		if (protocolInfo.genetics?.length > 200) {
			valid = false;
			errorList.genetics = true;
		}

		if (protocolInfo.herbicides?.length > 200) {
			valid = false;
			errorList.herbicides = true;
		}

		if (protocolInfo.fertilizer?.length > 200) {
			valid = false;
			errorList.fertilizer = true;
		}

		if (protocolInfo.pesticides?.length > 200) {
			valid = false;
			errorList.pesticides = true;
		}

		if (protocolInfo.other?.length > 200) {
			valid = false;
			errorList.other = true;
		}

		if (protocolInfo.objectives?.length > 200) {
			valid = false;
			errorList.objectives = true;
		}

		setErrors(errorList);
		setFormError(!valid);

		return valid;
	}

	return initialLoad ? (
		<Loader active inline="centered" />
	) : (
		<>
			<Segment basic style={{ minHeight: 50, marginBottom: 30 }}>
				<Button
					id="form-button-save"
					floated="right"
					primary
					content={"Save"}
					onClick={() => {
						handleSave(false);
					}}
					disabled={loading}
					loading={loading}
				/>
				<Button
					id="form-button-continue"
					floated="right"
					color="green"
					content={"Save and Continue"}
					onClick={() => {
						handleSave(true);
					}}
					disabled={loading}
					loading={loading}
				/>
				{mode === "edit" && (
					<Button
						id="form-button-delete"
						floated="right"
						negative
						content={"Delete"}
						onClick={() => {
							setOpenDeleteModal(true);
						}}
						disabled={loading}
						loading={loading}
					/>
				)}
			</Segment>
			<Segment basic id="protocol-info-form-segment" style={{ marginLeft: 50, paddingBottom: "unset" }}>
				<h2 style={{ float: "left" }}>Protocol Info</h2>
				<div style={{ float: "right" }}>
					{moduleNavigation.protocolId && moduleNavigation.activeSubmodule.name !== "new" ? (
						<ProtocolFavoritingWidget
							style={{ display: "inline" }}
							clientId={userAuth.currentClientId.toUpperCase()}
							protocolId={moduleNavigation.protocolId.toUpperCase()}
							userSettings={userSettings}
						></ProtocolFavoritingWidget>
					) : (
						<></>
					)}
					<h2 style={{ float: "right", marginTop: "unset", color: "rgba(7, 55, 99, 0.75)" }}>
						<i>{protocolInfo?.protocolName ?? ""}</i>
					</h2>
				</div>
				<hr style={{ clear: "both" }} />
				<Form error={formError}>
					<Grid>
						<Grid.Row>
							<Grid.Column width="6">
								{" "}
								{/* Left col */}
								<Grid.Row>
									<Grid.Column style={{ fontSize: "1.2em" }}>Overview:</Grid.Column>
								</Grid.Row>
								<br />
								<Grid.Row>
									<Grid.Column width="4" textAlign="right">
										<Form.Field>
											<label htmlFor="form-input-protocol-id">Protocol ID *</label>
										</Form.Field>
									</Grid.Column>
									<Grid.Column width="8" textAlign="right">
										<Form.Input
											id="form-input-protocol-id"
											defaultValue={protocolInfo?.protocolId}
											error={errors?.protocolId ?? false}
											disabled={loading}
											loading={loading}
											onBlur={(event) => {
												let value = event.target.value;
												setProtocolInfo((protocolInfo) => ({ ...protocolInfo, protocolId: value }));
											}}
										/>
									</Grid.Column>
								</Grid.Row>
								<Grid.Row>
									<Grid.Column width="4" textAlign="right">
										<Form.Field>
											<label htmlFor="form-input-protocol-name">Protocol Name *</label>
										</Form.Field>
									</Grid.Column>
									<Grid.Column width="8" textAlign="right">
										<Form.Input
											id="form-input-protocol-name"
											defaultValue={protocolInfo?.protocolName}
											error={errors?.protocolName ?? false}
											disabled={loading}
											loading={loading}
											onBlur={(event) => {
												let value = event.target.value;
												setProtocolInfo((protocolInfo) => ({ ...protocolInfo, protocolName: value }));
											}}
										/>
									</Grid.Column>
								</Grid.Row>
								<Grid.Row>
									<Grid.Column width="4" textAlign="right">
										<Form.Field>
											<label htmlFor="form-select-protocol-owner">Protocol Owner *</label>
										</Form.Field>
									</Grid.Column>
									<Grid.Column width="8" textAlign="right">
										<Form.Select
											id="form-select-protocol-owner"
											search
											options={protocolOwnerOptions}
											value={protocolInfo?.protocolOwnerId}
											error={errors?.protocolOwnerId ?? false}
											disabled={loading}
											loading={loading}
											onChange={(event, { value }) => {
												setProtocolInfo((protocolInfo) => ({ ...protocolInfo, protocolOwnerId: value }));
											}}
										/>
									</Grid.Column>
								</Grid.Row>
								<Grid.Row>
									<Grid.Column width="4" textAlign="right">
										<Form.Field>
											<label htmlFor="form-select-crop">Crop *</label>
										</Form.Field>
									</Grid.Column>
									<Grid.Column width="8" textAlign="right">
										<Form.Select
											id="form-select-crop"
											search
											options={cropOptions}
											value={protocolInfo?.cropId}
											error={errors?.cropId ?? false}
											disabled={loading || mode === "edit"}
											loading={loading}
											onChange={(event, { value }) => {
												setProtocolInfo((protocolInfo) => ({ ...protocolInfo, cropId: value }));
											}}
										/>
									</Grid.Column>
								</Grid.Row>
								<Grid.Row>
									<Grid.Column width="4" textAlign="right">
										<Form.Field>
											<label htmlFor="form-select-growing-season">Growing Season *</label>
										</Form.Field>
									</Grid.Column>
									<Grid.Column width="8" textAlign="right">
										<Form.Select
											id="form-select-growing-season"
											search
											options={growingSeasonOptions}
											value={protocolInfo?.growingSeasonId}
											error={errors?.growingSeasonId ?? false}
											disabled={loading}
											loading={loading}
											onChange={(event, { value }) => {
												setProtocolInfo((protocolInfo) => ({ ...protocolInfo, growingSeasonId: value }));
											}}
										/>
									</Grid.Column>
								</Grid.Row>
								<Grid.Row>
									<Grid.Column width="4" textAlign="right">
										<Form.Field>
											<label htmlFor="form-select-geography">Geopraphy *</label>
										</Form.Field>
									</Grid.Column>
									<Grid.Column width="8" textAlign="right">
										<Form.Select
											id="form-select-geography"
											search
											options={geographyOptions}
											value={protocolInfo?.geographyId}
											error={errors?.geographyId ?? false}
											disabled={loading}
											loading={loading}
											onChange={(event, { value }) => {
												setProtocolInfo((protocolInfo) => ({ ...protocolInfo, geographyId: value }));
											}}
										/>
									</Grid.Column>
								</Grid.Row>
								<br />
								<Grid.Row>
									<Grid.Column style={{ fontSize: "1.2em" }}>Design/Analysis:</Grid.Column>
								</Grid.Row>
								<br />
								<Grid.Row>
									<Grid.Column width="4" textAlign="right">
										<Form.Field>
											<label htmlFor="form-input-treatments">Treatments *</label>
										</Form.Field>
									</Grid.Column>
									<Grid.Column width="8" textAlign="right">
										<Form.Input
											id="form-input-treatments"
											defaultValue={protocolInfo?.treatments}
											error={errors?.treatments ?? false}
											disabled={loading}
											loading={loading}
											onBlur={(event) => {
												let value = event.target.value;
												setProtocolInfo((protocolInfo) => ({ ...protocolInfo, treatments: value }));
												if (!isNaN(parseFloat(value)) && isFinite(value)) {
													if (parseFloat(value) > 100) {
														toast.error("Treatments must be 100 or less.");
														setErrors((errors) => ({ ...errors, treatments: true }));
													} else {
														setErrors((errors) => ({ ...errors, treatments: false }));
													}
												} else if (value !== "") {
													toast.error("Treatments must be a number");
													setErrors((errors) => ({ ...errors, treatments: true }));
												}
											}}
										/>
									</Grid.Column>
								</Grid.Row>
								<Grid.Row>
									<Grid.Column width="4" textAlign="right">
										<Form.Field>
											<label htmlFor="form-input-replicates">Replicates *</label>
										</Form.Field>
									</Grid.Column>
									<Grid.Column width="8" textAlign="right">
										<Form.Input
											id="form-input-replicates"
											defaultValue={protocolInfo?.replicates}
											error={errors?.replicates ?? false}
											disabled={loading}
											loading={loading}
											onBlur={(event) => {
												let value = event.target.value;
												setProtocolInfo((protocolInfo) => ({ ...protocolInfo, replicates: value }));
												if (!isNaN(parseFloat(value)) && isFinite(value)) {
													if (parseFloat(value) > 100) {
														toast.error("Replicates must be 100 or less.");
														setErrors((errors) => ({ ...errors, replicates: true }));
													} else {
														setErrors((errors) => ({ ...errors, replicates: false }));
													}
												} else if (value !== "") {
													toast.error("Replicates must be a number");
													setErrors((errors) => ({ ...errors, replicates: true }));
												}
											}}
										/>
									</Grid.Column>
								</Grid.Row>
								<Grid.Row>
									<Grid.Column width="4" textAlign="right">
										<Form.Field>
											<label htmlFor="form-select-plot-size">Plot Size *</label>
										</Form.Field>
									</Grid.Column>
									<Grid.Column width="8" textAlign="right">
										<Form.Select
											id="form-select-plot-size"
											search
											options={plotSizeOptions}
											value={protocolInfo?.plotSizeId}
											error={errors?.plotSizeId ?? false}
											disabled={loading}
											loading={loading}
											onChange={(event, { value }) => {
												setProtocolInfo((protocolInfo) => ({ ...protocolInfo, plotSizeId: value }));
											}}
										/>
									</Grid.Column>
								</Grid.Row>
								<Grid.Row>
									<Grid.Column width="4" textAlign="right">
										<Form.Field>
											<label htmlFor="form-select-design">Design *</label>
										</Form.Field>
									</Grid.Column>
									<Grid.Column width="8" textAlign="right">
										<Form.Select
											id="form-select-design"
											search
											options={designOptions}
											value={protocolInfo?.designId}
											error={errors?.designId ?? false}
											disabled={true} //-- Remove later
											loading={loading}
											onChange={(event, { value }) => {
												setProtocolInfo((protocolInfo) => ({ ...protocolInfo, designId: value }));
											}}
										/>
									</Grid.Column>
								</Grid.Row>
								<Grid.Row>
									<Grid.Column width="4" textAlign="right">
										<Form.Field>
											<label htmlFor="form-select-mean-comparison">Mean Comparison *</label>
										</Form.Field>
									</Grid.Column>
									<Grid.Column width="8" textAlign="right">
										<Form.Select
											id="form-select-mean-comparison"
											search
											options={meanComparisonOptions}
											value={protocolInfo?.meanComparisonId}
											error={errors?.meanComparisonId ?? false}
											loading={loading}
											disabled={loading}
											onChange={(event, { value }) => {
												setProtocolInfo((protocolInfo) => ({ ...protocolInfo, meanComparisonId: value }));
											}}
										/>
									</Grid.Column>
								</Grid.Row>
								<Grid.Row>
									<Grid.Column width="4" textAlign="right">
										<Form.Field>
											<label htmlFor="form-select-alpha">Alpha *</label>
										</Form.Field>
									</Grid.Column>
									<Grid.Column width="8" textAlign="right">
										<Form.Select
											id="form-select-alpha"
											search
											options={alphaOptions}
											value={protocolInfo?.alphaId}
											error={errors?.alphaId ?? false}
											loading={loading}
											disabled={loading}
											onChange={(event, { value }) => {
												setProtocolInfo((protocolInfo) => ({ ...protocolInfo, alphaId: value }));
											}}
										/>
									</Grid.Column>
								</Grid.Row>
							</Grid.Column>
							<Grid.Column width="3">
								{" "}
								{/* Middle col */}
								<Grid.Row>
									<Grid.Column>
										<p style={{ fontSize: "1.2em" }}>Yield Required?</p>
										<Form.Field>
											<Radio
												label="Yes"
												name="yield"
												value={true}
												disabled={loading}
												checked={protocolInfo?.yield}
												onChange={(event, { value }) => {
													setProtocolInfo((protocolInfo) => ({ ...protocolInfo, yield: value }));
												}}
											/>
										</Form.Field>
										<Form.Field>
											<Radio
												label="No"
												name="yield"
												value={false}
												disabled={loading}
												checked={protocolInfo?.yield !== null && !protocolInfo?.yield}
												onChange={(event, { value }) => {
													setProtocolInfo((protocolInfo) => ({ ...protocolInfo, yield: value }));
												}}
											/>
										</Form.Field>
									</Grid.Column>
								</Grid.Row>
								<br />
								<Grid.Row>
									<Grid.Column>
										<p style={{ fontSize: "1.2em" }}>Regulatory:</p>
										{_.map(regulatoryOptions, (option) => {
											return (
												<Grid.Row>
													<Grid.Column>
														<Checkbox
															label={option.text}
															style={{ marginTop: "10px" }}
															disabled={loading}
															defaultChecked={_.find(protocolInfo?.regulatory, { id: option.value })?.checked}
															onChange={(event, { checked }) => {
																let matchingIndex = _.findIndex(protocolInfo.regulatory, { id: option.value });
																let updatedRegulatory = _.cloneDeep(protocolInfo.regulatory);
																updatedRegulatory[matchingIndex].checked = checked;

																setProtocolInfo((protocolInfo) => ({ ...protocolInfo, regulatory: updatedRegulatory }));
															}}
														/>
													</Grid.Column>
												</Grid.Row>
											);
										})}
									</Grid.Column>
								</Grid.Row>
								<br />
								<Grid.Row>
									<Grid.Column>
										<p style={{ fontSize: "1.2em" }}>Variables Tested:</p>
										{_.map(variablesTestedOptions, (option) => {
											return (
												<Grid.Row>
													<Grid.Column>
														<Checkbox
															label={option.text}
															style={{ marginTop: "10px" }}
															disabled={loading}
															defaultChecked={_.find(protocolInfo?.variablesTested, { id: option.value })?.checked}
															onChange={(event, { checked }) => {
																let matchingIndex = _.findIndex(protocolInfo.variablesTested, { id: option.value });
																let updatedVariabledTested = _.cloneDeep(protocolInfo.variablesTested);
																updatedVariabledTested[matchingIndex].checked = checked;

																setProtocolInfo((protocolInfo) => ({
																	...protocolInfo,
																	variablesTested: updatedVariabledTested
																}));
															}}
														/>
													</Grid.Column>
												</Grid.Row>
											);
										})}
									</Grid.Column>
								</Grid.Row>
							</Grid.Column>
							<Grid.Column width="7">
								{" "}
								{/* Right col */}
								<Grid.Row>
									<Grid.Column style={{ fontSize: "1.2em" }}>Specific Instructions:</Grid.Column>
								</Grid.Row>
								<br />
								<Grid.Row>
									<Grid.Column width="3" textAlign="right">
										<Form.Field>
											<label htmlFor="form-textbox-rotation">Rotation/Tillage</label>
										</Form.Field>
									</Grid.Column>
									<Grid.Column width="9" textAlign="right">
										<Form.TextArea
											id="form-textbox-rotation"
											defaultValue={protocolInfo?.rotation}
											error={errors?.rotation ?? false}
											disabled={loading}
											style={{ resize: "none" }}
											rows="4"
											onBlur={(event) => {
												let value = event.target.value;
												setProtocolInfo((protocolInfo) => ({ ...protocolInfo, rotation: value }));
												if (value.length > 200) {
													toast.error("Rotation/Tillage text must be below 200 characters.");
													setErrors((errors) => ({ ...errors, rotation: true }));
												} else {
													setErrors((errors) => ({ ...errors, rotation: false }));
												}
											}}
										/>
									</Grid.Column>
								</Grid.Row>
								<Grid.Row>
									<Grid.Column width="3" textAlign="right">
										<Form.Field>
											<label htmlFor="form-textbox-genetics">Genetics</label>
										</Form.Field>
									</Grid.Column>
									<Grid.Column width="9" textAlign="right">
										<Form.TextArea
											id="form-textbox-genetics"
											defaultValue={protocolInfo?.genetics}
											error={errors?.genetics ?? false}
											disabled={loading}
											style={{ resize: "none" }}
											rows="4"
											onBlur={(event) => {
												let value = event.target.value;
												setProtocolInfo((protocolInfo) => ({ ...protocolInfo, genetics: value }));
												if (value.length > 200) {
													toast.error("Genetics text must be below 200 characters.");
													setErrors((errors) => ({ ...errors, genetics: true }));
												} else {
													setErrors((errors) => ({ ...errors, genetics: false }));
												}
											}}
										/>
									</Grid.Column>
								</Grid.Row>
								<Grid.Row>
									<Grid.Column width="3" textAlign="right">
										<Form.Field>
											<label htmlFor="form-textbox-herbicides">Herbicides</label>
										</Form.Field>
									</Grid.Column>
									<Grid.Column width="9" textAlign="right">
										<Form.TextArea
											id="form-textbox-herbicides"
											defaultValue={protocolInfo?.herbicides}
											error={errors?.herbicides ?? false}
											disabled={loading}
											style={{ resize: "none" }}
											rows="4"
											onBlur={(event) => {
												let value = event.target.value;
												setProtocolInfo((protocolInfo) => ({ ...protocolInfo, herbicides: value }));
												if (value.length > 200) {
													toast.error("Herbicides text must be below 200 characters.");
													setErrors((errors) => ({ ...errors, herbicides: true }));
												} else {
													setErrors((errors) => ({ ...errors, herbicides: false }));
												}
											}}
										/>
									</Grid.Column>
								</Grid.Row>
								<Grid.Row>
									<Grid.Column width="3" textAlign="right">
										<Form.Field>
											<label htmlFor="form-textbox-fertilizer">Fertilizer</label>
										</Form.Field>
									</Grid.Column>
									<Grid.Column width="9" textAlign="right">
										<Form.TextArea
											id="form-textbox-fertilizer"
											defaultValue={protocolInfo?.fertilizer}
											error={errors?.fertilizer ?? false}
											disabled={loading}
											style={{ resize: "none" }}
											rows="4"
											onBlur={(event) => {
												let value = event.target.value;
												setProtocolInfo((protocolInfo) => ({ ...protocolInfo, fertilizer: value }));
												if (value.length > 200) {
													toast.error("Fertilizer text must be below 200 characters.");
													setErrors((errors) => ({ ...errors, fertilizer: true }));
												} else {
													setErrors((errors) => ({ ...errors, fertilizer: false }));
												}
											}}
										/>
									</Grid.Column>
								</Grid.Row>
								<Grid.Row>
									<Grid.Column width="3" textAlign="right">
										<Form.Field>
											<label htmlFor="form-textbox-pesticides">Pesticides</label>
										</Form.Field>
									</Grid.Column>
									<Grid.Column width="9" textAlign="right">
										<Form.TextArea
											id="form-textbox-pesticides"
											defaultValue={protocolInfo?.pesticides}
											error={errors?.pesticides ?? false}
											disabled={loading}
											style={{ resize: "none" }}
											rows="4"
											onBlur={(event) => {
												let value = event.target.value;
												setProtocolInfo((protocolInfo) => ({ ...protocolInfo, pesticides: value }));
												if (value.length > 200) {
													toast.error("Pesticides text must be below 200 characters.");
													setErrors((errors) => ({ ...errors, pesticides: true }));
												} else {
													setErrors((errors) => ({ ...errors, pesticides: false }));
												}
											}}
										/>
									</Grid.Column>
								</Grid.Row>
								<Grid.Row>
									<Grid.Column width="3" textAlign="right">
										<Form.Field>
											<label htmlFor="form-textbox-other">Other</label>
										</Form.Field>
									</Grid.Column>
									<Grid.Column width="9" textAlign="right">
										<Form.TextArea
											id="form-textbox-other"
											defaultValue={protocolInfo?.other}
											error={errors?.other ?? false}
											disabled={loading}
											style={{ resize: "none" }}
											rows="4"
											onBlur={(event) => {
												let value = event.target.value;
												setProtocolInfo((protocolInfo) => ({ ...protocolInfo, other: value }));
												if (value.length > 200) {
													toast.error("Other text must be below 200 characters.");
													setErrors((errors) => ({ ...errors, other: true }));
												} else {
													setErrors((errors) => ({ ...errors, other: false }));
												}
											}}
										/>
									</Grid.Column>
								</Grid.Row>
								<br />
								<Grid.Row>
									<Grid.Column>
										<label style={{ opacity: 0.75 }}>* Required</label>
									</Grid.Column>
								</Grid.Row>
							</Grid.Column>
						</Grid.Row>
						<Grid.Row>
							<Grid.Column>
								<Grid.Row>
									<Grid.Column width="3" textAlign="right">
										<Form.Field>
											<label htmlFor="form-textbox-objectives">Objectives</label>
										</Form.Field>
									</Grid.Column>
									<Grid.Column width="9" textAlign="right">
										<Form.TextArea
											id="form-textbox-objectives"
											defaultValue={protocolInfo?.objectives}
											error={errors?.objectives ?? false}
											disabled={loading}
											style={{ resize: "none" }}
											rows="4"
											onBlur={(event) => {
												let value = event.target.value;
												setProtocolInfo((protocolInfo) => ({ ...protocolInfo, objectives: value }));
												if (value.length > 200) {
													toast.error("Objectives text must be below 200 characters.");
													setErrors((errors) => ({ ...errors, objectives: true }));
												} else {
													setErrors((errors) => ({ ...errors, objectives: false }));
												}
											}}
										/>
									</Grid.Column>
								</Grid.Row>
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</Form>
			</Segment>
			<Modal open={openDeleteModal}>
				<Modal.Header>Delete Protocol</Modal.Header>
				<Modal.Content>
					<Modal.Description>
						<Header>Are you sure you want to delete this protocol?</Header>
					</Modal.Description>
				</Modal.Content>
				<Modal.Actions>
					<Button
						type="button"
						negative
						onClick={() => {
							handleDelete();
							setOpenDeleteModal(false);
						}}
						floated="right"
						style={{ marginBottom: 10 }}
					>
						Delete
					</Button>
					<Button type="button" onClick={() => setOpenDeleteModal(false)} floated="right" style={{ marginBottom: 10 }}>
						Cancel
					</Button>
					<br style={{ clear: "both" }} />
				</Modal.Actions>
			</Modal>
		</>
	);
};

ProtocolInfo.propTypes = {
	mode: PropTypes.string.isRequired
};

export default ProtocolInfo;
