import React, { useEffect, useState } from "react";
import { Checkbox, Segment, Table, Grid, Loader, Icon, Popup } from "semantic-ui-react";
import * as tableShader from "../../../Lumber/TableShader";
import * as valuePercisionConverter from "../../../Lumber/ValuePercisionConverter";
import * as protocolCalculations from "./ProtocolCalculations";

import _ from "lodash";

import PropTypes from "prop-types";

const ProtocolDataNormalizationTable = ({
	compiledData,
	tukeyData,
	dataNormalization,
	updateCheckedTreatment,
	treatmentsChecked,
	disableCheckBoxes,
	selectedAssessmentOption,
	initialGroupTrialDataCheckedCount,
	hideLetters,
	setHideLetters,
	needToRerunStats,
	needToRerunNormalization,
	allMeanComparisonData,
	uniqueAnalysesCount,
	setTreatmentsChecked,
	hideUncheckedTrialData = false,
	isForProtocolTimecoursePage,
	oneTrialDatasetError = false,
	multiTrialDatasetError = false
}) => {
	const [tableData, setTableData] = useState(null);
	const [tukeyTableData, setTukeyTableData] = useState(null);
	const [toggleAll, setToggleAll] = useState(true);
	const [tukeyDataTreatmentList, setTukeyDataTreatmentList] = useState([]);

	useEffect(() => {
		if (tukeyData) {
			setTukeyTableData(tukeyData);
			//Get list of treatments checked treatments that have data. Will use this list to set "N/A" to unchecked treatements.
			//This applies when unchecking treatments and getting new data
			let list = _.uniqBy(tukeyData[selectedAssessmentOption], "trialTreatmentId");
			setTukeyDataTreatmentList(
				_.map(list, (treatment) => {
					return treatment.trialTreatmentId;
				})
			);
		}
	}, [tukeyData]);

	useEffect(() => {
		if (compiledData) {
			let checkedTreatmentCountUpdated = _.map(compiledData, (data) => {
				return data.treatmentChecked;
			});

			//Logic to hide the auc letters when treatments are unchecked
			if (
				!_.isEqual(initialGroupTrialDataCheckedCount, checkedTreatmentCountUpdated) &&
				initialGroupTrialDataCheckedCount !== undefined &&
				initialGroupTrialDataCheckedCount?.length !== 0
			) {
				setHideLetters(true);
			} else if (initialGroupTrialDataCheckedCount?.length === 0) {
				if (_.some(compiledData, (data) => !data?.treatmentChecked)) {
					setHideLetters(true);
				} else {
					setHideLetters(false);
				}
			} else {
				setHideLetters(false);
			}

			//Filter unchecked treatements and don't use them to re-color. This applies when unchecking treatments and getting new data
			let tempCompiledData = _.filter(compiledData, (cd) => !cd.hasMissingTreatments);
			let filteredCompiledData = _.filter(tempCompiledData, (cd) => {
				return tukeyDataTreatmentList.includes(cd.trialTreatmentId);
			});
			filteredCompiledData = _.isEmpty(filteredCompiledData) ? tempCompiledData : filteredCompiledData;

			filteredCompiledData[0]?.reversed === true || dataNormalization === "RANK ORDER"
				? tableShader.calculateShadingForTreatmentReversed(filteredCompiledData, "average", "90, 138, 198")
				: tableShader.calculateShadingForTreatment(filteredCompiledData, "average", "90, 138, 198");
			setTableData(compiledData);
		}
	}, [compiledData]);

	async function updateCheckAll() {
		const toggleValue = toggleAll;
		setToggleAll(!toggleValue);
		return;
	}

	function toggleCheckAll() {
		let tempTableData = _.cloneDeep(treatmentsChecked);
		tempTableData.map((treatment) => {
			treatment.treatmentChecked = !toggleAll;
			treatment.dirty = true;
			updateCheckedTreatment(treatment.treatmentChecked, treatment.trialTreatmentId, true);
		});
		setTreatmentsChecked(tempTableData);
		//For now we dont want to hide the letters when all treatments are unchecked for the Protocol Box Whisker page
		if (isForProtocolTimecoursePage) {
			setHideLetters(true);
		}
	}

	return (
		<Segment basic style={{ overflowX: "auto" }}>
			<Table celled striped selectable color="blue" textAlign="center" collapsing>
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell style={{ zIndex: "0" }}>
							<Checkbox
								label="Check All"
								checked={toggleAll}
								disabled={
									disableCheckBoxes ||
									(allMeanComparisonData?.length !== uniqueAnalysesCount?.length &&
										!multiTrialDatasetError &&
										!oneTrialDatasetError)
								}
								onClick={() => {
									if (
										(!disableCheckBoxes && allMeanComparisonData?.length === uniqueAnalysesCount?.length) ||
										multiTrialDatasetError ||
										oneTrialDatasetError
									) {
										updateCheckAll().then(toggleCheckAll());
									}
								}}
							/>
						</Table.HeaderCell>
						<Table.HeaderCell style={{ zIndex: "0" }}>#</Table.HeaderCell>
						<Table.HeaderCell style={{ zIndex: "0" }}>Type</Table.HeaderCell>
						<Table.HeaderCell style={{ zIndex: "0" }}>Name</Table.HeaderCell>
						<Table.HeaderCell style={{ zIndex: "0" }}>{dataNormalization}</Table.HeaderCell>
					</Table.Row>
				</Table.Header>
				<Table.Body>
					{_.map(tableData, (treatment) => {
						return (
							<Table.Row key={treatment.trialTreatmentId}>
								<Table.Cell>
									<Checkbox
										checked={
											_.find(treatmentsChecked, (tc) => tc.trialTreatmentId === treatment.trialTreatmentId)
												?.treatmentChecked
										}
										disabled={
											disableCheckBoxes ||
											(allMeanComparisonData?.length !== uniqueAnalysesCount?.length &&
												!multiTrialDatasetError &&
												!oneTrialDatasetError)
										}
										onChange={() => {
											updateCheckedTreatment(
												!_.find(treatmentsChecked, (tc) => tc.trialTreatmentId === treatment.trialTreatmentId)
													?.treatmentChecked,
												treatment.trialTreatmentId
											);
										}}
									/>
								</Table.Cell>
								<Table.Cell>{treatment.trialTreatmentId}</Table.Cell>
								<Table.Cell>{treatment.treatmentTypeName}</Table.Cell>
								<Table.Cell style={{ width: "450px" }} textAlign="left">
									{treatment.treatmentName}
									{treatment.hasMissingTreatments ? (
										<span style={{ float: "right" }}>
											<Popup
												trigger={<Icon name="info circle" />}
												content="This treatment is missing from some of the trials on this protocol."
											/>
										</span>
									) : null}
								</Table.Cell>
								<Table.Cell
									style={{
										backgroundColor: needToRerunNormalization
											? ""
											: treatment.treatmentChecked
											? treatment.color
											: "lightGray",
										width: "150px",
										position: "sticky"
									}}
								>
									<Grid style={{ width: "inherit", height: "inherit" }}>
										<Grid.Row style={{ padding: "inherit" }}>
											<Grid.Column width="1" />
											<Grid.Column width="9" style={{ padding: 0 }}>
												{needToRerunNormalization
													? ""
													: treatment.hasMissingTreatments
													? "N/A"
													: treatment.average && treatment.treatmentChecked
													? valuePercisionConverter.getPercision(treatment.average) +
													  "" +
													  (dataNormalization.includes("%") ? " %" : "")
													: !treatment.treatmentChecked
													? ""
													: "N/A"}
											</Grid.Column>
											<Grid.Column width="4" style={{ padding: 0 }}>
												{disableCheckBoxes ? (
													<Loader active size="tiny" />
												) : !treatment.treatmentChecked ||
												  hideLetters ||
												  needToRerunStats ||
												  needToRerunNormalization ||
												  hideUncheckedTrialData ? (
													""
												) : (
													_.find(
														tukeyTableData[selectedAssessmentOption],
														(td) => td.trialTreatmentId === treatment.trialTreatmentId
													)?.tukeyLetters
												)}
											</Grid.Column>
										</Grid.Row>
									</Grid>
								</Table.Cell>
							</Table.Row>
						);
					})}
				</Table.Body>
				<Table.Body color="blue">
					<Table.Row>
						<Table.Cell></Table.Cell>
						<Table.Cell></Table.Cell>
						<Table.Cell></Table.Cell>
						<Table.Cell textAlign="right">Global Mean</Table.Cell>
						<Table.Cell>
							{(_.some(tableData, (td) => !td.treatmentChecked) && hideLetters) ||
							hideLetters ||
							needToRerunNormalization
								? ""
								: valuePercisionConverter.getPercision(
										_.meanBy(
											_.map(
												_.filter(tableData, (td) => td.treatmentChecked),
												"average"
											)
										)
								  )
								? valuePercisionConverter.getPercision(
										_.meanBy(
											_.map(
												_.filter(tableData, (td) => td.treatmentChecked),
												"average"
											)
										)
								  ) +
								  "" +
								  (dataNormalization.includes("%") ? " %" : "")
								: "N/A"}
						</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.Cell></Table.Cell>
						<Table.Cell></Table.Cell>
						<Table.Cell></Table.Cell>
						<Table.Cell textAlign="right">CV</Table.Cell>
						<Table.Cell>
							{(_.some(tableData, (td) => !td?.treatmentChecked) && hideLetters) ||
							hideLetters ||
							needToRerunNormalization ||
							disableCheckBoxes ||
							hideUncheckedTrialData
								? ""
								: multiTrialDatasetError || oneTrialDatasetError
								? "N/A"
								: protocolCalculations.calculateStandardDeviationForCompiledTable(
										_.filter(tableData, (td) => td?.treatmentChecked)
								  )}
						</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.Cell></Table.Cell>
						<Table.Cell></Table.Cell>
						<Table.Cell></Table.Cell>
						<Table.Cell textAlign="right">P-Value</Table.Cell>
						{(_.some(tableData, (td) => !td.treatmentChecked) && hideLetters) ||
						hideLetters ||
						needToRerunStats ||
						needToRerunNormalization ||
						disableCheckBoxes ||
						hideUncheckedTrialData ? (
							<Table.Cell></Table.Cell>
						) : tukeyTableData && tukeyTableData[selectedAssessmentOption]?.length > 0 ? (
							<Table.Cell>
								{Number(Number(tukeyTableData[selectedAssessmentOption][0]?.pValue).toFixed(5)) === 0
									? "<0.00001"
									: Number(Number(tukeyTableData[selectedAssessmentOption][0]?.pValue).toFixed(5))}
							</Table.Cell>
						) : (
							<Table.Cell>N/A</Table.Cell>
						)}
					</Table.Row>
				</Table.Body>
			</Table>
		</Segment>
	);
};

ProtocolDataNormalizationTable.propTypes = {
	compiledData: PropTypes.array,
	tukeyData: PropTypes.array,
	dataNormalization: PropTypes.string,
	updateCheckedTreatment: PropTypes.func,
	treatmentsChecked: PropTypes.array,
	disableCheckBoxes: PropTypes.bool,
	selectedAssessmentOption: PropTypes.string,
	siteSpecificData: PropTypes.array,
	updateTrialsChecked: PropTypes.func,
	trialsChecked: PropTypes.array,
	tukeySiteData: PropTypes.array,
	initialGroupTrialDataCheckedCount: PropTypes.array,
	hideLetters: PropTypes.bool,
	setHideLetters: PropTypes.func,
	needToRerunStats: PropTypes.bool,
	needToRerunNormalization: PropTypes.bool,
	allMeanComparisonData: PropTypes.array,
	uniqueAnalysesCount: PropTypes.array,
	setTreatmentsChecked: PropTypes.func,
	hideUncheckedTrialData: PropTypes.bool,
	isForProtocolTimecoursePage: PropTypes.bool,
	oneTrialDatasetError: PropTypes.bool,
	multiTrialDatasetError: PropTypes.bool
};

export default ProtocolDataNormalizationTable;
