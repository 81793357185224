import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { Loader, Grid, Message, Button } from "semantic-ui-react";
import { toast } from "react-toastify";

import _ from "lodash";
import moment from "moment";

import { useUserAuth } from "../../../../hooks/useUserAuth";
import { useAuth0 } from "../../../../auth/auth0";
import { useModuleNavigation } from "../../../../hooks/useModuleNavigation";
import CanvasJSReact from "../../../ThirdParty/CanvasJS/canvasjs.react";
import * as weatherDataActions from "../../../../redux/actions/weatherDataActions";
import * as excelGenerationFunctions from "../../../../utilities/excelGeneration";

import styles from "../../../Lumber/Timecourse";

import PropTypes from "prop-types";

const TrialWeatherGraphs = (trialName) => {
	const userAuth = useUserAuth();
	const { getTokenSilently } = useAuth0();
	const dispatch = useDispatch();
	const moduleNavigation = useModuleNavigation();

	const CanvasJSChart = CanvasJSReact.CanvasJSChart;

	//const clientId = useSelector((state) => (state.clients ? state.clients.currentId : null));
	//-- Data Sources
	const [historicalWeatherData, setHistoricalWeatherData] = useState([]);
	const [dailyTempChartOptions, setDailyTempChartOptions] = useState({});
	const [dailyRainfallChartOptions, setDailyRainfallChartOptions] = useState({});
	const [dailyGDUChartOptions, setDailyGDUChartOptions] = useState({});
	const [cumulativeRainfallChartOptions, setCumulativeRainfallChartOptions] = useState({});
	const [currentGDUByDay, setCurrentGDUByDay] = useState(null);
	const [gduByDaySincePlanting, setGDUByDaySincePlanting] = useState(null);
	const [weatherDataSincePlanting, setWeatherDataSincePlanting] = useState(null);
	const [gduByDayHistorical, setGDUByDayHistorical] = useState(null);
	const [trialDailyWeather, setTrialDailyWeather] = useState(null);
	//Used to reduce toast message warnings for when the plant date is greater than the harvest date
	const [isPlantDateValid, setIsPlantDateValid] = useState(true);
	const [isCropValid, setIsCropValid] = useState(true);

	//-- UI Control
	const [loading, setLoading] = useState(true);
	const [dailyChartLoading, setDailyChartLoading] = useState(true);
	const [gduChartLoading, setGDUChartLoading] = useState(true);

	//-- Excel
	const [excelDownloadLoading, setExcelDownloadLoading] = useState(false);

	//Used for historical weather data toast message when there is no data
	const [isCreateDone, setIsCreateDone] = useState(false);

	//Create Historical Data
	useEffect(() => {
		if (userAuth.isReady) {
			createHistoricalWeatherData();
		}
	}, [userAuth.isReady]);

	useEffect(() => {
		if (
			moduleNavigation.trialId &&
			(!historicalWeatherData || historicalWeatherData?.length === 0) &&
			userAuth.isReady &&
			isCreateDone
		) {
			getHistoricalWeatherData(moduleNavigation.trialId);
		}
	}, [moduleNavigation.trialId, userAuth.isReady, historicalWeatherData, isCreateDone]);

	useEffect(() => {
		if (
			moduleNavigation.trialId &&
			(!currentGDUByDay || currentGDUByDay?.length === 0) &&
			userAuth.isReady &&
			isPlantDateValid &&
			isCropValid
		) {
			getCurrentGDUByDay(moduleNavigation.trialId);
		}
	}, [moduleNavigation.trialId, userAuth.isReady, currentGDUByDay, isPlantDateValid, isCropValid]);

	useEffect(() => {
		if (
			moduleNavigation.trialId &&
			(!gduByDayHistorical || gduByDayHistorical?.length === 0) &&
			userAuth.isReady &&
			isCropValid
		) {
			getGDUByDayHistorical(moduleNavigation.trialId);
		}
	}, [moduleNavigation.trialId, userAuth.isReady, gduByDayHistorical, isCropValid]);

	useEffect(() => {
		if (currentGDUByDay && (currentGDUByDay?.length > 0 || !isCropValid) && dailyChartLoading) {
			getTrialDailyWeather(moduleNavigation.trialId);
		}
	}, [currentGDUByDay]);

	useEffect(() => {
		if (historicalWeatherData?.length > 0 && trialDailyWeather?.length > 0) {
			setDailyChartLoading(true);
			createChartForDays();
			createChartForRainfall();
			createChartForGDUDaysRainfall();
			setDailyChartLoading(false);
		}
	}, [historicalWeatherData, trialDailyWeather]);

	useEffect(() => {
		if (currentGDUByDay?.length > 0 && gduByDayHistorical?.length > 0) {
			setGDUChartLoading(true);
			createChartForGDUDays();
			setGDUChartLoading(false);
		}
	}, [currentGDUByDay, gduByDayHistorical, gduByDaySincePlanting, historicalWeatherData, trialDailyWeather]);

	async function createHistoricalWeatherData() {
		const accessToken = await getTokenSilently();
		setLoading(true);
		dispatch(
			weatherDataActions.createHistoricalWeatherData(moduleNavigation.trialId, userAuth.currentClientId, accessToken)
		)
			.then((res) => {
				if (res.statusCode === 200) {
					setIsCreateDone(true);
				}
			})
			.catch((error) => {
				toast.error("An Error has occured while creating historical weather data");
				console.log(error);
			});
	}

	async function getHistoricalWeatherData(trialId) {
		const accessToken = await getTokenSilently();

		dispatch(weatherDataActions.getHistoricalWeatherData(trialId, userAuth.currentClientId, accessToken))
			.then((res) => {
				if (res.data.length !== 0) {
					setHistoricalWeatherData(res.data);
				} else {
					toast.warn("Could not find historical weather data");
				}
			})
			.catch((err) => {
				console.log(err);
			});
	}

	async function getCurrentGDUByDay(trialId) {
		const accessToken = await getTokenSilently();

		await dispatch(weatherDataActions.getCurrentGDUForTrial(trialId, userAuth.currentClientId, accessToken))
			.then((res) => {
				setCurrentGDUByDay(res.data.item1);
				setGDUByDaySincePlanting(res.data.item2);
				setIsPlantDateValid(res.data.item3);
				setIsCropValid(res.data.item5);
				setWeatherDataSincePlanting(res.data.item6);
				if (!res.data.item3 && !currentGDUByDay) {
					toast.error("Plant date year can not be greater than the harvest date year");
					setLoading(false);
					setDailyChartLoading(false);
					setGDUChartLoading(false);
				}
				if (!res.data.item4) {
					toast.warn("No plant date is set");
				}
				if (res.data.item3 && res.data.item2.length === 0 && !currentGDUByDay && res.data.item4 && res.data.item5) {
					toast.warn("Could not find since planting weather data");
				}
			})
			.catch((err) => {
				console.log(err);
				setLoading(false);
				setDailyChartLoading(false);
				setGDUChartLoading(false);
			});
	}

	async function getGDUByDayHistorical(trialId) {
		const accessToken = await getTokenSilently();

		dispatch(weatherDataActions.getGDUForTrialHistorical(trialId, userAuth.currentClientId, accessToken))
			.then((res) => {
				setGDUByDayHistorical(res.data.item1);
				setIsCropValid(res.data.item2);
			})
			.catch((err) => {
				console.log(err);
			});
	}

	async function getTrialDailyWeather(trialId) {
		const accessToken = await getTokenSilently();

		dispatch(weatherDataActions.getTrialDailyWeather(trialId, userAuth.currentClientId, accessToken))
			.then((res) => {
				setTrialDailyWeather(res.data);
				setLoading(false);
				if (res.data === 0) {
					toast.warn("Could not find current daily weather data");
				}
			})
			.catch((err) => {
				console.log(err);
			});
	}

	function toggleDataSeries(e) {
		if (e) {
			if (typeof e.dataSeries.visible === "undefined" || e.dataSeries.visible) {
				e.dataSeries.visible = false;
			} else {
				e.dataSeries.visible = true;
			}
			e.chart.render();
		}
	}

	async function generateWeatherDataExcel() {
		const accessToken = await getTokenSilently();
		setExcelDownloadLoading(true);
		let currentDateTimeString = JSON.stringify(new Date().toLocaleString());
		dispatch(
			weatherDataActions.generateWeatherDataExcel(
				moduleNavigation.trialId,
				currentDateTimeString,
				userAuth.currentClientId,
				accessToken
			)
		)
			.then((res) => {
				let excelFileName = `${trialName.trialName}_TrialWeatherData_${moment().format("YYYYMMDD_hhmma")}.xlsx`;
				excelGenerationFunctions.downloadExcelFile(res.data, excelFileName);
				//toast.info("Download Complete");
				setExcelDownloadLoading(false);
			})
			.catch((err) => {
				setExcelDownloadLoading(false);
				console.log(err);
				toast.error("Error downloading excel file. Please try again.");
			});
	}

	//Daily Temperature Chart
	function createChartForDays() {
		let dataObject = [];
		let historicalDailyAvgTempPoints = [];
		let historicalDailyHighPoints = [];
		let historicalDailyLowPoints = [];
		let dailyAvgTempPoints = [];
		let dailyHighPoints = [];
		let dailyLowPoints = [];
		_.map(historicalWeatherData, (weatherData) => {
			//Set Historical weather
			const historicalDailyAvgTemp = {
				y: weatherData.temperature,
				label: weatherData.day
			};
			const historicalDailyHigh = {
				y: weatherData.dailyHigh,
				label: weatherData.day
			};
			const historicalDailyLow = {
				y: weatherData.dailyLow,
				label: weatherData.day
			};

			//Set Daily Current Weather
			let tdw = trialDailyWeather?.find((el) => el.day === weatherData.day);

			//If trial weather data exists for the same day as the historical data
			if (tdw) {
				const dailyAvgTemp = {
					y: tdw.temperature,
					label: tdw.day
				};
				const dailyHigh = {
					y: tdw.dailyHigh,
					label: tdw.day
				};
				const dailyLow = {
					y: tdw.dailyLow,
					label: tdw.day
				};
				dailyAvgTempPoints.push(dailyAvgTemp);
				dailyHighPoints.push(dailyHigh);
				dailyLowPoints.push(dailyLow);
			}
			//Otherwise set the daily temperature value to empty
			else {
				const dailyAvgTemp = {
					y: null,
					label: weatherData.day
				};
				const dailyHigh = {
					y: null,
					label: weatherData.day
				};
				const dailyLow = {
					y: null,
					label: weatherData.day
				};
				dailyAvgTempPoints.push(dailyAvgTemp);
				dailyHighPoints.push(dailyHigh);
				dailyLowPoints.push(dailyLow);
			}

			historicalDailyAvgTempPoints.push(historicalDailyAvgTemp);
			historicalDailyHighPoints.push(historicalDailyHigh);
			historicalDailyLowPoints.push(historicalDailyLow);
		});

		//Set chart options for each data set
		let historicalDailyAvgTemp = {
			indexLabelFontColor: "darkSlateGray",
			name: "30-yr Daily Avg",
			type: "spline",
			fillOpacity: 0.7,
			showInLegend: true,
			lineDashType: "shortDot",
			color: "green",
			dataPoints: historicalDailyAvgTempPoints
		};
		let historicalDailyHigh = {
			indexLabelFontColor: "darkSlateGray",
			name: "30-yr Daily High",
			type: "spline",
			fillOpacity: 0.7,
			showInLegend: true,
			lineDashType: "shortDot",
			color: "red",
			dataPoints: historicalDailyHighPoints
		};
		let historicalDailyLow = {
			indexLabelFontColor: "darkSlateGray",
			name: "30-yr Daily Low",
			type: "spline",
			fillOpacity: 0.7,
			showInLegend: true,
			lineDashType: "shortDot",
			color: "blue",
			dataPoints: historicalDailyLowPoints
		};
		let dailyAvgTemp = {
			indexLabelFontColor: "darkSlateGray",
			name: "Daily Avg",
			type: "line",
			showInLegend: true,
			color: "lightGreen",
			dataPoints: dailyAvgTempPoints
		};
		let dailyHigh = {
			indexLabelFontColor: "darkSlateGray",
			name: "Daily High",
			type: "line",
			showInLegend: true,
			color: "#FF7F7F",
			dataPoints: dailyHighPoints
		};
		let dailyLow = {
			indexLabelFontColor: "darkSlateGray",
			name: "Daily Low",
			type: "line",
			showInLegend: true,
			color: "lightBlue",
			dataPoints: dailyLowPoints
		};
		dataObject.push(historicalDailyAvgTemp);
		dataObject.push(historicalDailyHigh);
		dataObject.push(historicalDailyLow);
		dataObject.push(dailyAvgTemp);
		dataObject.push(dailyHigh);
		dataObject.push(dailyLow);

		//Set main chart options
		let opt = {
			theme: "light2",
			animationEnabled: true,
			title: {
				text: "Temperature"
			},
			axisY: {
				title: "Daily Temps °F"
			},
			toolTip: {
				shared: "true"
			},
			legend: {
				cursor: "pointer",
				itemclick: toggleDataSeries
			},
			addColorSet: ["#2F4F4F", "#008080", "#2E8B57", "#3CB371", "#90EE90"],
			data: dataObject
		};
		setDailyTempChartOptions(opt);
	}

	function createChartForRainfall() {
		let dataObject = [];
		let dailyRainfallPoints = [];
		_.map(trialDailyWeather, (weatherData) => {
			//Set historical daily rainfall data
			const dailyRainfall = {
				y: weatherData.precipitation,
				label: weatherData.day
			};
			dailyRainfallPoints.push(dailyRainfall);
		});

		//Set chart options for daily historical rainfall data
		let dailyRainfall = {
			indexLabelFontColor: "darkSlateGray",
			name: "Daily Rainfall",
			type: "line",
			showInLegend: true,
			dataPoints: dailyRainfallPoints
		};
		dataObject.push(dailyRainfall);

		//Set main chart options
		let opt = {
			theme: "light2",
			animationEnabled: true,
			title: {
				text: "Rainfall"
			},
			axisY: {
				title: "Daily Rainfall (inches)"
			},
			toolTip: {
				shared: "true"
			},
			legend: {
				cursor: "pointer"
			},
			data: dataObject
		};
		setDailyRainfallChartOptions(opt);
	}

	function createChartForGDUDays() {
		let dataObject = [];
		let historicalGDUPoints = [];
		let currentGDUPoints = [];
		let gduSincePlantingPoints = [];
		let gduSincePlantingHistoricalPoints = [];
		let previousHistoricalGDU = 0.0;
		let previousGDU = 0.0;
		let previousGDUSincePlanting = 0.0;
		let previousGDUSincePlantingHistorical = 0.0;
		let lastDaySincePlanting = gduByDaySincePlanting?.find((el, index) => index === gduByDaySincePlanting.length - 1);
		let firstDaySincePlanting = gduByDaySincePlanting?.find((el, index) => index === 0);
		let lastDayReached = false;
		_.map(gduByDayHistorical, (gduHistorical, index) => {
			//Set historical gdu weather data
			const historicalGDU = {
				y: gduHistorical.gdu + previousHistoricalGDU,
				label: gduHistorical.monthDay
			};

			//Set current year daily gdu data
			let dailyGDU = currentGDUByDay?.find((el) => el.monthDay === gduHistorical.monthDay);

			//If daily gdu data exists for the same day as the historical data
			if (dailyGDU) {
				const currentGDU = {
					y: dailyGDU.gdu + previousGDU,
					label: dailyGDU.monthDay
				};

				currentGDUPoints.push(currentGDU);
				previousGDU = dailyGDU.gdu + previousGDU;
			}
			//Otherwise set the daily gdu value to empty
			else {
				const currentGDU = {
					y: null,
					label: gduHistorical.monthDay
				};
				currentGDUPoints.push(currentGDU);
			}

			//Set current year gdu data since planting
			let gduSincePlanting =
				gduHistorical.monthDay === firstDaySincePlanting?.monthDay
					? null
					: gduByDaySincePlanting?.find((el) => el.monthDay === gduHistorical.monthDay);

			//This is used to handle current year situations where we don't have since planting data for the entire year
			if (!lastDayReached) {
				lastDayReached = gduHistorical.monthDay === lastDaySincePlanting?.monthDay ? true : false;
			}

			//If current year gdu data since planting exists for the same day as the historical data
			if (gduSincePlanting) {
				//Use previous day's since planting gdus to account of planting date (should be null on plant date)
				let previousGDUFromIndex = gduByDayHistorical?.find(
					(el, indexHist) => indexHist === (index === 0 ? 0 : index - 1)
				);
				let previousGDUSincePlantingFromIndex = gduByDaySincePlanting?.find(
					(el) => el.monthDay == previousGDUFromIndex.monthDay
				);
				let previousGDUSincePlantingHistoricalFromIndex = gduByDayHistorical?.find(
					(el) => el.monthDay == previousGDUFromIndex.monthDay
				);
				//Current year gdu data since planting
				const currentGDU = {
					y: previousGDUSincePlantingFromIndex.gdu + previousGDUSincePlanting,
					label: gduSincePlanting.monthDay
				};
				//Historical gdu data since planting
				const currentGDUHistorical = {
					y: previousGDUSincePlantingHistoricalFromIndex.gdu + previousGDUSincePlantingHistorical,
					label: gduHistorical.monthDay
				};

				gduSincePlantingPoints.push(currentGDU);
				gduSincePlantingHistoricalPoints.push(currentGDUHistorical);
				previousGDUSincePlanting = previousGDUSincePlantingFromIndex.gdu + previousGDUSincePlanting;
				previousGDUSincePlantingHistorical =
					previousGDUSincePlantingHistoricalFromIndex.gdu + previousGDUSincePlantingHistorical;
			}
			//Otherwise set the current gdu value to empty
			else {
				const currentGDU = {
					y: null,
					label: gduHistorical.monthDay
				};
				//Normal operation if we don't have since planting data
				if (!lastDayReached) {
					const currentGDUHistorical = {
						y: null,
						label: gduHistorical.monthDay
					};
					gduSincePlantingHistoricalPoints.push(currentGDUHistorical);
				}
				//Set since planting GDU data from historical weather data
				else {
					const currentGDUHistorical = {
						y: gduHistorical.gdu + previousGDUSincePlantingHistorical,
						label: gduHistorical.monthDay
					};
					gduSincePlantingHistoricalPoints.push(currentGDUHistorical);
					previousGDUSincePlantingHistorical = gduHistorical.gdu + previousGDUSincePlantingHistorical;
				}

				gduSincePlantingPoints.push(currentGDU);
			}

			historicalGDUPoints.push(historicalGDU);
			previousHistoricalGDU = gduHistorical.gdu + previousHistoricalGDU;
		});

		//Set chart options for gdu data
		let historicalGDU = {
			indexLabelFontColor: "darkSlateGray",
			name: "30-yr Normal",
			type: "line",
			showInLegend: true,
			dataPoints: historicalGDUPoints
		};
		let currentGDU = {
			indexLabelFontColor: "darkSlateGray",
			name: "Current Year",
			type: "line",
			showInLegend: true,
			dataPoints: currentGDUPoints
		};

		let gduSincePlantingHistorical = {
			indexLabelFontColor: "darkSlateGray",
			name: "30-yr Normal Since Planting",
			type: "line",
			showInLegend: true,
			dataPoints: gduSincePlantingHistoricalPoints
		};

		let gduSincePlanting = {
			indexLabelFontColor: "darkSlateGray",
			name: "Current Year Since Planting",
			type: "line",
			showInLegend: true,
			dataPoints: gduSincePlantingPoints
		};

		dataObject.push(historicalGDU);
		dataObject.push(currentGDU);
		dataObject.push(gduSincePlantingHistorical);
		dataObject.push(gduSincePlanting);

		//Set main chart options
		let opt = {
			theme: "light2",
			animationEnabled: true,
			title: {
				text: ""
			},
			axisY: {
				title: "Cumulative GDUs"
			},
			toolTip: {
				shared: "true"
			},
			legend: {
				cursor: "pointer",
				itemclick: toggleDataSeries
			},
			data: dataObject
		};
		setDailyGDUChartOptions(opt);
	}

	function createChartForGDUDaysRainfall() {
		let dataObject = [];
		let historicalRainfallPoints = [];
		let currentRainfallPoints = [];
		let rainfallSincePlantingPoints = [];
		let rainfallSincePlantingHistoricalPoints = [];
		let previousHistoricalRainfall = 0.0;
		let previousRainfall = 0.0;
		let previousRainfallSincePlanting = 0;
		let previousRainfallSincePlantingHistorical = 0.0;
		let lastDaySincePlanting = weatherDataSincePlanting?.find(
			(el, index) => index === weatherDataSincePlanting.length - 1
		);
		let firstDaySincePlanting = weatherDataSincePlanting?.find((el, index) => index === 0);
		let lastDayReached = false;
		_.map(historicalWeatherData, (rainfallHistorical, index) => {
			//Set historical gdu data for rainfall
			const historicalRainfall = {
				y: rainfallHistorical.precipitation + previousHistoricalRainfall,
				label: rainfallHistorical.monthDay
			};

			//Set current year daily gdu data
			let dailyRainfall = trialDailyWeather?.find((el) => el.day === rainfallHistorical.day);
			if (dailyRainfall) {
				//If daily gdu data exists for the same day as the historical data
				const currentRainfall = {
					y: dailyRainfall.precipitation + previousRainfall,
					label: dailyRainfall.day
				};

				currentRainfallPoints.push(currentRainfall);
				previousRainfall = dailyRainfall.precipitation + previousRainfall;
			}
			//Otherwise set the daily gdu value to empty
			else {
				const currentRainfall = {
					y: null,
					label: rainfallHistorical.day
				};
				currentRainfallPoints.push(currentRainfall);
			}

			//Set current year gdu data for rainfall since planting
			let rainfallSincePlanting =
				rainfallHistorical.day === firstDaySincePlanting?.day
					? null
					: weatherDataSincePlanting?.find((el) => el.day === rainfallHistorical.day);

			let dailyWeatherRainfall = trialDailyWeather?.find((el) => el.day === rainfallSincePlanting?.day);

			//This is used to handle current year situations where we don't have since planting data for the entire year
			if (!lastDayReached) {
				lastDayReached = rainfallHistorical.day === lastDaySincePlanting?.monthDay ? true : false;
			}

			if (dailyWeatherRainfall) {
				//Use previous day's since planting gdus to account of planting date (should be null on plant date)
				let previousGDUFromIndex = historicalWeatherData?.find(
					(el, indexHist) => indexHist === (index === 0 ? 0 : index - 1)
				);
				let previousGDUSincePlantingFromIndex = weatherDataSincePlanting?.find(
					(el) => el.day == previousGDUFromIndex.day
				);
				let previousGDUSincePlantingHistoricalFromIndex = historicalWeatherData?.find(
					(el) => el.day == previousGDUFromIndex.day
				);

				const currentRainfall = {
					y: previousGDUSincePlantingFromIndex.precipitation + previousRainfallSincePlanting,
					label: dailyWeatherRainfall.day
				};

				const currentRainfallHistorical = {
					y: previousGDUSincePlantingHistoricalFromIndex.precipitation + previousRainfallSincePlantingHistorical,
					label: rainfallHistorical.day
				};

				rainfallSincePlantingPoints.push(currentRainfall);
				rainfallSincePlantingHistoricalPoints.push(currentRainfallHistorical);
				previousRainfallSincePlanting = previousGDUSincePlantingFromIndex.precipitation + previousRainfallSincePlanting;
				previousRainfallSincePlantingHistorical =
					previousGDUSincePlantingHistoricalFromIndex.precipitation + previousRainfallSincePlantingHistorical;
			} else {
				const currentRainfall = {
					y: null,
					label: rainfallHistorical.day
				};
				//Normal operation if we don't have since planting data
				if (!lastDayReached) {
					const currentRainfallHistorical = {
						y: null,
						label: rainfallHistorical.day
					};
					rainfallSincePlantingHistoricalPoints.push(currentRainfallHistorical);
				}
				//Set since planting GDU data from historical weather data
				else {
					const currentRainfallHistorical = {
						y: rainfallHistorical.precipitation + previousRainfallSincePlantingHistorical,
						label: rainfallHistorical.day
					};
					rainfallSincePlantingHistoricalPoints.push(currentRainfallHistorical);
					previousRainfallSincePlantingHistorical =
						rainfallHistorical.precipitation + previousRainfallSincePlantingHistorical;
				}

				rainfallSincePlantingPoints.push(currentRainfall);
			}

			historicalRainfallPoints.push(historicalRainfall);
			previousHistoricalRainfall = rainfallHistorical.precipitation + previousHistoricalRainfall;
		});

		let historicalRainfall = {
			indexLabelFontColor: "darkSlateGray",
			name: "30-yr Normal",
			type: "line",
			showInLegend: true,
			dataPoints: historicalRainfallPoints
		};
		let currentRainfall = {
			indexLabelFontColor: "darkSlateGray",
			name: "Current Year",
			type: "line",
			showInLegend: true,
			dataPoints: currentRainfallPoints
		};

		let rainfallSincePlantingHistorical = {
			indexLabelFontColor: "darkSlateGray",
			name: "30-yr Normal Since Planting",
			type: "line",
			showInLegend: true,
			dataPoints: rainfallSincePlantingHistoricalPoints
		};

		let rainfallSincePlanting = {
			indexLabelFontColor: "darkSlateGray",
			name: "Current Year Since Planting",
			type: "line",
			showInLegend: true,
			dataPoints: rainfallSincePlantingPoints
		};

		dataObject.push(historicalRainfall);
		dataObject.push(currentRainfall);
		dataObject.push(rainfallSincePlantingHistorical);
		dataObject.push(rainfallSincePlanting);

		let opt = {
			theme: "light2",
			animationEnabled: true,
			title: {
				text: ""
			},
			axisY: {
				title: "Cumulative Rainfall (inches)"
			},
			toolTip: {
				shared: "true"
			},
			legend: {
				cursor: "pointer",
				itemclick: toggleDataSeries
			},
			data: dataObject
		};
		setCumulativeRainfallChartOptions(opt);
	}

	return loading || (dailyChartLoading && gduChartLoading) ? (
		<Loader active className={styles.workaround} inline="centered" />
	) : (
		<Grid columns={2} divided>
			<Grid.Row>
				<Grid.Column floated="right">
					<Button
						disabled={loading || dailyChartLoading}
						primary={!loading && !dailyChartLoading}
						loading={excelDownloadLoading}
						floated="right"
						onClick={() => {
							generateWeatherDataExcel();
						}}
					>
						Weather Data Download
					</Button>
				</Grid.Column>
			</Grid.Row>

			{!isCropValid ? (
				<Message
					warning
					content={"The crop for this trial is not set up to calculate GDUs"}
					style={{ marginTop: "unset", width: "100%", textAlign: "center" }}
				/>
			) : null}
			<Grid.Row stretched>
				<Grid.Column>
					<CanvasJSChart options={dailyTempChartOptions} />
				</Grid.Column>
				<Grid.Column>
					<CanvasJSChart options={dailyRainfallChartOptions} />
				</Grid.Column>
				<Grid.Column>
					<CanvasJSChart options={dailyGDUChartOptions} />
				</Grid.Column>
				<Grid.Column>
					<CanvasJSChart options={cumulativeRainfallChartOptions} />
				</Grid.Column>
			</Grid.Row>
			<Message
				content="These data were obtained from the NASA Langley Research Center (LaRC) POWER Project funded through the NASA Earth Science/Applied Science Program."
				style={{ marginBottom: "unset", width: "100%", textAlign: "center" }}
			/>
		</Grid>
	);
};
TrialWeatherGraphs.propTypes = {
	trialName: PropTypes.string
};
export default TrialWeatherGraphs;
