import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Segment, Button, Loader, Modal, Popup, Icon } from "semantic-ui-react";
import PropTypes from "prop-types";
import _ from "lodash";
import { produce } from "immer";
import * as uuid from "uuid";
import { toast } from "react-toastify";
import { useParams, useHistory } from "react-router-dom";

import { useModuleNavigation } from "../../../../hooks/useModuleNavigation";
import { useUserAuth } from "../../../../hooks/useUserAuth";
import * as fieldActions from "../../../../redux/actions/fieldActions";
import * as flightActions from "../../../../redux/actions/flightActions";
import * as orthoActions from "../../../../redux/actions/orthoActions";
import UploadOrthoImage from "./UploadOrthoImage";
import { useAuth0 } from "../../../../auth/auth0";
import UploadedElevationImage from "./UploadElevationImage";
import UploadMultibandImage from "./UploadMultibandImage";
import { ApplicationArea } from "../../../Lumber/ApplicationAreas";
import AzureFilesBrowser from "./AzureFilesBrowser";

const OrthoUpload = ({ flightName }) => {
	const dispatch = useDispatch();
	const params = useParams();
	const history = useHistory();
	const moduleNavigation = useModuleNavigation();
	const userAuth = useUserAuth();

	const { getTokenSilently } = useAuth0();

	//Data Sources
	const uploadingOrthos = useSelector((state) => state.orthoUploads);
	const autoUploadedOrthos = useSelector((state) => state.autoUploadedOrthos);

	//Data
	const [sensorsWithFlightImages, setSensorsWithFlightImages] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [isFileBrowserLoading, setIsFileBrowserLoading] = useState(true);
	const [unprocessedOrthos, setUnprocessedOrthos] = useState([]);
	const [showUnprocessedOrthosWarning, setShowUnprocessedOrthosWarning] = useState(false);
	const [continueLoading, setContinueLoading] = useState(false);
	const [flightsPlots, setFlightsPlots] = useState(null);
	const [azureFilesList, setAzureFilesList] = useState([]);
	const [azureBrowseModalToggle, setAzureBrowseModalToggle] = useState(false);
	const [selectedAzureFileElevation, setSelectedAzureFileElevation] = useState(null);
	const [selectedAzureFileOrtho, setSelectedAzureFileOrtho] = useState({});
	const [selectedAzureFileMultiband, setSelectedAzureFileMultiband] = useState(null);
	const [browseModalCallerComponentId, setBrowseModalCallerComponentId] = useState(null);

	const ComponentIds = {
		ELEVATION: 0,
		MULTIBAND: 1
	};

	useEffect(() => {
		if (flightsPlots === null) {
			checkForExistingPlots();
		}
	}, []);

	useEffect(() => {
		if (!sensorsWithFlightImages || _.isEmpty(sensorsWithFlightImages)) {
			getOrthosForFlight();
		}
	}, [sensorsWithFlightImages]);

	useEffect(() => {
		if (_.isEmpty(azureFilesList)) {
			downloadAzureFilesList();
		}
	}, [azureFilesList]);

	useEffect(() => {
		_.map(autoUploadedOrthos, (uploadedOrtho) => {
			const orthos = uploadedOrtho.filter((x) => x.imageInfo.flightId === moduleNavigation.flightId);
			const sensorsWithFlightImagesCopy = _.cloneDeep(sensorsWithFlightImages);
			orthos.forEach((ortho) => {
				const doesOrthoExist = sensorsWithFlightImages?.flightImages?.find(
					(x) => x?.orthoImageTypeId === ortho?.imageData.orthoImageTypeId
				);
				if (ortho && !doesOrthoExist) {
					sensorsWithFlightImagesCopy.flightImages.push(ortho.imageData);
					let compositeName = { name: ortho.imageInfo.fileName };
					sensorsWithFlightImagesCopy.multibandImage = compositeName;
				}
			});
			setSensorsWithFlightImages(sensorsWithFlightImagesCopy);
		});
	}, [autoUploadedOrthos]);

	async function downloadAzureFilesList() {
		const accessToken = await getTokenSilently();
		const directoryName = ".";
		dispatch(orthoActions.downloadAzureFilesList(directoryName, accessToken))
			.then((res) => {
				setIsFileBrowserLoading(false);
				setAzureFilesList(res.data);
			})
			.catch(() => {
				setIsFileBrowserLoading(false);
				toast.error("Failed to retrieve file list. Please try again by reloading the page.");
			});
	}

	const handleAzureBrowseCallback = (callerComponentId) => {
		setBrowseModalCallerComponentId(callerComponentId);
		setAzureBrowseModalToggle(!azureBrowseModalToggle);
	};

	const handleAzureBrowseFileSelectOrtho = (file) => {
		const componentIdFilePair = { [browseModalCallerComponentId]: file };
		setSelectedAzureFileOrtho(componentIdFilePair);
	};

	const handleAzureBrowseFileSelectElevation = (file) => {
		setSelectedAzureFileElevation(file);
	};

	const handleAzureBrowseFileSelectMultiband = (file) => {
		setSelectedAzureFileMultiband(file);
	};

	const handleAzureUploadSuccess = (uploadData) => {
		// clean up the selected file since if upload is successful it has been deleted from the temp share we pull from

		const { azureShareFileItem: uploadedAzureShareFileItem } = uploadData;
		const updatedAzureFilesList = azureFilesList.filter((azureShareFileItem) => azureShareFileItem.Id !== uploadedAzureShareFileItem.Id);
		setAzureFilesList(updatedAzureFilesList);
	};

	async function getOrthosForFlight() {
		const accessToken = await getTokenSilently();
		dispatch(flightActions.getOrthosForFlight(moduleNavigation.flightId, accessToken))
			.then((result) => {
				_.map(uploadingOrthos, (ortho) => {
					const anyDuplicateOrthoTypes = _.some(result.flightImages, (flightImage) => {
						return flightImage.sensorId === ortho.sensorId && flightImage.orthoImageTypeId === ortho.orthoImageTypeId;
					});
					if (!anyDuplicateOrthoTypes && ortho.flightId === moduleNavigation.flightId) {
						result.flightImages.push(ortho);
					}
				});
				setSensorsWithFlightImages(result);
				setIsLoading(false);
			})
			.catch(() => {
				setIsLoading(false);
				toast.error("Failed to retreive Sensor data. Please try again by reloading the page.");
			});
	}

	const checkForExistingPlots = async () => {
		const accessToken = await getTokenSilently();
		dispatch(flightActions.getExistingPlots(moduleNavigation.flightId, userAuth.currentClientId, accessToken)).then(
			(res) => {
				setFlightsPlots(res);
			}
		);
	};

	const isOrthoTypeUploading = (selectedOrtho) => {
		return uploadingOrthos.some(
			(x) => x.sensorId === selectedOrtho.sensorId && x.orthoImageTypeId === selectedOrtho.orthoImageTypeId
		);
	};

	const isOrthoTypeUploaded = (selectedOrtho) => {
		return sensorsWithFlightImages.flightImages.some(
			(x) =>
				x.sensorId === selectedOrtho.sensorId &&
				x.orthoImageTypeId === selectedOrtho.orthoImageTypeId &&
				x.isUploaded === true
		);
	};

	const uploadComponent = (sensors, orthoImages) => {
		return _.map(orthoImages, (ortho) => {
			return (
				<UploadOrthoImage
					key={ortho.flightImageId}
					flightImageId={ortho.flightImageId}
					orthos={sensors}
					selectedOrthomosaic={ortho}
					deleteUploadComponent={deleteUploadComponent}
					removeComponent={removeComponent}
					isOrthoTypeUploading={isOrthoTypeUploading}
					isOrthoTypeUploaded={isOrthoTypeUploaded}
					clientId={sensorsWithFlightImages.clientId}
					orthoSelectedHandler={onOrthoSelected}
					orthoUploadedHandler={onOrthoUploaded}
					orthoRemovedHandler={onOrthoRemoved}
					multispectralImageUploaded={sensorsWithFlightImages?.multibandImage !== null}
					multispectralImageName={sensorsWithFlightImages?.multibandImage?.name}
					flightsPlots={flightsPlots}
					azureBrowseCallback={handleAzureBrowseCallback}
					azureUploadSuccessCallback={handleAzureUploadSuccess}
					selectedAzureFile={selectedAzureFileOrtho}
				/>
			);
		});
	};

	async function deleteUploadComponent(orthoToDelete, deleteFailed) {
		const accessToken = await getTokenSilently();
		const flightImageIdToDelete = orthoToDelete.uploadedFlightImageId
			? orthoToDelete.uploadedFlightImageId
			: orthoToDelete.flightImageId;
		dispatch(flightActions.deleteOrthoForFlight(flightImageIdToDelete, orthoToDelete.clientId, accessToken))
			.then(() => {
				removeComponent(orthoToDelete.flightImageId);
				toast.success("Ortho deleted.");
			})
			.catch(() => {
				deleteFailed();
			});
	}

	function removeComponent(orthoToDeleteId) {
		const updatedUploadedFlightImages = produce(sensorsWithFlightImages, (draft) => {
			draft.flightImages = draft.flightImages.filter((x) => x.flightImageId !== orthoToDeleteId);
		});

		setSensorsWithFlightImages(updatedUploadedFlightImages);
	}

	const addOrtho = (sensor) => {
		//-- Populate NDVI, NDRE, or RGB if it is an option and it is NOT on the flight already
		let presetOrthoImageTypeId = null;
		const orthosOnSensor = getFlightImagesOnSensor(sensor.sensorId);
		const orthoOpts = getFlightSensors(sensor.sensorId);
		if (doesSensorHaveOrthoType(orthoOpts, "NDVI") && !doesFlightHaveOrtho(orthosOnSensor, orthoOpts, "NDVI")) {
			presetOrthoImageTypeId = _.find(orthoOpts, (o) => {
				return o.orthoImageType === "NDVI";
			})?.orthoImageTypeId;
		} else if (doesSensorHaveOrthoType(orthoOpts, "NDRE") && !doesFlightHaveOrtho(orthosOnSensor, orthoOpts, "NDRE")) {
			presetOrthoImageTypeId = _.find(orthoOpts, (o) => {
				return o.orthoImageType === "NDRE";
			})?.orthoImageTypeId;
		} else if (doesSensorHaveOrthoType(orthoOpts, "ALN") && !doesFlightHaveOrtho(orthosOnSensor, orthoOpts, "ALN")) {
			presetOrthoImageTypeId = _.find(orthoOpts, (o) => {
				return o.orthoImageType === "ALN";
			})?.orthoImageTypeId;
		} else if (
			doesSensorHaveOrthoType(orthoOpts, "CCC_1") &&
			!doesFlightHaveOrtho(orthosOnSensor, orthoOpts, "CCC_1")
		) {
			presetOrthoImageTypeId = _.find(orthoOpts, (o) => {
				return o.orthoImageType === "CCC_1";
			})?.orthoImageTypeId;
		} else if (doesSensorHaveOrthoType(orthoOpts, "LAI") && !doesFlightHaveOrtho(orthosOnSensor, orthoOpts, "LAI")) {
			presetOrthoImageTypeId = _.find(orthoOpts, (o) => {
				return o.orthoImageType === "LAI";
			})?.orthoImageTypeId;
		} else if (doesSensorHaveOrthoType(orthoOpts, "RGB") && !doesFlightHaveOrtho(orthosOnSensor, orthoOpts, "RGB")) {
			presetOrthoImageTypeId = _.find(orthoOpts, (o) => {
				return o.orthoImageType === "RGB";
			})?.orthoImageTypeId;
		} else if (
			doesSensorHaveOrthoType(orthoOpts, "Thermal0_75C") &&
			!doesFlightHaveOrtho(orthosOnSensor, orthoOpts, "Thermal0_75C")
		) {
			presetOrthoImageTypeId = _.find(orthoOpts, (o) => {
				return o.orthoImageType === "Thermal0_75C";
			})?.orthoImageTypeId;
		}

		const updatedUploadedFlightImages = produce(sensorsWithFlightImages, (draft) => {
			draft.flightImages.push({
				flightImageId: uuid.v4(),
				name: "",
				flightId: params.id,
				sensorId: sensor.sensorId,
				orthoImageTypeId: presetOrthoImageTypeId,
				isUploaded: false,
				uploading: false,
				uploadPercent: 0,
				canGenerateMultispectralImage:
					_.find(orthoOpts, (o) => {
						return o.orthoImageTypeId === presetOrthoImageTypeId;
					})?.canGenerateMultispectralImage === true
			});
		});

		setSensorsWithFlightImages(updatedUploadedFlightImages);
	};

	function doesSensorHaveOrthoType(orthoOptions, orthoImageName) {
		const result = _.some(orthoOptions, (oo) => {
			return oo.orthoImageType === orthoImageName;
		});

		return result;
	}

	function doesFlightHaveOrtho(orthosOnSensor, orthoOptions, orthoImageName) {
		const orthoImageTypeId = _.find(orthoOptions, (o) => {
			return o.orthoImageType === orthoImageName;
		})?.orthoImageTypeId;

		const f = _.find(orthosOnSensor, (o) => {
			return o.orthoImageTypeId === orthoImageTypeId;
		});

		return f;
	}

	async function handleContinue() {
		setContinueLoading(true);
		const accessToken = await getTokenSilently();
		dispatch(flightActions.getOrthosForFlight(moduleNavigation.flightId, accessToken)).then(async (orthos) => {
			let clientId = orthos.clientId;
			let fieldsInFlight = [];

			// eslint-disable-next-line
			const allImagesChecked = await Promise.all(
				_.map(orthos.flightImages, async (flightImage) => {
					let orthoId = flightImage.orthoImageTypeId;

					await dispatch(fieldActions.getUsedFields(moduleNavigation.flightId, orthoId, clientId, accessToken)).then(
						(res) => {
							_.assign(fieldsInFlight, res);
						}
					);
				})
			);

			if (
				fieldsInFlight.length > 0 &&
				userAuth.hasApplicationArea(ApplicationArea.FlightAlignFields, userAuth.currentClientId)
			) {
				history.push(moduleNavigation.createFlightLink(true, null, "align-fields"));
			} else if (userAuth.hasApplicationArea(ApplicationArea.FlightAddNewFields, userAuth.currentClientId)) {
				history.push(moduleNavigation.createFlightLink(true, null, "add-new-fields"));
			} else if (userAuth.hasApplicationArea(ApplicationArea.FlightPlotAnalysis, userAuth.currentClientId)) {
				history.push(moduleNavigation.createFlightLink(true, null, "plot-analysis"));
			}
		});
	}

	const getFlightImagesOnSensor = (sensorId) => {
		return _.filter(sensorsWithFlightImages.flightImages, ["sensorId", sensorId]);
	};

	const getFlightSensors = (sensorId) => {
		return _.filter(sensorsWithFlightImages.flightSensors, ["sensorId", sensorId]);
	};

	function onOrthoSelected(fileName) {
		setUnprocessedOrthos(unprocessedOrthos.concat([fileName]));
	}

	const onOrthoUploaded = (fileName) => {
		setUnprocessedOrthos(unprocessedOrthos.filter((uo) => uo !== fileName));
	};

	const onOrthoRemoved = (fileName) => {
		setUnprocessedOrthos(unprocessedOrthos.filter((uo) => uo !== fileName));
	};

	return (
		<>
			{isLoading || isFileBrowserLoading ? (
				<Loader active />
			) : (
				<>
					<h2 style={{ float: "right", marginTop: "unset", color: "rgba(7, 55, 99, 0.75)" }}>
						<i>{flightName}</i>
					</h2>
					<hr style={{ clear: "both" }} />
					<Segment basic style={{ minHeight: 50, marginBottom: 30 }}>
						<Button
							id="form-button-back"
							floated="left"
							secondary
							content={"Back"}
							onClick={() => {
								history.push(moduleNavigation.createFlightLink(true, null, "info"));
							}}
						/>
						<Button
							id="form-button-continue"
							floated="right"
							color="green"
							content={"Continue"}
							onClick={() => {
								if (unprocessedOrthos.length > 0) {
									setShowUnprocessedOrthosWarning(true);
								} else {
									handleContinue();
								}
							}}
							disabled={
								continueLoading ||
								//-- Check if user has access to any of the down stream pages to continue to. If they do not have access to ANY of the down stream pages, disable the button
								(!userAuth.hasApplicationArea(ApplicationArea.FlightAlignFields, userAuth.currentClientId) &&
									!userAuth.hasApplicationArea(ApplicationArea.FlightAddNewFields, userAuth.currentClientId) &&
									!userAuth.hasApplicationArea(ApplicationArea.FlightPlotAnalysis, userAuth.currentClientId))
							}
							loading={continueLoading}
						/>
					</Segment>
					<Segment basic>
						<h2>Upload Processed Orthomosaics (Vegetative Indices)</h2>
					</Segment>

					<Segment basic>
						{_.map(_.uniqBy(sensorsWithFlightImages.flightSensors, "sensorId"), (sensor) => {
							return (
								<div key={sensor.sensorId} style={{ marginBottom: 20 }}>
									<h2 style={{ marginBottom: 10 }}>Sensor: {sensor.sensorName}</h2>
									{uploadComponent(getFlightSensors(sensor.sensorId), getFlightImagesOnSensor(sensor.sensorId))}
									{!(getFlightImagesOnSensor(sensor.sensorId).length >= getFlightSensors(sensor.sensorId).length) ? (
										<Button icon="add" content="Add Ortho" onClick={() => addOrtho(sensor)} />
									) : null}
								</div>
							);
						})}
					</Segment>

					{sensorsWithFlightImages.flightSensorHasMultiBandImageTypes &&
						userAuth.hasApplicationArea(ApplicationArea.FlightOrthoUpload, userAuth.currentClientId) ? (
						<Segment basic>
							{_.some(sensorsWithFlightImages.flightSensors, (s) => {
								return s.orthoImageType === "Rapid_RGB";
							}) ? (
								<h2>
									<span>
										Upload Rapid RGB{" "}
										{
											<Popup
												content="Rapid RGB is a low resolution RGB image. Uploading a Rapid RGB composite will automatically create a Rapid_RGB ortho image."
												trigger={
													<Icon
														size="small"
														name="info circle"
														link
														style={{ display: "table-cell", verticalAlign: "middle" }}
													/>
												}
											/>
										}
									</span>
								</h2>
							) : (
								<h2>Upload Composite Multispectral</h2>
							)}

							<UploadMultibandImage
								multibandImage={sensorsWithFlightImages?.multibandImage}
								sensorId={sensorsWithFlightImages?.flightSensors[0]?.sensorId}
								clientId={sensorsWithFlightImages?.clientId}
								azureBrowseCallback={handleAzureBrowseCallback}
								azureUploadSuccessCallback={handleAzureUploadSuccess}
								selectedAzureFile={selectedAzureFileMultiband}
								componentId={ComponentIds.MULTIBAND}
							/>
						</Segment>
					) : null}
					{userAuth.hasApplicationArea(ApplicationArea.FlightOrthoUpload, userAuth.currentClientId) ? (
						<Segment basic>
							<h2>Upload Digital Elevation Model</h2>

							<UploadedElevationImage
								elevationImage={sensorsWithFlightImages?.elevationImage}
								sensorId={sensorsWithFlightImages?.flightSensors[0]?.sensorId}
								clientId={sensorsWithFlightImages?.clientId}
								azureBrowseCallback={handleAzureBrowseCallback}
								azureUploadSuccessCallback={handleAzureUploadSuccess}
								selectedAzureFile={selectedAzureFileElevation}
								componentId={ComponentIds.ELEVATION}
							/>
						</Segment>
					) : null}
				</>
			)}
			<Modal open={showUnprocessedOrthosWarning}>
				<Modal.Header>Unuploaded Orthos</Modal.Header>
				<Modal.Content>
					<Modal.Description>
						<p>{`You selected orthos that haven't been uploaded yet. If you'd like to upload them, please click the Upload buton. Otherwise, please remove them from the sensor to continue.`}</p>
					</Modal.Description>
				</Modal.Content>
				<Modal.Actions>
					<Button floated="right" onClick={() => setShowUnprocessedOrthosWarning(false)}>
						Ok
					</Button>
					<br style={{ clear: "both" }} />
				</Modal.Actions>
			</Modal>

			<AzureFilesBrowser
				data={azureFilesList}
				open={azureBrowseModalToggle}
				onClose={handleAzureBrowseCallback}
				onConfirmOrtho={handleAzureBrowseFileSelectOrtho}
				onConfirmElevation={handleAzureBrowseFileSelectElevation}
				onConfirmMultiband={handleAzureBrowseFileSelectMultiband}
				callerComponentId={browseModalCallerComponentId}
				componentIds={ComponentIds}
			></AzureFilesBrowser>
		</>
	);
};

OrthoUpload.propTypes = {
	flightName: PropTypes.string.isRequired
};

export default OrthoUpload;
