import moment from "moment";

export function validateForm(field) {
	let validation = { error: false, errorList: [] };

	if (!field.name || field.name.length > 50) {
		validation.errorList.push("Field Name cannot be empty or more than 50 characters.");
		validation.nameError = true;
		validation.error = true;
	}

	if (field.name.slice(-1) == " ") {
		validation.errorList.push("Please remove extra spaces at the end of Field Name");
		validation.nameError = true;
		validation.error = true;
	}

	if (field.name[0] == " ") {
		validation.errorList.push("Please remove extra spaces at the beginning of Field Name");
		validation.nameError = true;
		validation.error = true;
	}

	if (!field.growingSeasonId || field.growingSeasonId === "select") {
		validation.errorList.push("Growing Season is required.");
		validation.growingSeasonError = true;
		validation.error = true;
	}

	if (!field.farmId || field.farmId === "select") {
		validation.errorList.push("Farm is required.");
		validation.farmError = true;
		validation.error = true;
	}

	if (!field.cropId || field.cropId === "select") {
		validation.errorList.push("Crop is required.");
		validation.cropError = true;
		validation.error = true;
	}

	if (!field.cooperatorCompanyId) {
		validation.errorList.push("Cooperator company is required.");
		validation.cooperatorCompanyError = true;
		validation.error = true;
	}

	if (field.plantDate && !moment.isDate(field.plantDate)) {
		validation.errorList.push("Plant Date is invalid.");
		validation.plantDateError = true;
		validation.error = true;
	}

	if (field.harvestDate && !moment.isDate(field.harvestDate)) {
		validation.errorList.push("Harvest Date is invalid.");
		validation.harvestDateError = true;
		validation.error = true;
	}

	if (field.harvestDate && field.plantDate && field.harvestDate < field.plantDate) {
		validation.errorList.push("Plant Date must be before Harvest Date.");
		validation.harvestDateError = true;
		validation.plantDateError = true;
		validation.error = true;
	}

	if (!field.numOfColumns || field.numOfColumns <= 0 || field.numOfColumns % 1 != 0) {
		validation.errorList.push("Number of Columns cannot be empty or less than 0.");
		validation.numOfColumnsError = true;
		validation.error = true;
	}

	if (!field.numOfRanges || field.numOfRanges <= 0 || field.numOfRanges % 1 != 0) {
		validation.errorList.push("Number of Ranges cannot be empty or less than 0.");
		validation.numOfRangesError = true;
		validation.error = true;
	}

	if (
		field.columnWidth === undefined ||
		field.columnWidth === null ||
		field.columnWidth === "" ||
		field.columnWidth < 0
	) {
		validation.errorList.push("Column Width cannot be empty or less than 0.");
		validation.columnWidthError = true;
		validation.error = true;
	}

	if (
		field.rangeLength === undefined ||
		field.rangeLength === null ||
		field.rangeLength === "" ||
		field.rangeLength < 0
	) {
		validation.errorList.push("Range Length cannot be empty or less than 0.");
		validation.rangeLengthError = true;
		validation.error = true;
	}

	if (!field.rowsPerPlot || field.rowsPerPlot <= 0 || field.rowsPerPlot % 1 != 0) {
		validation.errorList.push("Rows Per Plot cannot be empty or less than 0.");
		validation.rowsPerPlotError = true;
		validation.error = true;
	}

	if (!field.passesPerPlot || field.passesPerPlot <= 0 || field.passesPerPlot % 1 != 0) {
		validation.errorList.push("Passes Per Plot cannot be empty or less than 0.");
		validation.passesPerPlotError = true;
		validation.error = true;
	}

	if (field.rowSpacing === undefined || field.rowSpacing === null || field.rowSpacing === "" || field.rowSpacing < 0) {
		validation.errorList.push("Row Spacing cannot be empty or less than 0.");
		validation.rowSpacingError = true;
		validation.error = true;
	}

	if (
		field.alleyLength === undefined ||
		field.alleyLength === null ||
		field.alleyLength === "" ||
		field.alleyLength < 0
	) {
		validation.errorList.push("Alley Length cannot be empty or less than 0.");
		validation.alleyLengthError = true;
		validation.error = true;
	}

	if (
		field.planterOffset === undefined ||
		field.planterOffset === null ||
		field.planterOffset === "" ||
		field.planterOffset < 0
	) {
		validation.errorList.push("Planter Offset cannot be empty or less than 0.");
		validation.planterOffsetError = true;
		validation.error = true;
	}

	//-- Adding fudge factor of 5in
	if (
		Number(field.columnWidth) * 12 <
		Number(field.rowSpacing) * Number(field.rowsPerPlot) + Number(field.planterOffset) - 5
	) {
		validation.errorList.push(
			"Column width must be equal to or larger than the product of row spacing and rows per plot, plus the planter offset."
		);
		validation.columnWidthBoundsError = true;
		validation.error = true;
	}

	return validation;
}
