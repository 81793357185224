const imageApiUri = process.env.aerialPlotConnections.aerialImageApi.baseUrl + "api/v1/";
const aerialApiUri = process.env.aerialPlotConnections.aerialPlotApi.baseUrl + "api/v1/";

import * as orthoApi from "./orthoApi";
import _ from "lodash";

export function getFlightInfo(clientId, accessToken) {
	return fetch(aerialApiUri + "Flights/GetNewEditFlightInfo?clientId=" + clientId, {
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})
		.then((res) => res.json())
		.then((data) => data);
}

export function saveNewFlight(flight, accessToken) {
	let flightT = _.cloneDeep(flight);
	flightT.flightDateTime = flightT.flightDateTime.toLocaleString();
	return fetch(aerialApiUri + "Flights", {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${accessToken}`
		},
		body: JSON.stringify(flightT)
	})
		.then((res) => res.json())
		.then((data) => data);
}

export function updateFlight(flight, accessToken) {
	let flightT = _.cloneDeep(flight);
	flightT.flightDateTime = flightT.flightDateTime.toLocaleString();
	return fetch(aerialApiUri + "Flights", {
		method: "PUT",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${accessToken}`
		},
		body: JSON.stringify(flightT)
	}).then((res) => res);
}

export function getFlight(flightId, accessToken) {
	return fetch(aerialApiUri + "Flights?flightId=" + flightId, {
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})
		.then((res) => res.json())
		.then((data) => data);
}

export function getFlightLogDataOptions(clientId, searchAllClients, accessToken) {
	return fetch(
		aerialApiUri + `Flights/FlightLogDataOptions?clientId=${clientId}&searchAllClients=${searchAllClients}`,
		{
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}
	)
		.then((res) => res.json())
		.then((data) => data);
}

export function getFlightLog(startDateTime, endDateTime, clientId, searchAllClients, accessToken) {
	return fetch(
		aerialApiUri +
			"Flights/FlightLog?startDateTime=" +
			startDateTime +
			"&endDateTime=" +
			endDateTime +
			"&clientId=" +
			clientId +
			"&searchAllClients=" +
			searchAllClients,
		{
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}
	)
		.then((res) => res.json())
		.then((data) => data);
}

export async function deleteFlight(flightId, clientId, accessToken) {
	var deleteFlightImages = await fetch(`${imageApiUri}Delete/Flight?flightId=${flightId}&clientId=${clientId}`, {
		method: "DELETE",
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	});

	const deleteImageRes = await deleteFlightImages;

	if (deleteImageRes.ok === true) {
		var deleteFlight = fetch(aerialApiUri + "Flights?flightId=" + flightId + "&clientId=" + clientId, {
			method: "DELETE",
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}).then((res) => res);

		return await deleteFlight;
	}

	return deleteImageRes;
}

export function getOrthosForFlight(flightId, accessToken) {
	return fetch(aerialApiUri + "OrthoUploadData?flightId=" + flightId, {
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})
		.then((res) => res.json())
		.then((data) => data);
}

export function getViewerOrthoOptions(flightId, fieldId, trialId, clientId, accessToken) {
	return fetch(
		`${aerialApiUri}OrthoUploadData/GetViewerOrthoOptions?flightId=${flightId}&fieldId=${fieldId}&trialId=${trialId}&clientId=${clientId}`,
		{
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}
	)
		.then((res) => res.json())
		.then((data) => data.data);
}

export function downloadUploadedOrtho(orthoId, fileName, clientId, accessToken) {
	// eslint-disable-next-line
	const downloadOrthoPromise = new Promise(function (resolve, reject) {
		resolve(
			fetch(`${imageApiUri}Download/GetFileSASUri?flightImageId=${orthoId}&clientId=${clientId}`, {
				headers: {
					Authorization: `Bearer ${accessToken}`
				}
			})
				.then((res) => {
					if (res.ok === false) {
						reject(res);
					} else {
						return res.json();
					}
				})
				.then(async (data) => {
					await orthoApi.handleSasToken(data, fileName);
				})
		);
	});

	return downloadOrthoPromise;
}

export function deleteUploadedOrtho(orthoId, clientId, accessToken) {
	// eslint-disable-next-line
	const deleteOrthoPromise = new Promise(function (resolve, reject) {
		resolve(
			fetch(`${imageApiUri}Delete?flightImageId=${orthoId}&clientId=${clientId}`, {
				method: "DELETE",
				headers: {
					Authorization: `Bearer ${accessToken}`
				}
			}).then((res) => res)
		);
	});
	return deleteOrthoPromise;
}

export function deleteFlightElevationImage(flightElevationImageId, clientId, accessToken) {
	// eslint-disable-next-line
	const deleteOrthoPromise = new Promise(function (resolve, reject) {
		resolve(
			fetch(
				`${imageApiUri}Delete/FlightElevationImage?flightElevationImageId=${flightElevationImageId}&clientId=${clientId}`,
				{
					method: "DELETE",
					headers: {
						Authorization: `Bearer ${accessToken}`
					}
				}
			).then((res) => res)
		);
	});
	return deleteOrthoPromise;
}

export function getExistingPlots(flightId, clientId, accessToken) {
	return fetch(`${aerialApiUri}Flights/GetExistingPlots?flightId=${flightId}&clientId=${clientId}`, {
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})
		.then((res) => res.json())
		.then((data) => data.data);
}

export function GetPlotAnalysisStatusForFlight(flightId, clientId, accessToken) {
	return fetch(`${aerialApiUri}Flights/GetPlotAnalysisStatusForFlight?flightId=${flightId}&clientId=${clientId}`, {
		method: "GET",
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => data);
}
