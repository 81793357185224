import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import _ from "lodash";

import { Segment, Grid, List, Form, Checkbox, Button, Modal } from "semantic-ui-react";
import { toast } from "react-toastify";

import { useAuth0 } from "../../../../auth/auth0";

import * as userActions from "../../../../redux/actions/userActions";

const UserManagement = () => {
	const { getTokenSilently } = useAuth0();
	const dispatch = useDispatch();

	//-- Data Source
	const [firstName, setFirstName] = useState(null);
	const [lastName, setLastName] = useState(null);
	const [emailAddress, setEmailAddress] = useState(null);
	const [phone, setPhone] = useState(null);
	const [phoneExt, setPhoneExt] = useState(null);
	const [personId, setPersonId] = useState(null);
	const [personEnabled, setPersonEnabled] = useState(null);
	const [userId, setUserId] = useState(null);
	const [userEnabled, setUserEnabled] = useState(null);
	const [systemCreated, setSystemCreated] = useState(null);
	const [externalIds, setExternalIds] = useState(null);
	const [enabledExternalIds, setEnabledExternalIds] = useState([]);

	//-- Dropdown Options
	const [personOptions, setPersonOptions] = useState(null);

	//-- UI Control
	const [mode, setMode] = useState("update");
	const [saving, setSaving] = useState(false);
	const [loginInstructionsModalOpen, setLoginInstructionsModalOpen] = useState(false);

	useEffect(() => {
		getPersons();
	}, []);

	async function getPersons() {
		const accessToken = await getTokenSilently();
		dispatch(userActions.getPersons(accessToken))
			.then((res) => {
				populatePersonOptions(res.data);
			})
			.catch((err) => {
				console.log(err);
				toast.error("Unable to load users. Please try again.");
			});
	}

	const populatePersonOptions = (pOpts) => {
		const perOpts = _.map(pOpts, ({ personId, firstName, lastName, emailAddress }) => {
			const opt = {
				key: personId,
				value: personId,
				text: firstName + " " + lastName,
				emailAddress
			};
			return opt;
		});

		if (perOpts.length > 0) {
			getPersonDetail(perOpts[0].value);
		}

		setPersonOptions(perOpts);
	};

	async function getPersonDetail(personId) {
		clearPerson();
		const accessToken = await getTokenSilently();
		dispatch(userActions.getPersonDetail(accessToken, personId))
			.then((res) => {
				setPerson(res.data);
			})
			.catch(() => {
				toast.error("Unable to load user info. Please try again.");
			});
	}

	const setPerson = (person) => {
		setFirstName(person.basic.firstName);
		setLastName(person.basic.lastName);
		setEmailAddress(person.basic.emailAddress);
		setPhone(person.basic.phone);
		setPhoneExt(person.basic.phoneExt);
		setPersonId(person.basic.personId);
		setPersonEnabled(person.basic.personEnabled);
		setUserId(person.basic.userId);
		setUserEnabled(person.basic.userEnabled);
		setSystemCreated(person.basic.systemCreated);
		setExternalIds(person.userExternalIds);

		let xids = [];
		person.userExternalIds.forEach((uxi) => {
			if (uxi.enabled) {
				xids = xids.concat(uxi.externalId);
				setEnabledExternalIds(xids);
			}
		});

		setMode("update");
	};

	const clearPerson = () => {
		setFirstName(null);
		setLastName(null);
		setEmailAddress(null);
		setPhone(null);
		setPhoneExt(null);
		setPersonId(null);
		setPersonEnabled(null);
		setUserId(null);
		setUserEnabled(null);
		setExternalIds(null);
		setEnabledExternalIds([]);
	};

	const getPersonObject = () => {
		const perObj = {
			firstName,
			lastName,
			emailAddress,
			phone,
			phoneExt,
			personId,
			personEnabled,
			userId,
			userEnabled,
			enabledExternalIds
		};

		return perObj;
	};

	const saveUser = async () => {
		const perObj = getPersonObject();

		const accessToken = await getTokenSilently();
		if (!systemCreated) {
			if (mode === "update") {
				dispatch(userActions.updatePersonUser(accessToken, perObj))
					.then((res) => {
						if (res.statusCode === 200) {
							toast.success("Saved!");
							const updatedPersonOptions = personOptions.map((p) => {
								if (p.value === personId) {
									p.text = firstName + " " + lastName;
									p.emailAddress = emailAddress;
								}

								return p;
							});
							setPersonOptions(updatedPersonOptions);
						} else if (res.statusCode === 400) {
							if (res.messages && res.messages.length > 0) {
								res.messages.forEach((m) => {
									if (m.type === "Informational") {
										toast.info(m.text);
										const updatedPersonOptions = personOptions.map((p) => {
											if (p.value === personId) {
												p.text = firstName + " " + lastName;
												p.emailAddress = emailAddress;
											}

											return p;
										});
										setPersonOptions(updatedPersonOptions);
									} else if (m.type === "FailedValidation") {
										toast.error(m.text);
									}
								});
							}
						}
					})
					.catch((err) => {
						console.log(err);
						toast.error("Unable to save user. Please try again.");
					});
			} else {
				dispatch(userActions.createNewUser(accessToken, perObj))
					.then((res) => {
						if (res.statusCode === 200) {
							getPersons();
							toast.success("Created!");
						} else if (res.statusCode === 400) {
							if (res.messages && res.messages.length > 0) {
								res.messages.forEach((m) => {
									if (m.type === "Informational") {
										setPerson(res.data);
										toast.info(m.text);
									} else if (m.type === "FailedValidation") {
										toast.error(m.text);
									}
								});
							}
						}
					})
					.catch((err) => {
						toast.error("Unable to create new user. Please try again.");
						console.log(err);
					});
			}
		} else {
			toast.warn("The selected person is a system created person and cannot be edited.");
		}
	};

	const createUser = async () => {
		if (!systemCreated) {
			const accessToken = await getTokenSilently();
			setSaving(true);
			dispatch(userActions.createUserForPerson(accessToken, personId))
				.then((res) => {
					setSaving(false);
					toast.success("Created!");
					setUserId(res.data.userId);
					setUserEnabled(res.data.userEnabled);
				})
				.catch((err) => {
					setSaving(false);
					console.log(err);
					toast.error("Unable to create user. Please try again.");
				});
		} else {
			toast.warn("The selected person is a system created person and cannot be edited.");
		}
	};

	const prepareNewUserForm = () => {
		setMode("create");
		clearPerson();
	};

	const emailLoginInstructions = async () => {
		const accessToken = await getTokenSilently();
		setSaving(true);
		dispatch(userActions.emailLoginInstructions(accessToken, userId))
			.then(() => {
				setSaving(false);
				handleLoginInstructionsModal();
				toast.success("Emailed User Login Instructions!");
			})
			.catch((err) => {
				setSaving(false);
				console.log(err);
				toast.error("Failed to email user. Please try again.");
			});
	};

	const handleLoginInstructionsModal = () => {
		setLoginInstructionsModalOpen(!loginInstructionsModalOpen);
	};

	return (
		<Segment style={{ marginLeft: 50, marginTop: 15 }}>
			<h2 style={{ float: "left" }}>User Management</h2>
			<hr style={{ clear: "both" }} />
			<Form>
				<Grid id="user-management-grid" columns="equal">
					<Grid.Row>
						<Grid.Column width={4}>
							<label htmlFor="form-select-person" className="fieldLabel">
								Users
							</label>
							<Form.Button
								id="new-user"
								color="green"
								content="New User"
								style={{ width: "calc(100% + 4px)", marginTop: 5, marginBottom: 10 }}
								onClick={() => prepareNewUserForm()}
							/>
							<List
								id="form-select-person"
								selection
								size="big"
								style={{
									minHeight: 150,
									maxHeight: 500,
									marginTop: "unset",
									overflowY: "auto",
									border: "1px solid #DEDEDF",
									backgroundColor: "#FFFFFF"
								}}
							>
								{_.map(personOptions, ({ key, value, text, emailAddress }) => {
									return (
										<List.Item
											key={key}
											active={personId === value}
											onClick={() => {
												getPersonDetail(value);
											}}
										>
											<List.Content>
												<span style={{ fontSize: 14, color: "#000000" }}>{text}</span>
												{emailAddress && <span style={{ float: "right", fontSize: 12 }}>({emailAddress})</span>}
											</List.Content>
										</List.Item>
									);
								})}
							</List>
						</Grid.Column>
						<Grid.Column>
							<Form.Group widths={"equal"}>
								<Form.Input
									id="form-person-first-name"
									width={6}
									label="First Name"
									value={firstName ?? ""}
									required
									onChange={(event) => setFirstName(event.target.value)}
									readOnly={systemCreated}
								/>
								<Form.Input
									id="form-person-last-name"
									width={6}
									label="Last Name"
									value={lastName ?? ""}
									required
									onChange={(event) => setLastName(event.target.value)}
									readOnly={systemCreated}
								/>
							</Form.Group>
							<Form.Group widths={"equal"}>
								<Form.Input
									id="form-person-email-address"
									width={6}
									label="Email Address"
									value={emailAddress ?? ""}
									required
									onChange={(event) => setEmailAddress(event.target.value)}
									readOnly={systemCreated}
								/>
							</Form.Group>
							<Form.Group widths={"equal"}>
								<Form.Input
									id="form-person-phone"
									width={6}
									label="Phone"
									value={phone ?? ""}
									onChange={(event) => setPhone(event.target.value)}
									readOnly={systemCreated}
								/>
								<Form.Input
									id="form-person-phone-ext"
									width={6}
									label="Phone Ext."
									value={phoneExt ?? ""}
									onChange={(event) => setPhoneExt(event.target.value)}
									readOnly={systemCreated}
								/>
							</Form.Group>
							<hr style={{ clear: "both" }} />
							<Form.Group>
								<Form.Input id="form-person-id" width={8} label="Person Id" value={personId ?? ""} readOnly />
								<Form.Checkbox
									id="form-person-enabled"
									width={3}
									label="Enabled?"
									style={{ marginTop: 33 }}
									checked={personEnabled ?? false}
									onChange={(event, data) => {
										setPersonEnabled(data.checked);
									}}
									disabled={mode === "create"}
									readOnly={systemCreated}
								/>
							</Form.Group>
							<Form.Group>
								<Form.Input id="form-user-id" width={8} label="User Id" value={userId ?? ""} readOnly />
								<Form.Checkbox
									id="form-user-enabled"
									width={3}
									label="Enabled?"
									style={{ marginTop: 33 }}
									disabled={!userId}
									checked={userEnabled ?? false}
									onChange={(event, data) => {
										setUserEnabled(data.checked);
									}}
								/>
								{!userId && mode === "update" && !systemCreated && (
									<Form.Button
										id="form-create-user"
										width={3}
										primary
										content="Create user"
										style={{ marginTop: 25 }}
										onClick={() => createUser()}
									/>
								)}
							</Form.Group>
							{userId && (
								<>
									<label htmlFor="form-select-external-ids" className="fieldLabel">
										User External Ids
									</label>
									<List
										id="form-select-external-ids"
										size="big"
										style={{
											overflowY: "auto",
											border: "1px solid #DEDEDF",
											backgroundColor: "#FFFFFF",
											marginTop: 4,
											minHeight: 100
										}}
									>
										{_.map(_.orderBy(externalIds, ["modifiedDateTimeUtc"], ["desc"]), ({ externalId }) => {
											return (
												<List.Item key={externalId}>
													<List.Content>
														<Checkbox
															label={externalId}
															style={{ padding: 5 }}
															checked={enabledExternalIds.includes(externalId)}
															onChange={(event, data) => {
																if (data.checked) {
																	setEnabledExternalIds(enabledExternalIds.concat([externalId]));
																} else {
																	setEnabledExternalIds(enabledExternalIds.filter((r) => r !== externalId));
																}
															}}
														/>
													</List.Content>
												</List.Item>
											);
										})}
									</List>
								</>
							)}

							<Form.Group inline floated="right" widths="equal" style={{ marginTop: 50 }}>
								<Form.Field>
									<div style={{ marginTop: 10 }}>
										<Button
											floated="right"
											color="green"
											content="Save"
											onClick={() => saveUser()}
											loading={saving}
											disabled={saving}
										/>

										<Modal
											open={loginInstructionsModalOpen}
											trigger={
												userId &&
												(_.some(externalIds, (uxi) => {
													return (
														!uxi.externalId.toUpperCase().includes("MICROSOFT") &&
														!uxi.externalId.toUpperCase().includes("GOOGLE") &&
														!uxi.externalId.toUpperCase().includes("AERIALPLOT")
													);
												}) ||
													externalIds?.length === 0) &&
												mode === "update" && (
													<Button
														id="form-create-user"
														floated="right"
														primary
														content="Email Login Instructions"
														onClick={() => handleLoginInstructionsModal()}
														loading={saving}
														disabled={saving}
													/>
												)
											}
										>
											<Modal.Header>Login Instructions</Modal.Header>
											<Modal.Content>
												<Modal.Description>
													<p>
														Sending login instructions will trigger the user to reset their password and an email with
														login instructions will be sent to them. Are you sure you want to continue?
													</p>
												</Modal.Description>
											</Modal.Content>
											<Modal.Actions>
												<Button
													type="button"
													primary
													loading={saving}
													disabled={saving}
													onClick={() => {
														emailLoginInstructions();
													}}
													floated="right"
													style={{ marginBottom: 10 }}
												>
													Email Login Instructions
												</Button>
												<Button
													type="button"
													loading={saving}
													disabled={saving}
													onClick={() => handleLoginInstructionsModal()}
													floated="right"
													style={{ marginBottom: 10 }}
												>
													Cancel
												</Button>
												<br style={{ clear: "both" }} />
											</Modal.Actions>
										</Modal>
									</div>
								</Form.Field>
							</Form.Group>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</Form>
		</Segment>
	);
};

export default UserManagement;
