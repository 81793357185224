import _ from "lodash";

const aerialApiUri = process.env.aerialPlotConnections.aerialPlotApi.baseUrl;

export function updateMarkedPlots(accessToken, updateInfo) {
	const formData = new FormData();

	for (const name in updateInfo) {
		let val = updateInfo[name] === "" ? null : updateInfo[name];

		if (val) {
			if (!Array.isArray(val)) {
				if (name === "dateAndTime") {
					formData.set(name, val.toLocaleString());
				} else {
					formData.set(name, val);
				}
			} else {
				for (const v in val) {
					if (typeof val[v] === "object" && val[v] !== null) {
						for (const nested in val[v]) {
							if (val[v][nested] !== null) {
								formData.append(`${name}[${v}].${nested.charAt(0).toUpperCase() + nested.slice(1)}`, val[v][nested]);
							}
						}
					} else {
						formData.append(`${name}[${v}]`, val[v]);
					}
				}
			}
		}
	}

	return fetch(`${aerialApiUri}api/v1/MarkedPlots/UpdateMarkedPlots`, {
		method: "PUT",
		headers: {
			Authorization: `Bearer ${accessToken}`
		},
		body: formData
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export const deleteMarkedPlots = (deleteInfo, accessToken) => {
	const formData = new FormData();

	formData.set("ClientId", deleteInfo.clientId);

	let index = 0;

	_.forEach(deleteInfo.markedPlots, (mp) => {
		formData.append(`MarkedPlots[${index}].PlotId`, mp.plotId ?? "");
		formData.append(`MarkedPlots[${index}].IsAllData`, mp.isAllData ?? "");
		formData.append(`MarkedPlots[${index}].FlightId`, mp.flightId ?? "");
		formData.append(`MarkedPlots[${index}].DateAndTime`, mp.dateAndTime?.toLocaleString() ?? "");
		formData.append(`MarkedPlots[${index}].PlotStatusId`, mp.plotStatusId ?? "");
		index++;
	});

	index = 0;
	_.forEach(deleteInfo.plotTrialPairs, (ptp) => {
		formData.append(`PlotTrialPairs[${index}].PlotId`, ptp.plotId);
		if (ptp.trialId !== null) {
			formData.append(`PlotTrialPairs[${index}].TrialId`, ptp.trialId);
		}
		index++;
	});

	return fetch(`${aerialApiUri}api/v1/MarkedPlots/DeleteMarkedPlots`, {
		method: "DELETE",
		headers: {
			Authorization: `Bearer ${accessToken}`
		},
		body: formData
	})
		.then((res) => res.json())
		.then((data) => data);
};

export const deleteMarkedQuantifiedRegions = (deleteInfo, accessToken) => {
	let formData = new FormData();

	formData.set("ClientId", deleteInfo.clientId);

	for (let index = 0; index < deleteInfo.markedQuantifiedRegions.length; index++) {
		const mqr = deleteInfo.markedQuantifiedRegions[index];
		for (const name in mqr) {
			let val = mqr[name] === "" ? null : mqr[name];

			if (val) {
				if (name === "dateAndTime") {
					formData.append(`MarkedQuantifiedRegions[${index}][${name}]`, val.toLocaleString());
				} else {
					formData.append(`MarkedQuantifiedRegions[${index}][${name}]`, val);
				}
			}
		}
	}

	let index = 0;
	_.forEach(deleteInfo.plotTrialPairs, (ptp) => {
		formData.append(`PlotTrialPairs[${index}].PlotId`, ptp.plotId);
		if (ptp.trialId !== null) {
			formData.append(`PlotTrialPairs[${index}].TrialId`, ptp.trialId);
		}
		index++;
	});

	return fetch(`${aerialApiUri}api/v1/MarkedPlots/DeleteMarkedQuantifiedRegions`, {
		method: "DELETE",
		headers: {
			Authorization: `Bearer ${accessToken}`
		},
		body: formData
	})
		.then((res) => res.json())
		.then((data) => data);
};
