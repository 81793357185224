const statisticsApiUri = process.env.aerialPlotConnections.aerialStatisticsApi.baseUrl;

export function generateFieldHeatmapExcel(clientId, clientName, fieldId, currentDateTimeString, accessToken) {
	return fetch(
		`${statisticsApiUri}api/v1/ExcelGenerator/GenerateFieldHeatmapExcel?clientId=${clientId}&clientName=${clientName}&fieldId=${fieldId}&currentDateTimeString=${currentDateTimeString}`,
		{
			method: "POST",
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}
	)
		.then((res) => {
			return res.json();
		})
		.then((data) => data.data);
}

export function generateTrialHeatmapExcel(
	clientId,
	clientName,
	trialId,
	isForAdmin,
	currentDateTimeString,
	accessToken
) {
	return fetch(
		`${statisticsApiUri}api/v1/ExcelGenerator/GenerateTrialHeatmapExcel?clientId=${clientId}&clientName=${clientName}&trialId=${trialId}&isForAdmin=${isForAdmin}&currentDateTimeString=${currentDateTimeString}`,
		{
			method: "POST",
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}
	)
		.then((res) => {
			return res.json();
		})
		.then((data) => data.data);
}

export function generateProtocolHeatmapExcel(
	trials,
	clientId,
	clientName,
	isForAdmin,
	currentDateTimeString,
	accessToken
) {
	return fetch(
		`${statisticsApiUri}api/v1/ExcelGenerator/GenerateProtocolHeatmapExcel?clientId=${clientId}&clientName=${clientName}&isForAdmin=${isForAdmin}&currentDateTimeString=${currentDateTimeString}`,
		{
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify(trials)
		}
	)
		.then((res) => res.json())
		.then((data) => data.data);
}
