const aerialPlotApi = process.env.aerialPlotConnections.aerialPlotApi.baseUrl;

export function getFarms(accessToken, clientId) {
	return fetch(`${aerialPlotApi}api/v1/Farms/GetFarms?clientId=${clientId}`, {
		method: "GET",
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function getFarmInfo(accessToken, farmId) {
	return fetch(`${aerialPlotApi}api/v1/Farms?farmId=${farmId}`, {
		method: "GET",
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function createFarm(accessToken, newFarm) {
	const farmFormData = new FormData();

	for (const name in newFarm) {
		let val = newFarm[name] === "" ? null : newFarm[name];

		if (val) {
			if (name.match(/date/gim) && val) {
				val = val.toLocaleDateString();
			}

			farmFormData.set(name, val);
		}
	}

	return fetch(`${aerialPlotApi}api/v1/Farms`, {
		method: "POST",
		headers: {
			Authorization: `Bearer ${accessToken}`
		},
		body: farmFormData
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function updateFarm(accessToken, farmUpdate) {
	const farmFormData = new FormData();

	for (const name in farmUpdate) {
		let val = farmUpdate[name] === "" ? null : farmUpdate[name];

		if (val) {
			if (name.match(/date/gim) && val) {
				val = val.toLocaleDateString();
			}

			farmFormData.set(name, val);
		}
	}

	return fetch(`${aerialPlotApi}api/v1/Farms`, {
		method: "PUT",
		headers: {
			Authorization: `Bearer ${accessToken}`
		},
		body: farmFormData
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}

export function deleteFarm(accessToken, clientId, farmId) {
	return fetch(`${aerialPlotApi}api/v1/Farms?clientId=${clientId}&farmId=${farmId}`, {
		method: "DELETE",
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			return data;
		});
}
