import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import _ from "lodash";

import { Segment, Grid, List, Loader, Form} from "semantic-ui-react";
import { toast } from "react-toastify";

import { useAuth0 } from "../../../../auth/auth0";
import { useUserAuth } from "../../../../hooks/useUserAuth";


import * as cropActions from "../../../../redux/actions/cropActions";

const CropManagement = () => {
	const clientId = useSelector((state) => (state.clients ? state.clients.currentId : null));

	const { getTokenSilently } = useAuth0();
	const userAuth = useUserAuth();
    const dispatch = useDispatch();

	//-- Data Source
	const [crops, setCrops] = useState(null);
	const [clientName, setClientName] = useState(null);

	//-- Selected Data
	const [selectedCrop, setSelectedCrop] = useState(null);

	//-- UI Control
	const [mode, setMode] = useState("update");
	const [loading, setLoading] = useState(true);
	const [saving, setSaving] = useState(false);
	const [deleting, setDeleting] = useState(false);

	useEffect(() => {
		if (userAuth.isReady && clientId) {
			setClientName(userAuth.user.userInfo.clientMemberships.find((cm) => cm.clientId === clientId).clientName);
			getData();
		}
		setMode("update");
	}, [userAuth.isReady, clientId]);
	
	const getData = async () => {
		setLoading(true);
		const accessToken = await getTokenSilently();
		dispatch(cropActions.getCropDetails(accessToken))
		.then((res) => {
			if (res.statusCode === 200) {
				setCrops(res.data);
			} else {
				toast.error("Unable to load crop data. Please try again.");
				setLoading(false);
			}
		})
		.catch((err) => {
			console.log(err);
			toast.error("Unable to load crop data. Please try again.");
		});
	};

	//-- Crops
	useEffect(() => {
		if (crops && crops.length > 0) {
			if (!selectedCrop) {
				cropSelected(crops[0].id,crops[0].name);
			} else {
				cropSelected(selectedCrop.id, selectedCrop.name);
			}
			setMode("update");
		}
    }, [crops]);
    
	useEffect(() => {
		if (
			crops !== null
		) {
			setLoading(false);
		}
	}, [crops, selectedCrop]);

	const cropSelected = (cropId, cropName) => {
		if (cropId || cropName) {
			const selCrop = crops.find((c) => c.id === cropId || c.name === cropName);
			setSelectedCrop(selCrop);
			setMode("update");
		}
	};

	function prepareNewCropForm() {
		setSelectedCrop(null);
		setMode("create");
	}

	const createCrop = async () => {
		setSaving(true);

		const accessToken = await getTokenSilently();
		dispatch(cropActions.createCrop(accessToken, selectedCrop))
			.then((res) => {
				if (res.statusCode === 200) {
					toast.success("Crop created");
					getData();
				} else if (res.statusCode === 400) {
					if (res.messages && res.messages.length > 0) {
						res.messages.forEach((m) => {
							if (m.type === "Informational") {
								toast.info(m.text);	
							} else if (m.type === "FailedValidation") {
								toast.error(m.text);
							}
						});
					}
				}
				setSaving(false);
			})
			.catch(() => {
				toast.error("Unable to create Crop. Please try again.");
				setSaving(false);
			});
	};

	const updateCrop = async () => {
		setSaving(true);

		const accessToken = await getTokenSilently();
		dispatch(cropActions.updateCrop(accessToken, selectedCrop))
			.then((res) => {
				if (res.statusCode === 200) {
					toast.success("Crop updated");
					getData();
				} else if (res.statusCode === 400) {
					if (res.messages && res.messages.length > 0) {
						res.messages.forEach((m) => {
							if (m.type === "Informational") {
								toast.info(m.text);
							} else if (m.type === "FailedValidation") {
								toast.error(m.text);
							}
						});
					}
				}
				setSaving(false);
			})
			.catch((error) => {
				toast.error("Unable to update Crop. Please try again.");
				console.log(error);
				setSaving(false);
			});
	};

	const deleteCrop = async () => {
		setDeleting(true);

		const accessToken = await getTokenSilently();
		dispatch(cropActions.deleteCrop(accessToken, selectedCrop.id))
			.then((res) => {
				if (res.statusCode === 200) {
					toast.success("Crop deleted");
					getData();
				} else if (res.statusCode === 400) {
					if (res.messages && res.messages.length > 0) {
						res.messages.forEach((m) => {
							if (m.type === "Informational") {
								toast.info(m.text);
							} else if (m.type === "FailedValidation") {
								toast.error(m.text);
							}
						});
					}
				}
				setDeleting(false);
			})
			.catch((error) => {
				toast.error("Unable to delete UAV. Please try again.");
				console.log(error);
				setDeleting(false);
			});
	};

	return (
		<Segment style={{ marginLeft: 50, marginTop: 15, paddingBottom: "unset" }}>
			<h2 style={{ float: "left" }}>Manage Crops</h2>
			<h2 style={{ float: "right", marginTop: "unset", color: "rgba(7, 55, 99, 0.75)" }}>
				<i>{clientName}</i>
			</h2>
			<hr style={{ clear: "both" }} />
			{loading && (
				<Segment basic style={{ marginBottom: 15 }}>
					<Loader active />
				</Segment>
			)}
			{!loading && (
				<Grid id="configure-crop-grid" columns="equal">
					<Grid.Row style={{ paddingBottom: "unset" }}>
						<Grid.Column width={4}>
							<label htmlFor="form-select-crop" className="fieldLabel">
								Crops
							</label>
									<Form.Button
										id="new-crop"
										color="green"
										content="New Crop"
										style={{ width: "calc(100% + 4px)", marginTop: 5, marginBottom: 10 }}
										onClick={() => prepareNewCropForm()}
									/>
							<List
								id="select-crop"
								selection
								size="big"
								style={{
									minHeight: 150,
									maxHeight: 250,
									marginTop: "unset",
									overflowY: "auto",
									border: "1px solid #DEDEDF",
									backgroundColor: "#FFFFFF"
								}}
							>
								{_.map(crops, ({ id, name }) => {
									return (
										<List.Item
											key={id}
											active={selectedCrop?.id === id}
											onClick={() => {
												cropSelected(id, name);
											}}
										>
											<List.Content>
												<span style={{ fontSize: 14, color: "#000000" }}>{name}</span>
											</List.Content>
										</List.Item>
									);
								})}
							</List>
						</Grid.Column>
						<Grid.Column>
						<Form>
			<Grid id="configure-crop-form-grid" columns="equal">
				<Grid.Row style={{ paddingBottom: "unset" }}>
					<Grid.Column>
						<Form.Group widths={"equal"}>
							<Form.Input
								id="form-input-name"
								width={12}
								label="Name"
								value={selectedCrop?.name ?? ""}
								required
								onChange={(event) => setSelectedCrop({ ...selectedCrop, name: event.target.value})}
							/>
						</Form.Group>
						<Form.Group style={{ marginBottom: 3 }}>
							<label htmlFor="form-text-area-description" className="fieldLabel" style={{ marginLeft: 7 }}>
								Description
							</label>
						</Form.Group>
						<Form.Group>
							<Form.TextArea
								id="form-text-area-description"
								width={16}
								value={selectedCrop?.description ?? ""}
								onChange={(event) => setSelectedCrop({ ...selectedCrop, description: event.target.value})}
								rows="5"
							/>
						</Form.Group>
						<hr style={{ clear: "both" }} />
						<Form.Group>
								<Form.Checkbox
									id="form-crop-enabled"
									width={3}
									label="Crop Enabled?"
									style={{ marginTop: 33 }}
									checked={selectedCrop?.enabled ?? false}
									onChange={(event, data) => {
										setSelectedCrop({ ...selectedCrop, enabled: data.checked})}
									}
								/>
							</Form.Group>
						<Form.Group style={{ float: "right"}}>
							{mode === "update" && selectedCrop?.canDelete && (
								<Form.Button
									floated="right"
									negative
									content="Delete"
									onClick={() => {
										deleteCrop();
									}}
									disabled={deleting}
									loading={deleting}
								/>
							)}
							<Form.Button
								floated="right"
								color="green"
								content={mode === "create" ? "Create" : "Update"}
								onClick={() => {
									if (mode === "create") {
										createCrop();
									} else {
										updateCrop();
									}
								}}
								disabled={saving}
								loading={saving}
							/>
						</Form.Group>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</Form>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			)}
		</Segment>
	);
}

export default CropManagement;