import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Table, Icon } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import Tooltip from "rc-tooltip";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { useAuth0 } from "../../../../auth/auth0";
import * as trialActions from "../../../../redux/actions/trialActions";

import { useUserAuth } from "../../../../hooks/useUserAuth";
import { useModuleNavigation } from "../../../../hooks/useModuleNavigation";
import { ApplicationArea } from "../../../Lumber/ApplicationAreas";

import damagedIcon from "../../../../assets/excludedNoBorder.svg";
import partialExcludedIcon from "../../../../assets/partialExcludedNoBorder.svg";
import partialFlagIcon from "../../../../assets/partialFlagNoBorder.svg";
import flaggedIcon from "../../../../assets/flaggedNoBorder.svg";

import AnnotationSummaryModal from "../../Trials/TrialSearch/AnnotationSummaryModal";

const ProtocolTimecourseSiteSpecificTable = ({ trialId, trialName, cooperatorName, farmCity, farmState, hasExclusions, hasFlaggings, hasPartialExclusions, hasPartialFlaggings }) => {
	const userAuth = useUserAuth();
	const moduleNavigation = useModuleNavigation();
	const { getTokenSilently } = useAuth0();
	const dispatch = useDispatch();

	//Annotation Summary Modal
	const [openAnnotationSummaryModal, setOpenAnnotationSummaryModal] = useState(false);
	const [trialNameForModal, setTrialNameForModal] = useState(null);
	const [annotationSummaryData, setAnnotationSummaryData] = useState([]);
	const [annotationSummaryDataLoader, setAnnotationSummaryDataLoader] = useState(true);

	const getAnnotationSummaryData = async (trialId, trialName) => {
		setOpenAnnotationSummaryModal(true);
		setTrialNameForModal(trialName)
		const accessToken = await getTokenSilently();

		dispatch(trialActions.getAnnotationSummaryForTrial(trialId, userAuth.currentClientId, accessToken))
			.then((res) => {
				setAnnotationSummaryData(res);
				setAnnotationSummaryDataLoader(false);
			})
			.catch((err) => {
				toast.error("Error loading annotation summary data. Please try again.");
				console.error(err);
				setAnnotationSummaryDataLoader(false);
			});
	}

	return (
		<Table celled striped selectable color="blue" textAlign="center">
			<Table.Header>
				<Table.Row>
					<Table.HeaderCell style={{ zIndex: "0" }}>Trial Name</Table.HeaderCell>
				</Table.Row>
			</Table.Header>
			<Table.Body>
				<Table.Row>
					<Table.Cell>
						<Link to={moduleNavigation.createTrialLink(false, trialId, "viewer")}>{trialName}</Link>
					</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.Cell>{cooperatorName}</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.Cell>
						{farmCity}, {farmState}
					</Table.Cell>
				</Table.Row>
				<Table.Row>
					<Table.Cell textAlign="center">
						{userAuth.hasApplicationArea(ApplicationArea.TrialEdit, userAuth.currentClientId) && (
							<>
								<Tooltip
									placement="bottom"
									mouseEnterDelay={0}
									mouseLeaveDelay={0}
									trigger="hover"
									overlay={<p>Edit</p>}
									transitionName="rc-tooltip-zoom"
								>
									<Link to={moduleNavigation.createTrialLink(false, trialId, "edit", null)}>
										<Icon.Group>
											<Icon name="leaf" corner="top left" style={{ fontSize: 11, textShadow: "unset" }} />
											<Icon name="pencil" corner="bottom right" style={{ fontSize: 6 }} />
										</Icon.Group>
									</Link>
								</Tooltip>
								<span style={{ display: "inline-block", width: 7 }} />
							</>
						)}
						{userAuth.hasApplicationArea(ApplicationArea.TrialHeatMap, userAuth.currentClientId) && (
							<>
								<Tooltip
									placement="bottom"
									mouseEnterDelay={0}
									mouseLeaveDelay={0}
									trigger="hover"
									overlay={<p>Heatmap</p>}
									transitionName="rc-tooltip-zoom"
								>
									<Link to={moduleNavigation.createTrialLink(false, trialId, "heatmap", null)}>
										<Icon name="map" />
									</Link>
								</Tooltip>
								<span style={{ display: "inline-block", width: 5 }} />
							</>
						)}
						<Tooltip
							placement="bottom"
							mouseEnterDelay={0}
							mouseLeaveDelay={0}
							trigger="hover"
							overlay={<p>Analysis</p>}
							transitionName="rc-tooltip-zoom"
						>
							<Link to={moduleNavigation.createTrialLink(false, trialId, "analysis", null)}>
								<Icon name="chart bar outline" />
							</Link>
						</Tooltip>
						<span style={{ display: "inline-block", width: 5 }} />
						<Tooltip
							placement="bottom"
							mouseEnterDelay={0}
							mouseLeaveDelay={0}
							trigger="hover"
							overlay={<p>Outline</p>}
							transitionName="rc-tooltip-zoom"
						>
							<Link to={moduleNavigation.createTrialLink(false, trialId, "outline", null)}>
								<Icon name="table" />
							</Link>
						</Tooltip>
						{hasExclusions || hasPartialExclusions ?
							<Tooltip
								placement="bottom"
								mouseEnterDelay={0}
								mouseLeaveDelay={0}
								trigger="hover"
								overlay={
									`Trial has ${hasExclusions ? "whole plot" : "partial"} exclusions`
								}
								transitionName="rc-tooltip-zoom"
							>

								<img src={hasExclusions ? damagedIcon : partialExcludedIcon} color={"#d03f2c"} width={hasExclusions ? "18px" : "19px"} style={{ marginLeft: "-2px", cursor: "pointer" }} onClick={() => { getAnnotationSummaryData(trialId, trialName); }}></img>

							</Tooltip> : null}
						{hasFlaggings || hasPartialFlaggings ?
							<Tooltip
								placement="bottom"
								mouseEnterDelay={0}
								mouseLeaveDelay={0}
								trigger="hover"
								overlay={
									`Trial has ${hasFlaggings ? "whole plot" : "partial"} flaggings`
								}
								transitionName="rc-tooltip-zoom"
							>
								<img src={hasFlaggings ? flaggedIcon : partialFlagIcon} color={"#ffa500"} width={hasFlaggings ? "18px" : "19px"} style={{ marginRight: "2px", cursor: "pointer" }} onClick={() => { getAnnotationSummaryData(trialId, trialName); }}></img>
							</Tooltip> : null}
					</Table.Cell>
					<AnnotationSummaryModal
						open={openAnnotationSummaryModal && trialName === trialNameForModal}
						setOpen={(isOpen) => setOpenAnnotationSummaryModal(isOpen)}
						trialName={trialNameForModal}
						annotationSummaryData={annotationSummaryData}
						setAnnotationSummaryData={setAnnotationSummaryData}
						annotationSummaryDataLoader={annotationSummaryDataLoader}
						setAnnotationSummaryDataLoader={setAnnotationSummaryDataLoader}
					/>
				</Table.Row>
			</Table.Body>
		</Table>
	);
};

ProtocolTimecourseSiteSpecificTable.propTypes = {
	trialId: PropTypes.string,
	trialName: PropTypes.string,
	cooperatorName: PropTypes.string,
	farmCity: PropTypes.string,
	farmState: PropTypes.string,
	clientId: PropTypes.string,
	hasExclusions: PropTypes.bool,
	hasFlaggings: PropTypes.bool,
	hasPartialExclusions: PropTypes.bool,
	hasPartialFlaggings: PropTypes.bool
};

export default ProtocolTimecourseSiteSpecificTable;
