import * as types from "./actionTypes";
import * as markedPlotApi from "../../apis/markedPlotApi";
import { beginApiCall, apiCallError } from "./apiStatusActions";

/*
 *  Action Creators
 *
 */
export function updateMarkedPlotsSuccess() {
	return { type: types.MARKED_PLOTS_UPDATE_MARKED_PLOTS_SUCCESS };
}

export function deleteMarkedPlotSuccess() {
	return { type: types.MARKED_PLOTS_DELETE_MARKED_PLOT_SUCCESS };
}

export function deleteMarkedQuantifiedRegionSuccess() {
	return { type: types.MARKED_PLOTS_DELETE_MARKED_QUANTIFIED_SUCCESS };
}

/*
 *  Thunks
 *
 */

export function updateMarkedPlots(accessToken, updateInfo) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return markedPlotApi
				.updateMarkedPlots(accessToken, updateInfo)
				.then((res) => {
					if (res.statusCode !== 200) {
						reject(res.data);
					}
					dispatch(updateMarkedPlotsSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function deleteMarkedPlots(deleteInfo, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return markedPlotApi
				.deleteMarkedPlots(deleteInfo, accessToken)
				.then((res) => {
					if (res.statusCode !== 200) {
						reject(res.data);
					}
					dispatch(deleteMarkedPlotSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}

export function deleteMarkedQuantifiedRegions(deleteInfo, accessToken) {
	return (dispatch) => {
		return new Promise((resolve, reject) => {
			dispatch(beginApiCall());

			return markedPlotApi
				.deleteMarkedQuantifiedRegions(deleteInfo, accessToken)
				.then((res) => {
					if (res.statusCode !== 200) {
						reject(res.data);
					}
					dispatch(deleteMarkedQuantifiedRegionSuccess());
					resolve(res);
				})
				.catch((error) => {
					dispatch(apiCallError(error));
					reject(error);
				});
		});
	};
}
