//-- Api Status
export const BEGIN_API_CALL = "BEGIN_API_CALL";
export const API_CALL_ERROR = "API_CALL_ERROR";

//-- Users
export const USER_LOG_IN_SUCCESS = "USER_LOG_IN_SUCCESS";
export const USER_GET_PERSONS_SUCCESS = "USER_GET_PERSONS_SUCCESS";
export const USER_GET_PERSONS_BY_CLIENT_SUCCESS = "USER_GET_PERSONS_BY_CLIENT_SUCCESS";
export const USER_GET_PERSON_DETAIL_SUCCESS = "USER_GET_PERSON_DETAIL_SUCCESS";
export const USER_UPDATE_PERSON_SUCCESS = "USER_UPDATE_PERSON_SUCCESS";
export const USER_UPDATE_PERSON_USER_SUCCESS = "USER_UPDATE_PERSON_USER_SUCCESS";
export const USER_CREATE_USER_FOR_PERSON_SUCCESS = "USER_CREATE_USER_FOR_PERSON_SUCCESS";
export const USER_CREATE_NEW_PERSON_SUCCESS = "USER_CREATE_NEW_USER_SUCCESS";
export const USER_CREATE_NEW_USER_SUCCESS = "USER_CREATE_NEW_USER_SUCCESS";
export const DELETE_PERSON_SUCCESS = "DELETE_PERSON_SUCCESS";
export const USER_EMAIL_LOGIN_INSTRUCTIONS_SUCCESS = "USER_EMAIL_LOGIN_INSTRUCTIONS_SUCCESS";

//-- Lookups
export const LU_GET_STATES_SUCCESS = "LU_GET_STATES_SUCCESS";

//-- Entities
export const ENTITIES_GET_ENTITY_NAMES_SUCCESS = "ENTITIES_GET_ENTITY_NAMES_SUCCESS";

//-- Clients
export const CLIENT_SET_CURRENT_ID_SUCCESS = "CLIENT_SET_CURRENT_ID_SUCCESS";
export const CLIENT_GET_CLIENTS_SUCCESS = "CLIENT_GET_CLIENTS_SUCCESS";
export const CLIENT_GET_CLIENT_INFO_SUCCESS = "CLIENT_GET_CLIENT_INFO_SUCCESS";
export const CLIENT_GET_LICENSES_SUCCESS = "CLIENT_GET_LICENSES_SUCCESS";
export const CLIENT_UPDATE_CLIENT_SUCCESS = "CLIENT_UPDATE_CLIENT_SUCCESS";
export const CLIENT_CREATE_CLIENT_SUCCESS = "CLIENT_CREATE_CLIENT_SUCCESS";
export const CLIENT_REMOVE_MEMBERSHIP_SUCCESS = "CLIENT_REMOVE_MEMBERSHIP_SUCCESS";
export const CLIENT_UPDATE_MEMBER_SUCCESS = "CLIENT_UPDATE_MEMBER_SUCCESS";
export const CLIENT_GET_POTENTIAL_MEMBERS_SUCCESS = "CLIENT_GET_POTENTIAL_MEMBERS_SUCCESS";
export const CLIENT_ADD_MEMBER_SUCCESS = "CLIENT_ADD_MEMBER_SUCCESS";
export const CLIENT_GET_CHECK_CLIENT_SUCCESS = "CLIENT_GET_CHECK_CLIENT_SUCCESS";

//-- Client Logos
export const CLIENT_LOGOS_GET_LOGO_URL_SUCCESS = "CLIENT_LOGOS_GET_LOGO_URL_SUCCESS";
export const CLIENT_LOGOS_UPLOAD_CLIENT_LOGO_SUCCESS = "CLIENT_LOGOS_UPLOAD_CLIENT_LOGO_SUCCESS";
export const CLIENT_LOGOS_DELETE_CLIENT_LOGO_SUCCESS = "CLIENT_LOGOS_DELETE_CLIENT_LOGO_SUCCESS";

//-- Crops
export const CROP_GET_CROP_SUCCESS = "CROP_GET_CROP_SUCCESS";
export const CROP_GET_CROP_DETAILS_SUCCESS = "CROP_GET_CROP_DETAILS_SUCCESS";
export const CROP_CREATE_SUCCESS = "CROP_CREATE_SUCCESS";
export const CROP_UPDATE_SUCCESS = "CROP_UPDATE_SUCCESS";
export const CROP_DELETE_SUCCESS = "CROP_DELETE_SUCCESS";

//-- Growing Seasons
export const GROWING_SEASON_GET_GROWING_SEASON_DETAILS_SUCCESS = "GROWING_SEASON_GET_GROWING_SEASON_DETAILS_SUCCESS";
export const GROWING_SEASON_CREATE_SUCCESS = "GROWING_SEASON_CREATE_SUCCESS";
export const GROWING_SEASON_UPDATE_SUCCESS = "GROWING_SEASON_UPDATE_SUCCESS";

//-- Flights
export const FLIGHT_GET_FLIGHT_INFO_SUCCESS = "FLIGHT_GET_FLIGHT_INFO_SUCCESS";
export const FLIGHT_SAVE_NEW_FLIGHT_SUCCESS = "FLIGHT_SAVE_NEW_FLIGHT_SUCCESS";
export const FLIGHT_UPDATE_FLIGHT_SUCCESS = "FLIGHT_UPDATE_FLIGHT_SUCCESS";
export const FLIGHT_GET_FLIGHT_SUCCESS = "FLIGHT_GET_FLIGHT_SUCCESS";
export const FLIGHT_GET_FLIGHT_LOG_DATA_OPTIONS_SUCCESS = "FLIGHT_GET_FLIGHT_LOG_DATA_OPTIONS_SUCCESS";
export const FLIGHT_GET_FLIGHT_LOG_SUCCESS = "FLIGHT_GET_FLIGHT_LOG_SUCCESS";
export const FLIGHT_DELETE_FLIGHT_SUCCESS = "FLIGHT_DELETE_FLIGHT_SUCCESS";
export const FLIGHT_GET_SENSORS_FOR_FLIGHT_SUCCESS = "FLIGHT_GET_SENSORS_FOR_FLIGHT_SUCCESS";
export const FLIGHT_GET_ORTHOS_FOR_FLIGHT_SUCCESS = "FLIGHT_GET_ORTHOS_FOR_FLIGHT_SUCCESS";
export const FLIGHT_DOWNLOAD_ORTHOS_FOR_FLIGHT_SUCCESS = "FLIGHT_GET_ORTHOS_FOR_FLIGHT_SUCCESS";
export const FLIGHT_DELETE_ORTHOS_FOR_FLIGHT_SUCCESS = "FLIGHT_GET_ORTHOS_FOR_FLIGHT_SUCCESS";
export const FLIGHT_DELETE_FLIGHT_ELEVATION_IMAGE_SUCCESS = "FLIGHT_DELETE_FLIGHT_ELEVATION_IMAGE_SUCCESS";
export const FLIGHT_GET_VIEWER_ORTHO_OPTIONS_SUCCESS = "FLIGHT_GET_VIEWER_ORTHO_OPTIONS_SUCCESS";
export const FLIGHT_GET_EXISTING_PLOTS_SUCCESS = "FLIGHT_GET_EXISTING_PLOTS_SUCCESS";
export const FLIGHT_GET_PLOT_ANALYSIS_STATUS_FOR_FLIGHT_SUCCESS = "FLIGHT_GET_PLOT_ANALYSIS_STATUS_FOR_FLIGHT_SUCCESS";

//-- Trials
export const TRIAL_GET_TRIAL_SEARCH_DATA_OPTIONS_SUCCESS = "TRIAL_GET_TRIAL_SEARCH_DATA_OPTIONS_SUCCESS";
export const TRIAL_GET_TRIAL_FAVORITES_SUCCESS = "TRIAL_GET_TRIAL_FAVORITES_SUCCESS";
export const TRIAL_GET_TRIAL_FAVORITE_GUIDS_SUCCESS = "TRIAL_GET_TRIAL_FAVORITE_GUIDS_SUCCESS";
export const TRIAL_CREATE_TRIAL_FAVORITE_SUCCESS = "TRIAL_CREATE_TRIAL_FAVORITE_SUCCESS";
export const TRIAL_DELETE_TRIAL_FAVORITE_SUCCESS = "TRIAL_DELETE_TRIAL_FAOVIRTE_SUCCESS";
export const TRIAL_SEARCH_FOR_TRIALS_SUCCESS = "TRIAL_SEARCH_FOR_TRIALS_SUCCESS";
export const TRIAL_GET_TRIALS_IN_FIELD_SUCCESS = "TRIAL_GET_TRIALS_IN_FIELD_SUCCESS";
export const TRIAL_GET_RECENTLY_UPDATED_TRIALS_SUCCESS = "TRIAL_GET_RECENTLY_UPDATED_TRIALS_SUCCESS";
export const TRIAL_GET_RECENTLY_PUBLISHED_TRIALS_SUCCESS = "TRIAL_GET_RECENTLY_PUBLISHED_TRIALS_SUCCESS";
export const TRIAL_GET_OUTLINE_DATA_SUCCESS = "TRIAL_GET_OUTLINE_DATA_SUCCESS";
export const TRIAL_GET_TRIAL_OPTIONS_SUCCESS = "TRIAL_GET_TRIAL_OPTIONS_SUCCESS";
export const TRIAL_GET_TRIAL_DATA_SUCCESS = "TRIAL_GET_TRIAL_DATA_SUCCESS";
export const TRIAL_UPDATE_TRIAL_SUCCESS = "TRIAL_UPDATE_TRIAL_SUCCESS";
export const TRIAL_UPDATE_TRIAL_TREATMENTS_SUCCESS = "TRIAL_UPDATE_TRIAL_TREATMENTS_SUCCESS";
export const TRIAL_SAVE_ASSESSMENT_SELECTION_FOR_SUMMARY_TABLE_SUCCESS =
	"TRIAL_SAVE_ASSESSMENT_SELECTION_FOR_SUMMARY_TABLE_SUCCESS";
export const TRIAL_PUBLISH_TRIAL_FLIGHT_SUCCESS = "TRIAL_PUBLISH_TRIAL_FLIGHT_SUCCESS";
export const TRIAL_PUBLISH_TRIAL_FLIGHTS_SUCCESS = "TRIAL_PUBLISH_TRIAL_FLIGHTS_SUCCESS";
export const TRIAL_PUBLISH_TRIAL_FLIGHT_TO_TRIAL_SPONSOR_SUCCESS =
	"TRIAL_PUBLISH_TRIAL_FLIGHT_TO_TRIAL_SPONSOR_SUCCESS";
export const TRIAL_PUBLISH_TRIAL_FLIGHTS_TO_TRIAL_SPONSOR_SUCCESS =
	"TRIAL_PUBLISH_TRIAL_FLIGHTS_TO_TRIAL_SPONSOR_SUCCESS";
export const TRIAL_UNPUBLISH_TRIAL_FLIGHT_SUCCESS = "TRIAL_UNPUBLISH_TRIAL_FLIGHT_SUCCESS";
export const TRIAL_UNPUBLISH_TRIAL_FLIGHTS_SUCCESS = "TRIAL_UNPUBLISH_TRIAL_FLIGHTS_SUCCESS";
export const TRIAL_UNPUBLISH_TRIAL_FLIGHT_FOR_TRIAL_SPONSOR_SUCCESS =
	"TRIAL_UNPUBLISH_TRIAL_FLIGHT_FOR_TRIAL_SPONSOR_SUCCESS";
export const TRIAL_UNPUBLISH_TRIAL_FLIGHTS_FOR_TRIAL_SPONSOR_SUCCESS =
	"TRIAL_UNPUBLISH_TRIAL_FLIGHTS_FOR_TRIAL_SPONSOR_SUCCESS";
export const TRIAL_GET_TRIALS_PUBLISHED_TO_CLIENT_OR_USER_SUCCESS =
	"TRIAL_GET_TRIALS_PUBLISHED_TO_CLIENT_OR_USER_SUCCESS";
export const TRIAL_SAVE_PUBLISHED_TRIAL_FLIGHT_CLIENT_ID_ASSIGNMENTS_SUCCESS =
	"TRIAL_SAVE_PUBLISHED_TRIAL_FLIGHT_CLIENT_ID_ASSIGNMENTS_SUCCESS";
export const TRIAL_GET_TRIALS_PUBLISHED_BY_CLIENT_SUCCESS = "TRIAL_GET_TRIALS_PUBLISHED_BY_CLIENT_SUCCESS";
export const TRIAL_SET_PUBLISHED_TRIAL_FLIGHT_VIEWED_SUCCESS = "TRIAL_SET_PUBLISHED_TRIAL_FLIGHT_VIEWED_SUCCESS";
export const TRIAL_GET_TRIAL_PUBLISHING_DATA_SUCCESS = "TRIAL_GET_TRIAL_PUBLISHING_DATA_SUCCESS";
export const TRIAL_GET_BULK_PUBLISHING_DATA_SUCCESS = "TRIAL_GET_BULK_PUBLISHING_DATA_SUCCESS";
export const PUBLISH_BULK_TRIAL_FLIGHTS_SUCCESS = "PUBLISH_BULK_TRIAL_FLIGHTS_SUCCESS";
export const TRIAL_SET_TRIAL_OWNER_EMAIL_ADDRESS_SUCCESS = "TRIAL_SET_TRIAL_OWNER_EMAIL_ADDRESS_SUCCESS";
export const TRIAL_SET_TRIAL_SPONSOR_EMAIL_ADDRESS_SUCCESS = "TRIAL_SET_TRIAL_SPONSOR_EMAIL_ADDRESS_SUCCESS";
export const TRIAL_SEND_NEW_USER_EMAIL_SUCCESS = "TRIAL_SEND_NEW_USER_EMAIL_SUCCESS";
export const TRIAL_GET_TRIAL_ANALYSIS_DATA_OPTIONS_SUCCESS = "TRIAL_GET_TRIAL_ANALYSIS_DATA_OPTIONS_SUCCESS";
export const TRIAL_GET_SUMMARY_TABLE_DATA_OPTIONS_SUCCESS = "TRIAL_GET_SUMMARY_TABLE_DATA_OPTIONS_SUCCESS";
export const TRIAL_GET_DEFAULT_VIEWER_SETTING_FOR_TRIAL_SUCCESS = "TRIAL_GET_DEFAULT_VIEWER_SETTING_FOR_TRIAL_SUCCESS";
export const TRIAL_CREATE_DEFAULT_VIEWER_SETTING_FOR_TRIAL_SUCCESS =
	"TRIAL_CREATE_DEFAULT_VIEWER_SETTING_FOR_TRIAL_SUCCESS";
export const TRIAL_UPDATE_DEFAULT_VIEWER_SETTING_FOR_TRIAL_SUCCESS =
	"TRIAL_UPDATE_DEFAULT_VIEWER_SETTING_FOR_TRIAL_SUCCESS";
export const TRIAL_GET_METADATA_FOR_TRIALS_SUCCESS = "TRIAL_GET_METADATA_FOR_TRIALS_SUCCESS";
export const TRIAL_GET_PLOT_ANALYSIS_STATUS_FOR_TRIAL_SUCCESS = "TRIAL_GET_PLOT_ANALYSIS_STATUS_FOR_TRIAL_SUCCESS";
export const TRIAL_GET_ANNOTATION_SUMMARY_FOR_TRIAL_SUCCESS = "TRIAL_GET_ANNOTATION_SUMMARY_FOR_TRIAL_SUCCESS";

//-- Orthos
export const ORTHO_ADD_ORTHO_UPLOAD = "ORTHO_ADD_ORTHO_UPLOAD";
export const ORTHO_AUTO_ORTHO_UPLOAD = "ORTHO_AUTO_ORTHO_UPLOAD";
export const ORTHO_AUTO_ORTHO_REMOVE = "ORTHO_AUTO_ORTHO_REMOVE";
export const ORTHO_REMOVE_ORTHO_UPLOAD = "ORTHO_REMOVE_ORTHO_UPLOAD";
export const ORTHO_UPDATE_UPLOADING_ORTHO_PROGRESS = "ORTHO_UPDATE_UPLOADING_ORTHO_PROGRESS";
export const ORTHO_DOWNLOAD_SUCCESS = "ORTHO_DOWNLOAD_SUCCESS";
export const ORTHO_CUT_SUCCESS = "ORTHO_CUT_SUCCESS";
export const ORTHO_GET_ORTHO_IMAGE_TYPE_DETAILS_SUCCESS = "ORTHO_GET_ORTHO_IMAGE_TYPE_DETAILS_SUCCESS";
export const ORTHO_GET_ORTHO_IMAGE_TYPES_SUCCESS = "ORTHO_GET_ORTHO_IMAGE_TYPES_SUCCESS";
export const ORTHO_ADD_FLIGHT_ELEVATION_ORTHO_UPLOAD = "ORTHO_ADD_FLIGHT_ELEVATION_ORTHO_UPLOAD";
export const ORTHO_ADD_FLIGHT_MULTIBAND_ORTHO_UPLOAD = "ORTHO_ADD_FLIGHT_MULTIBAND_ORTHO_UPLOAD";
export const ORTHO_UPDATE_UPLOADING_ELEVATION_ORTHO_PROGRESS = "ORTHO_UPDATE_UPLOADING_ELEVATION_ORTHO_PROGRESS";
export const ORTHO_UPDATE_UPLOADING_MULTIBAND_ORTHO_PROGRESS = "ORTHO_UPDATE_UPLOADING_MULTIBAND_ORTHO_PROGRESS";
export const ORTHO_REMOVE_ELEVATION_ORTHO_UPLOAD = "ORTHO_REMOVE_ELEVATION_ORTHO_UPLOAD";
export const ORTHO_REMOVE_MULTIBAND_ORTHO_UPLOAD = "ORTHO_REMOVE_MULTIBAND_ORTHO_UPLOAD";
export const ORTHO_DOWNLOAD_FLIGHT_ELEVATION_ORTHO_SUCCESS = "ORTHO_DOWNLOAD_FLIGHT_ELEVATION_ORTHO_SUCCESS";
export const ORTHO_DOWNLOAD_FLIGHT_MULTIBAND_ORTHO_SUCCESS = "ORTHO_DOWNLOAD_FLIGHT_MULTIBAND_ORTHO_SUCCESS";
export const ORTHO_CREATE_INTIAL_FLIGHT_IMAGE_RECORD_SUCCESS = "ORTHO_CREATE_INTIAL_FLIGHT_IMAGE_RECORD_SUCCESS";
export const ORTHO_CREATE_INTIAL_MULTI_BAND_FLIGHT_IMAGE_RECORD_SUCCESS =
	"ORTHO_CREATE_INTIAL_MULTI_BAND_FLIGHT_IMAGE_RECORD_SUCCESS";
export const ORTHO_CREATE_INTIAL_FLIGHT_ELEVATION_IMAGE_RECORD_SUCCESS =
	"ORTHO_CREATE_INTIAL_FLIGHT_ELEVATION_IMAGE_RECORD_SUCCESS";
export const ORTHO_RETRY_FLIGHT_ELEVATION_IMAGE_UPLOAD_CHUNKS_SUCCESS =
	"ORTHO_RETRY_FLIGHT_ELEVATION_IMAGE_UPLOAD_CHUNKS_SUCCESS";
export const ORTHO_RETRY_ORTHO_IMAGE_UPLOAD_CHUNKS_SUCCESS = "ORTHO_RETRY_ORTHO_IMAGE_UPLOAD_CHUNKS_SUCCESS";
export const ORTHO_RETRY_MULTI_BAND_FLIGHT_IMAGE_UPLOAD_CHUNKS_SUCCESS =
	"ORTHO_RETRY_MULTI_BAND_FLIGHT_IMAGE_UPLOAD_CHUNKS_SUCCESS";
export const ORTHO_UPLOAD_IMAGE_CHUNK_SUCCESS = "ORTHO_UPLOAD_IMAGE_CHUNK_SUCCESS";
export const FLIGHT_DELETE_FLIGHT_MULTIBAND_IMAGE_SUCCESS = "FLIGHT_DELETE_FLIGHT_MULTIBAND_IMAGE_SUCCESS";
export const ORTHO_GET_VIEWER_FLIGHT_IMAGE_SUCCESS = "ORTHO_GET_VIEWER_FLIGHT_IMAGE_SUCCESS";
export const ORTHO_GENERATE_ORTHO_FROM_MULTIBAND_ORTHO_SUCCESS = "ORTHO_GENERATE_ORTHO_FROM_MULTIBAND_ORTHO_SUCCESS";
export const ORTHO_CUT_PLOT_IMAGES_SUCCESS = "ORTHO_CUT_PLOT_IMAGES_SUCCESS";
export const ORTHO_IS_FIELD_IMAGE_UPLOADED_SUCCESS = "ORTHO_IS_FIELD_IMAGE_UPLOADED_SUCCESS";
export const ORTHO_GET_PLOT_IMAGES_UPLOADED_STATUS = "ORTHO_GET_PLOT_IMAGES_UPLOADED_STATUS";
export const ORTHO_RETRY_MAPBOX_UPLOAD_SUCCESS = "ORTHO_RETRY_MAPBOX_UPLOAD_SUCCESS";
export const ORTHO_DOWNLOAD_AZURE_FILES_LIST_SUCCESS = "ORTHO_DOWNLOAD_AZURE_FILES_LIST_SUCCESS";
export const ORTHO_COPY_AZURE_FILE_TO_SHARE_SUCCESS = "ORTHO_COPY_AZURE_FILE_TO_SHARE_SUCCESS";

//-- Farms
export const FARM_GET_FARMS_SUCCESS = "FARM_GET_FARMS_SUCCESS";
export const FARM_GET_FARM_INFO_SUCCESS = "FARM_GET_FARM_INFO_SUCCESS";
export const FARM_CREATE_FARM_SUCCESS = "FARM_CREATE_FARM_SUCCESS";
export const FARM_UPDATE_FARM_SUCCESS = "FARM_UPDATE_FARM_SUCCESS";
export const FARM_DELETE_FARM_SUCCESS = "FARM_DELETE_FARM_SUCCESS";

//-- Fields
export const FIELD_GET_FIELDS_SUCCESS = "FIELD_GET_FIELDS_SUCCESS";
export const FIELD_GET_USED_FIELDS_SUCCESS = "FIELD_GET_USED_FIELDS_SUCCESS";
export const FIELD_GET_UNUSED_FIELDS_SUCCESS = "FIELD_GET_UNUSED_FIELDS_SUCCESS";
export const FIELD_SAVE_NEW_FIELD_SUCCESS = "FIELD_SAVE_NEW_FIELD_SUCCESS";
export const FIELD_GET_FIELD_INFO_SUCCESS = "FIELD_GET_FIELD_INFO_SUCCESS";
export const FIELD_GET_FIELD_SUCCESS = "FIELD_GET_FIELD_SUCCESS";
export const FIELD_EDIT_FIELD_SUCCESS = "FIELD_EDIT_FIELD_SUCCESS";
export const FIELD_DELETE_FIELD_SUCCESS = "FIELD_DELETE_FIELD_SUCCESS";
export const FIELD_GET_FIELD_SEARCH_DATA_OPTIONS_SUCCESS = "FIELD_GET_FIELD_SEARCH_DATA_OPTIONS_SUCCESS";
export const FIELD_SEARCH_FOR_FIELDS_SUCCESS = "FIELD_SEARCH_FOR_FIELDS_SUCCESS";
export const FIELD_GET_FLIGHTS_WITH_FIELD_OR_TRIAL_SUCCESS = "FIELD_GET_FLIGHTS_WITH_FIELD_OR_TRIAL_SUCCESS";
export const FIELD_DELETE_FIELD_IN_FLIGHT_SUCCESS = "FIELD_DELETE_FIELD_IN_FLIGHT_SUCCESS";
export const FIELD_GET_OUTLINE_DATA_SUCCESS = "FIELD_GET_OUTLINE_DATA_SUCCESS";
export const FIELD_GET_FIELD_AND_PLOTS_SUCCESS = "FIELD_GET_FIELD_AND_PLOTS_SUCCESS";
export const FIELD_GET_FIELDS_IN_FLIGHT_INFORMATION_SUCCESS = "FIELD_GET_FIELDS_IN_FLIGHT_INFORMATION_SUCCESS";
export const FIELD_GET_SHAPE_FILE_POINTS_SUCCESS = "FIELD_GET_SHAPE_FILE_POINTS_SUCCESS";
export const FIELD_GET_FIELD_LAYOUT_INFO_SUCCESS = "FIELD_GET_FIELD_LAYOUT_INFO_SUCCESS";
export const FIELD_EDIT_FIELD_LAYOUT_SUCCESS = "FIELD_EDIT_FIELD_LAYOUT_SUCCESS";
export const FIELD_GET_FIELD_ADVANCED_ANALYTICS_DATA_SUCCESS = "FIELD_GET_FIELD_ADVANCED_ANALYTICS_DATA_SUCCESS";
export const FIELD_SAVE_FIELD_QUANTIFIED_REGIONS_SUCCESS = "FIELD_SAVE_FIELD_QUANTIFIED_REGIONS_SUCCESS";
export const FIELD_GET_NEW_FIELD_INFO_SUCCESS = "FIELD_GET_NEW_FIELD_INFO_SUCCESS";
export const FIELD_SAVE_FIELD_COORDINATES_SUCCESS = "FIELD_SAVE_FIELD_COORDINATES_SUCCESS";
export const FIELD_GET_DEFAULT_VIEWER_SETTING_FOR_FIELD_SUCCESS = "FIELD_GET_DEFAULT_VIEWER_SETTING_FOR_FIELD_SUCCESS";
export const FIELD_CREATE_DEFAULT_VIEWER_SETTING_FOR_FIELD_SUCCESS =
	"FIELD_CREATE_DEFAULT_VIEWER_SETTING_FOR_FIELD_SUCCESS";
export const FIELD_UPDATE_DEFAULT_VIEWER_SETTING_FOR_FIELD_SUCCESS =
	"FIELD_UPDATE_DEFAULT_VIEWER_SETTING_FOR_FIELD_SUCCESS";
export const FIELD_GET_PLOT_ANALYSIS_STATUS_FOR_FIELD_SUCCESS = "FIELD_GET_PLOT_ANALYSIS_STATUS_FOR_FIELD_SUCCESS";

//-- FlightImages
export const FI_GET_FLIGHT_IMAGES_SUCCESS = "FI_GET_FLIGHT_IMAGES_SUCCESS";
export const FI_GET_IS_UPLOADED_SUCCESS = "FI_GET_IS_UPLOADED_SUCCESS";
export const FI_MODIFY_UAS_SUCCESS = "FI_MODIFY_UAS_SUCCESS";
export const FI_CHECK_CUT_IMAGES_SUCCESS = "FI_CHECK_CUT_IMAGES_SUCCESS";

//-- FlightsPlots
export const FP_GET_EXISTING_FLIGHTS_PLOTS_IN_FLIGHT = "FP_GET_EXISTING_FLIGHTS_PLOTS_IN_FLIGHT";
export const FP_GET_EXISTING_FLIGHTS_PLOTS_IN_FIELD = "FP_GET_EXISTING_FLIGHTS_PLOTS_IN_FIELD";
export const FP_GET_EXISTING_FLIGHTS_PLOTS_IN_TRIAL = "FP_GET_EXISTING_FLIGHTS_PLOTS_IN_TRIAL";
export const FP_CREATE_FLIGHTS_PLOTS = "FP_CREATE_FLIGHTS_PLOTS";
export const FP_UPDATE_FLIGHTS_PLOTS_SUCCESS = "FP_UPDATE_FLIGHTS_PLOTS_SUCCESS";
export const FP_GET_RECUT_PARAMETERS_SUCCESS = "FP_GET_RECUT_PARAMETERS_SUCCESS";

//-- Analysis
export const ANALYSIS_ANALYZE_FLIGHT_SUCCESS = "ANALYSIS_ANALYZE_FLIGHT_SUCCESS";
export const ANALYSIS_STAND_COUNT_SUCCESS = "ANALYSIS_STAND_COUNT_SUCCESS";
export const ANALYSIS_DELETE_FLIGHT_ANALYSIS_SUCCESS = "ANALYSIS_DELETE_FLIGHT_ANALYSIS_SUCCESS";
export const ANALYSIS_GET_ANALYSIS_RESULTS_FOR_FLIGHT_SUCCESS = "ANALYSIS_GET_ANALYSIS_RESULTS_FOR_FLIGHT_SUCCESS";
export const ANALYSIS_GET_ANALYSIS_RESULTS_FOR_FIELD_SUCCESS = "ANALYSIS_GET_ANALYSIS_RESULTS_FOR_FIELD_SUCCESS";
export const ANALYSIS_GET_ANALYSIS_RESULTS_FOR_TRIAL_SUCCESS = "ANALYSIS_GET_ANALYSIS_RESULTS_FOR_TRIAL_SUCCESS";
export const ANALYSIS_GET_ANALYSIS_RESULTS_FOR_SUMMARY_TABLE_SUCCESS =
	"ANALYSIS_GET_ANALYSIS_RESULTS_FOR_SUMMARY_TABLE_SUCCESS";
export const ANALYSIS_GET_ANALYSIS_RESULTS_FOR_TRIAL_WITH_MEAN_COMPARISON_SUCCESS =
	"ANALYSIS_GET_ANALYSIS_RESULTS_FOR_TRIAL_WITH_MEAN_COMPARISON_SUCCESS";
export const ANALYSIS_GET_ANALYSES_SUCCESS = "ANALYSIS_GET_ANALYSES_SUCCESS";
export const ANALYSIS_GET_ANALYSES_DETAILS_SUCCESS = "ANALYSIS_GET_ANALYSES_DETAILS_SUCCESS";
export const ANALYSUS_GET_CORRELATION_PLOT_ANALYSIS_RESULTS_FOR_TRIAL_SUCCESS =
	"ANALYSUS_GET_CORRELATION_PLOT_ANALYSIS_RESULTS_FOR_TRIAL_SUCCESS";
export const ANALYSIS_GET_CORRELATION_MATRIX_DATA_SUCCESS = "ANALYSIS_GET_CORRELATION_MATRIX_DATA_SUCCESS";
export const ANALYSIS_GET_ANALYSIS_RESULTS_FOR_TRIAL_FOR_PROTOCOL_SUMMARY_TABLE_SUCCESS =
	"ANALYSIS_GET_ANALYSIS_RESULTS_FOR_TRIAL_FOR_PROTOCOL_SUMMARY_TABLE_SUCCESS";
export const ANALYSIS_GET_HEATMAP_ANALYSIS_RESULTS_FOR_TRIAL_SUCCESS =
	"ANALYSIS_GET_HEATMAP_ANALYSIS_RESULTS_FOR_TRIAL_SUCCESS";
export const ANALYSIS_GET_HEATMAP_ANALYSIS_RESULTS_FOR_FIELD_SUCCESS =
	"ANALYSIS_GET_HEATMAP_ANALYSIS_RESULTS_FOR_FIELD_SUCCESS";
export const ANALYSIS_REMOVE_OUTLIERS_SUCCESS = "ANALYSIS_REMOVE_OUTLIERS_SUCCESS";
export const PROTOCOL_ANALYSIS_GET_ANALYSIS_RESULTS_FOR_TIMECOURSE_SUCCESS =
	"PROTOCOL_ANALYSIS_GET_ANALYSIS_RESULTS_FOR_TIMECOURSE_SUCCESS";
export const PROTOCOL_ANALYSIS_GET_ANALYSIS_RESULTS_FOR_BOX_WHISKER_SUCCESS =
	"PROTOCOL_ANALYSIS_GET_ANALYSIS_RESULTS_FOR_BOX_WHISKER_SUCCESS";
export const PROTOCOL_ANALYSIS_GET_ANALYSIS_RESULTS_FOR_PIANO_CHARTS_SUCCESS =
	"PROTOCOL_ANALYSIS_GET_ANALYSIS_RESULTS_FOR_PIANO_CHARTS_SUCCESS";
export const ANALYSIS_GENERATE_FIELD_HEATMAP_EXCEL_SUCCESS = "ANALYSIS_GENERATE_FIELD_HEATMAP_EXCEL_SUCCESS";
export const ANALYSIS_GENERATE_TRIAL_HEATMAP_EXCEL_SUCCESS = "ANALYSIS_GENERATE_FIELD_TRIAL_EXCEL_SUCCESS";
export const ANALYSIS_GET_FLIGHT_ANALYSIS_RESULTS_FOR_TRIAL_SUCCESS =
	"ANALYSIS_GET_FLIGHT_ANALYSIS_RESULTS_FOR_TRIAL_SUCCESS";
export const ANALYSIS_GENERATE_PROTOCOL_HEATMAP_EXCEL_SUCCESS = "ANALYSIS_GENERATE_PROTOCOL_HEATMAP_EXCEL_SUCCESS";
export const ANALYSIS_GENERATE_PROTOCOL_WEATHER_DATA_EXCEL_SUCCESS = "ANALYSIS_GENERATE_PROTOCOL_HEATMAP_EXCEL_SUCCESS";
export const ANALYSIS_DELETE_ANALYSIS_DATA_FOR_ADMIN_SUCCESS = "ANALYSIS_DELETE_ANALYSIS_DATA_FOR_ADMIN_SUCCESS";

//-- Ortho Viewer
export const OV_SET_CURRENT_FLIGHT_ID = "OV_SET_CURRENT_FLIGHT_ID";

//-- UAS
export const UAS_GET_CONFIGURE_UASES_DATA_SUCCESS = "UAS_GET_CONFIGURE_UASES_DATA_SUCCESS";
export const UAS_GET_UASES_DETAILS_SUCCESS = "UAS_GET_UASES_DETAILS_SUCCESS";
export const UAS_GET_SENSORS_SUCCESS = "UAS_GET_SENSORS_SUCCESS";
export const UAS_GET_UAVS_SUCCESS = "UAS_GET_UAVS_SUCCESS";
export const UAS_CREATE_SUCCESS = "UAS_CREATE_SUCCESS";
export const UAS_UPDATE_SUCCESS = "UAS_UPDATE_SUCCESS";
export const UAS_DELETE_SUCCESS = "UAS_DELETE_SUCCESS";

//-- UAV
export const UAV_GET_UAV_SUCCESS = "UAV_GET_UAV_SUCCESS";
export const UAV_GET_UAV_DETAILS_SUCCESS = "UAV_GET_UAV_DETAILS_SUCCESS";
export const UAV_CREATE_SUCCESS = "UAV_CREATE_SUCCESS";
export const UAV_UPDATE_SUCCESS = "UAV_UPDATE_SUCCESS";
export const UAV_DELETE_SUCCESS = "UAV_DELETE_SUCCESS";

//-- Sensors
export const SENSOR_GET_SENSOR_SUCCESS = "SENSOR_GET_SENSOR_SUCCESS";
export const SENSOR_GET_SENSOR_DETAILS_SUCCESS = "SENSOR_GET_SENSOR_DETAILS_SUCCESS";
export const SENSOR_CREATE_SUCCESS = "SENSOR_CREATE_SUCCESS";
export const SENSOR_UPDATE_SUCCESS = "SENSOR_UPDATE_SUCCESS";
export const SENSOR_DELETE_SUCCESS = "SENSOR_DELETE_SUCCESS";

//-- Eula
export const EULA_GET_EULA_SUCCESS = "EULA_GET_EULA_SUCCESS";
export const EULA_SET_EULA_STATUS_SUCCESS = "EULA_SET_EULA_STATUS_SUCCESS";
export const EULA_GET_EULA_STATUS_SUCCESS = "EULA_GET_EULA_STATUS_SUCCESS";

//-- Licenses
export const LICENSES_GET_USER_LICENSES = "LICENSES_GET_USER_LICENSES";
export const LICENSES_ADD_USER_LICENSE = "LICENSES_ADD_USER_LICENSE";
export const LICENSES_UPDATE_USER_LICENSE = "LICENSES_UPDATE_USER_LICENSE";
export const LICENSES_REMOVE_USER_LICENSE = "LICENSES_REMOVE_USER_LICENSE";

//-- Companies
export const COMPANIES_GET_COMPANY_MANAGEMENT_INFO_SUCCESS = "COMPANIES_GET_COMPANY_MANAGEMENT_INFO_SUCCESS";
export const COMPANIES_CREATE_COMPANY_SUCCESS = "COMPANIES_CREATE_COMPANY_SUCCESS";
export const COMPANIES_EDIT_COMPANY_SUCCESS = "COMPANIES_EDIT_COMPANY_SUCCESS";
export const COMPANIES_DELETE_COMPANY_SUCCESS = "COMPANIES_DELETE_COMPANY_SUCCESS";
export const COMPANIES_GENERATE_COMPANY_CODE_SUCCESS = "COMPANIES_GENERATE_COMPANY_CODE_SUCCESS";
export const COMPANIES_GET_COOPERATOR_COMPANIES_SUCCESS = "COMPANIES_GET_COOPERATOR_COMPANIES_SUCCESS";
export const COMPANIES_GET_COMPANY_TYPES_SUCCESS = "COMPANIES_GET_COMPANY_TYPES_SUCCESS";

//-- Statistics
export const STATISTICS_GET_MEAN_COMPARISON_FOR_AUC_SUCCESS = "STATISTICS_GET_MEAN_COMPARISON_FOR_AUC_SUCCESS";
export const STATISTICS_GET_MEAN_COMPARISON_FOR_TIMECOURSE_SUCCESS =
	"STATISTICS_GET_MEAN_COMPARISON_FOR_TIMECOURSE_SUCCESS";
export const STATISTICS_GET_MEAN_COMPARISON_OPTIONS_SUCCESS = "STATISTICS_GET_MEAN_COMPARISON_OPTIONS_SUCCESS";
export const STATISTICS_GET_COMPILED_TUKEY_DATA_SUCCESS = "STATISTICS_GET_COMPILED_TUKEY_DATA_SUCCESS";
export const STATISTICS_GET_PROTOCOL_SINGLE_TRIAL_TUKEY_DATA_SUCCESS =
	"STATISTICS_GET_PROTOCOL_SINGLE_TRIAL_TUKEY_DATA_SUCCESS";

//-- Ground Data
export const GROUND_DATA_GET_GROUND_DATA_INFO_SUCCESS = "GROUND_DATA_GET_GROUND_DATA_INFO_SUCCESS";
export const GROUND_DATA_INSPECT_GROUND_DATA_SUCCESS = "GROUND_DATA_INSPECT_GROUND_DATA_SUCCESS";
export const GROUND_DATA_CREATE_GROUND_DATA_SUCCESS = "GROUND_DATA_CREATE_GROUND_DATA_SUCCESS";
export const GROUND_DATA_DELETE_DATASET_SUCCESS = "GROUND_DATA_DELETE_DATASET_SUCCESS";
export const GROUND_DATA_ERASE_DATASET_SUCCESS = "GROUND_DATA_ERASE_DATASET_SUCCESS";
export const GROUND_DATA_GET_GROUND_DATASETS_SUCCESS = "GROUND_DATA_GET_GROUND_DATASETS_SUCCESS";
export const GROUND_DATA_EDIT_GROUND_DATA_SUCCESS = "GROUND_DATA_EDIT_GROUND_DATA_SUCCESS";

//-- Assessments
export const ASSESSMENTS_GET_ASSESSMENT_LOG_OPTIONS_SUCCESS = "ASSESSMENTS_GET_ASSESSMENT_LOG_OPTIONS_SUCCESS";
export const ASSESSMENTS_GET_ASSESSMENT_LOG_SUCCESS = "ASSESSMENTS_GET_ASSESSMENT_LOG_SUCCESS";

//-- Marked Plots
export const MARKED_PLOTS_UPDATE_MARKED_PLOTS_SUCCESS = "MARKED_PLOTS_UPDATE_MARKED_PLOTS_SUCCESS";
export const MARKED_PLOTS_DELETE_MARKED_PLOT_SUCCESS = "MARKED_PLOTS_DELETE_MARKED_PLOT_SUCCESS";
export const MARKED_PLOTS_DELETE_MARKED_QUANTIFIED_SUCCESS = "MARKED_PLOTS_DELETE_MARKED_QUANTIFIED_SUCCESS";

//-- Curve Models
export const CURVE_MODELS_GET_CURVE_MODELS_SUCCESS = "CURVE_MODELS_GET_CURVE_MODELS_SUCCESS";
export const CURVE_MODELS_GET_CURVE_MODEL_DATA_SUCCESS = "CURVE_MODELS_GET_CURVE_MODEL_DATA_SUCCESS";
export const CURVE_MODELS_SAVE_AND_RUN_CURVE_ANALYSIS_SUCCESS = "CURVE_MODELS_SAVE_AND_RUN_CURVE_ANALYSIS_SUCCESS";
export const CURVE_MODELS_DELETE_CURVE_ANALYSIS_SUCCESS = "CURVE_MODELS_DELETE_CURVE_ANALYSIS_SUCCESS";
export const CURVE_MODELS_GET_CURVE_MODEL_BATCH_ASSESSMENTS_SUCCESS =
	"CURVE_MODELS_GET_CURVE_MODEL_BATCH_ASSESSMENTS_SUCCESS";
export const CURVE_MODELS_RUN_BATCH_ASSESSMENTS_SUCCESS = "CURVE_MODELS_RUN_BATCH_ASSESSMENTS_SUCCESS";
export const CURVE_MODELS_RUN_CURVE_MODELS_SUCCESS = "CURVE_MODELS_RUN_CURVE_MODELS_SUCCESS";
export const CURVE_MODELS_RUN_BATCH_CURVE_MODELS_SUCCESS = "CURVE_MODELS_RUN_BATCH_CURVE_MODELS_SUCCESS";
export const CURVE_MODELS_GET_CURVE_MODLE_RESULTS_FOR_TRIAL_SUCCESS =
	"CURVE_MODELS_GET_CURVE_MODLE_RESULTS_FOR_TRIAL_SUCCESS";

//-- Protocols
export const PROTOCOLS_GET_PROTOCOL_INFO_OPTIONS_SUCCESS = "PROTOCOLS_GET_PROTOCOL_INFO_OPTIONS_SUCCESS";
export const PROTOCOLS_GET_PROTOCOL_FAVORITES_SUCCESS = "PROTOCOLS_GET_PROTOCOL_FAVORITES_SUCCESS";
export const PROTOCOLS_GET_PROTOCOL_FAVORITE_GUIDS_SUCCESS = "PROTOCOLS_GET_PROTOCOL_FAVORITE_GUIDS_SUCCESS";
export const PROTOCOLS_CREATE_PROTOCOL_FAVORITE_SUCCESS = "PROTOCOLS_CREATE_PROTOCOL_FAVORITE_SUCCESS";
export const PROTOCOLS_DELETE_PROTOCOL_FAVORITE_SUCCESS = "PROTOCOLS_DELETE_PROTOCOL_FAVORITE_SUCCESS";
export const PROTOCOLS_GET_PROTOCOL_QUESTIONS_SUCCESS = "PROTOCOLS_GET_PROTOCOL_QUESTIONS_SUCCESS";
export const PROTOCOLS_GET_PROTOCOLS_SUCCESS = "PROTOCOLS_GET_PROTOCOLS_SUCCESS";
export const PROTOCOLS_GET_ASSOCIATED_TRIALS_SUCCESS = "PROTOCOLS_GET_ASSOCIATED_TRIALS_SUCCESS";
export const PROTOCOLS_GET_RECENTLY_UPDATED_PROTOCOLS_SUCCESS = "PROTOCOLS_GET_RECENTLY_UPDATED_PROTOCOLS_SUCCESS";
export const PROTOCOLS_CREATE_ASSOCIATED_TRIALS_SUCCESS = "PROTOCOLS_CREATE_ASSOCIATED_TRIALS_SUCCESS";
export const PROTOCOLS_GET_PROTOCOL_DATA_SUCCESS = "PROTOCOLS_GET_PROTOCOL_DATA_SUCCESS";
export const PROTOCOLS_DELETE_PROTOCOL_SUCCESS = "PROTOCOLS_DELETE_PROTOCOL_SUCCESS";
export const PROTOCOLS_CREATE_PROTOCOL_SUCCESS = "PROTOCOLS_CREATE_PROTOCOL_SUCCESS";
export const PROTOCOLS_UPDATE_PROTOCOL_SUCCESS = "PROTOCOLS_UPDATE_PROTOCOL_SUCCESS";
export const PROTOCOLS_GET_PROTOCOL_TREATMENT_DATA_SUCCESS = "PROTOCOLS_GET_PROTOCOL_TREATMENT_DATA_SUCCESS";
export const PROTOCOLS_GET_PROTOCOL_TREATMENT_OPTIONS_SUCCESS = "PROTOCOLS_GET_PROTOCOL_TREATMENT_OPTIONS_SUCCESS";
export const PROTOCOLS_CREATE_PROTOCOL_TREATMENT_DATA_SUCCESS = "PROTOCOLS_CREATE_PROTOCOL_TREATMENT_DATA_SUCCESS";
export const PROTOCOLS_UPDATE_PROTOCOL_TREATMENT_DATA_SUCCESS = "PROTOCOLS_UPDATE_PROTOCOL_TREATMENT_DATA_SUCCESS";
export const PROTOCOLS_GET_SUMMARY_INFO_SUCCESS = "PROTOCOLS_GET_SUMMARY_INFO_SUCCESS";
export const PROTOCOLS_SAVE_ASSESSMENTS_SUCCESS = "PROTOCOLS_SAVE_ASSESSMENTS_SUCCESS";
export const PROTOCOLS_RESET_PROTOCOL_DATA_SUCCESS = "PROTOCOLS_RESET_PROTOCOL_DATA_SUCCESS";
export const PROTOCOLS_GET_PROTOCOL_DASHBOARD_OPTIONS_SUCCESS = "PROTOCOLS_GET_PROTOCOL_DASHBOARD_OPTIONS_SUCCESS";
export const PROTOCOLS_CLEAR_PROTOCOL_TUKEY_DATA = "PROTOCOLS_CLEAR_PROTOCOL_TUKEY_DATA";
export const PROTOCOLS_GET_PLOT_ANALYSIS_STATUS_FOR_PROTOCOL_SUCCESS =
	"PROTOCOLS_GET_PLOT_ANALYSIS_STATUS_FOR_PROTOCOL_SUCCESS";
export const PROTOCOLS_GET_AUC_MEAN_COMPARISON_LETTERS_FOR_PROTOCOL_SUCCESS =
	"PROTOCOLS_GET_AUC_MEAN_COMPARISON_LETTERS_FOR_PROTOCOL_SUCCESS";

//Weather Data
export const WEATHER_DATA_GET_GDU_FOR_TRIAL_SUCCESS = "WEATHER_DATA_GET_GDU_FOR_TRIAL_SUCCESS";
export const WEATHER_DATA_UPDATE_WEATHER_DATA_SUCCESS = "WEATHER_DATA_UPDATE_WEATHER_DATA_SUCCESS";
export const WEATHER_DATA_HISTORICAL_UPDATE_WEATHER_DATA_SUCCESS =
	"WEATHER_DATA_HISTORICAL_UPDATE_WEATHER_DATA_SUCCESS";
export const WEATHER_DATA_GET_HISTORICAL_FOR_TRIAL_SUCCESS = "WEATHER_DATA_GET_HISTORICAL_FOR_TRIAL_SUCCESS";
export const WEATHER_DATA_GET_DAILY_WEATHER_FOR_TRIAL_SUCCESS = "WEATHER_DATA_GET_DAILY_WEATHER_FOR_TRIAL_SUCCESS";
export const WEATHER_DATA_GET_GDU_FOR_TRIAL_HISTORICAL_SUCCESS = "WEATHER_DATA_GET_GDU_FOR_TRIAL_HISTORICAL_SUCCESS";
export const WEATHER_DATA_CREATE_HISTORICAL_WEATHER_DATA_SUCCESS =
	"WEATHER_DATA_CREATE_HISTORICAL_WEATHER_DATA_SUCCESS";
export const WEATHER_DATA_INSPECT_WEATHER_DATA_SUCCESS = "WEATHER_DATA_INSPECT_WEATHER_DATA_SUCCESS";
export const WEATHER_DATA_GET_GDU_DATA_FOR_TRIALS_SUCCESS = "WEATHER_DATA_GET_GDU_DATA_FOR_TRIALS_SUCCESS";
export const WEATHER_DATA_GENERATE_EXCEL_SUCCESS = "WEATHER_DATA_GENERATE_EXCEL_SUCCESS";

//Applications
export const APPLICATIONS_GET_APPLICATION_OPTIONS_SUCCESS = "APPLICATIONS_GET_APPLICATION_OPTIONS_SUCCESS";
export const APPLICATIONS_GET_APPLICATIONS_SUCCESS = "APPLICATIONS_GET_APPLICATIONS_SUCCESS";
export const APPLICATIONS_SAVE_APPLICATIONS_SUCCESS = "APPLICATIONS_SAVE_APPLICATIONS_SUCCESS";
export const APPLICATIONS_UPDATE_APPLICATIONS_SUCCESS = "APPLICATIONS_UPDATE_APPLICATIONS_SUCCESS";
export const PROTOCOLS_SET_SUMMARY_INFO_SUCCESS = "PROTOCOLS_SET_SUMMARY_INFO_SUCCESS";
export const PROTOCOLS_GET_PROTOCOL_OVERVIEW_INFO_SUCCESS = "PROTOCOLS_GET_PROTOCOL_OVERVIEW_INFO_SUCCESS";

//State Management
export const RESET_TIMECOURSE_MEAN_COMPARISON_DATA = "RESET_TIMECOURSE_MEAN_COMPARISON_DATA";

//Quantified Regions
export const QUANTIFIED_REGIONS_CREATE_FLIGHTS_QUANTIFIED_REGIONS_SUCCESS =
	"QUANTIFIED_REGIONS_CREATE_FLIGHTS_QUANTIFIED_REGIONS_SUCCESS";
export const QUANTIFIED_REGIONS_UPDATE_FLIGHTS_QUANTIFIED_REGIONS_SUCCESS =
	"QUANTIFIED_REGIONS_UPDATE_FLIGHTS_QUANTIFIED_REGIONS_SUCCESS";
export const QUANTIFIED_REGIONS_CHECK_IF_FLIGHTS_QUANTIFIED_REGIONS_EXIST_SUCCESS =
	"QUANTIFIED_REGIONS_CHECK_IF_FLIGHTS_QUANTIFIED_REGIONS_EXIST_SUCCESS";

//-- Auto Align
export const ANALYSIS_ALIGN_PLOTS_SUCCESS = "ANALYSIS_ALIGN_PLOTS_SUCCESS";
export const ANALYSIS_SCALE_PLOTS_SUCCESS = "ANALYSIS_SCALE_PLOTS_SUCCESS";
export const ANALYSIS_SCALE_AND_ALIGN_PLOTS_SUCCESS = "ANALYSIS_SCALE_AND_ALIGN_PLOTS_SUCCESS";

//Treatments
export const TREATMENTS_INSPECT_TREATMENT_DATA_SUCCESS = "TREATMENTS_INSPECT_TREATMENT_DATA_SUCCESS";
